import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../atom/button'
import { useNavigate, useLoaderData, useSearchParams, useSubmit, Form, useActionData } from 'react-router-dom'
import SearchAndDelete from '../../../components/searchAndDelete'
import { CREATE_PATH } from '../../../../const/path'
import { DELETE_METHOD, PAGE_PER_SIZE } from '../../../../const/const'
import { adminDeleteQuery, adminListQuery } from '../../../../queries/adminQueries'
import { useQuery } from '@tanstack/react-query'
import Pagination from '../../../atom/pagination'
import { isEmptyObject, makeParamsFromFormData } from '../../../../util/util'
import Input from '../../../atom/input'
import AdminDeletePopup from '../../../components/popup/adminDeletePopup'

export const adminListLoader = 
    (queryClient) => async({request}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
        const keyword = url.searchParams.get('keyword');
        const keywordType = url.searchParams.get('keywordType');
      
        const param = {
            page: page,
            size: size,
            keyword: keyword,
                keywordType: keywordType
        }
                
        await queryClient.fetchQuery(adminListQuery(param));
        return { param };
    }

export const adminDeleteAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData, "appManagerCode");
        const codeList = [];
        const errors = {}
        
        if(isEmptyObject(params)) {
            
            errors.appManagerCode = "권한을 삭제할 관리자를 선택해주세요.";
            return errors;
        } else {
            params.appManagerCode.forEach(code => {
                codeList.push({appManagerCode: code});
            })
        }
        
        const response = await queryClient.fetchQuery(adminDeleteQuery(codeList));

        if(response.result === "FAIL") {
            errors.error = response.message;
        }

        return errors;
    }

const AdminSetting = () => {
    const navigator = useNavigate();
    const { param } = useLoaderData();
    const errors = useActionData();
    const { data: { data: admin } } = useQuery(adminListQuery(param));
    const [, setSearchParams ] = useSearchParams();
    const ref = useRef([]);
    const submitRef = useRef();
    const submit = useSubmit();
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setIsDeletePopupOpen(true);
    }

    const onDelete = (e) => {
        setIsDeletePopupOpen(false);
        submit(submitRef.current);
    }

    const onCancle = () => {
        setIsDeletePopupOpen(false);
    }

    const onChangeHandle = (page) => {
        setSearchParams({page: page.selected});
    }

    const checkAll = (e) => {
        ref.current.forEach(ref => {
            if(ref) {
                ref.checked = e.target.checked;
            }
        });
    }

    useEffect(() => {
        ref.current.forEach((r, idx) => {
            if(r) {
                r.checked = false;
            }    
        })
    }, [admin])

    return (
        <div className="main">
            <div className="contentBg">
                <div className='d-flex justify-content-between align-items-center subTitWrap'>
                    <h2 className="subTit">관리자 설정</h2>
                    <div className='btnWrap justify-content-end'>
                        <Button content={"관리자 권한삭제"}/>    
                        <Button content={"관리자 권한부여"} onClick={() => navigator(CREATE_PATH)}/>
                    </div>
                </div>
                {isDeletePopupOpen ? <AdminDeletePopup onCancle={onCancle} onSubmit={onDelete}/> : ""}
                <SearchAndDelete 
                    isDelete={true}
                    searchTypeName={"keywordType"}
                    searchName={"keyword"}
                    htmlFor={"deleteBtn"}
                    error={errors?.appManagerCode}>
                    <option value="appManagerEmail">관리자 이메일</option>
                    <option value="appManagerName">관리자 이름</option>
                </SearchAndDelete>

                <Form method={DELETE_METHOD} onSubmit={onSubmit} reloadDocument>
                    <div className="tableWrap adminWrap">
                        <button style={{display: "none"}} id="deleteBtn">숨겨진 삭제 버튼</button>
                        <button style={{display: "none"}} ref={submitRef}>숨겨진 삭제 버튼2</button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className='tableshort'>
                                        <label htmlFor="selectAll" className="chk_box">
                                            <input 
                                                type="checkbox" 
                                                id="selectAll" 
                                                className="checkAll" 
                                                onChange={checkAll}/>
                                            <span className="checkon"></span>
                                        </label>
                                    </th>
                                    <th scope="col" className='tableshort'>No</th>
                                    <th scope="col">관리자 아이디</th>
                                    <th scope="col">관리자 이름</th>
                                    <th scope="col">생성 날짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admin.appManagerResponseDtoList.length !== 0 ?
                                        admin.appManagerResponseDtoList.map((adm, idx) => (
                                            <tr onClick={() => navigator(adm.appManagerCode)} key={idx}>
                                                <td onClick={(e) => {e.stopPropagation();}} className='tableshort'>
                                                    <label htmlFor={adm.appManagerCode} className="chk_box">
                                                        <input 
                                                            type="checkbox" 
                                                            id={adm.appManagerCode} 
                                                            name="appManagerCode" 
                                                            className="chkbox" 
                                                            value={adm.appManagerCode} 
                                                            ref={e => ref.current[idx] = e}/>
                                                        <span className="checkon"></span>
                                                    </label>
                                                </td>
                                                <td className='tableshort'>{adm.idx}</td>
                                                <td>{adm.appManagerEmail}</td>
                                                <td>{adm.appManagerName}</td>
                                                <td>{adm.createAt}</td>
                                            </tr>
                                        )) : <td colSpan={5}><div className='nodata'><p>등록된 관리자가 없습니다</p></div></td>
                                }
                            </tbody>
                        </table>
                    </div>
                </Form>
                <Input type={"hidden"} error={errors?.error}/>
                <Input type={"hidden"} error={errors?.appManagerCode}/>
                <Pagination
                    initialPage={Number(param.page)}
                    onChange={onChangeHandle}
                    totalCount={admin.totalCount}/>
            </div>
        </div>
    )
}

export default AdminSetting