import React from 'react'
import { POST_METHOD } from '../../const/const';
import { Form } from 'react-router-dom';
import { MYPAGE_PATH } from '../../const/path';
import { useNavigate } from 'react-router-dom'


const Header = ({ name, profile }) => {
  const navigator = useNavigate();
  

  return (
    <header className="p-3 mb-3">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-end justify-content-lg-end">
          <button>앱 다운로드 QR코드 보기</button>
          <Form method={POST_METHOD} action='/logout'>
            <button className="logoutBtn">
              <img src={`${process.env.PUBLIC_URL}/img/icon_logout.svg`} alt="로그아웃 버튼"/>
              <div className='hover'>로그아웃</div>
            </button>
          </Form>
          <div className="profile"  onClick={() => {navigator(MYPAGE_PATH)}}>
            <img src="/img/icon_person.png" alt="mdo" width="24" height="24" className="rounded-circle"/> 
            <p><span>{name}</span> 님</p>
            <div className='hover'>마이페이지</div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header