import React, { useState } from 'react'
import Button from '../../../atom/button'
import { companyDeleteQuery, companyListQuery } from '../../../../queries/companyQueries';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { redirect, useNavigate } from 'react-router-dom';
import CompanyDeletePopup from '../../../components/popup/companyDeletePopup';
import { SETTING_COMPANY_PATH, UPDATE_PATH, SETTING_PATH } from '../../../../const/path';
import { FILE_HOST } from '../../../../server/host';

export const companyDetailLoader = 
    (queryClient) => async() => {
        await queryClient.fetchQuery(companyListQuery());
        return null;
    }

export const companyDeleteAction = 
    (queryClient) => async() => {
        const response = await queryClient.fetchQuery(companyDeleteQuery());

        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_COMPANY_PATH}`);
        }
    }

const CompanyRead = () => {
    const navigator = useNavigate();
    const { data: { data } } = useQuery(companyListQuery());
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const onClickDeleteBtn = () => {
        setIsDeletePopupOpen(true);
    }

    const onDeleteCancle = (e) => {
        e.preventDefault();
        setIsDeletePopupOpen(!isDeletePopupOpen);
    }

    return (
        <div className="main">
            {
                isDeletePopupOpen ? 
                    <CompanyDeletePopup onCancle={onDeleteCancle}/> 
                    : ""
            }
            <div className="contentBg">
            <h2 className="subTit">대표 정보 관리</h2>
            
            <div className='formWrap'>
                <div className="mb-4 d-grid">
                    <label className="form-label">대표명</label>
                    <div>
                        <div>{ data?.companyName }</div>
                        <p className='info'>회사(단체)의 이름입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">대표자명</label>
                    <div>
                        <div>{ data?.companyOwnerName }</div>
                        <p className='info'>회사(단체)의 대표자 이름입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">대표 이메일</label>
                    <div>
                        <div>{ data?.companyEmail }</div>
                        <p className='info'>회사(단체)를 대표하는 메일 계정입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">대표번호</label>
                    <div>
                        <div>{ data?.companyPhone }</div>
                        <p className='info'>회사(단체)의 회사 대표번호입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">사업자등록번호</label>
                    <div>
                        <div>{ data?.companyBusinessNumber }</div>
                        <p className='info'>회사(단체)의 사업자등록번호입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">통신판매번호</label>
                    <div>
                        <div>{ data?.companyMailOrderNumber }</div>
                        <p className='info'>회사(단체)의 통신판매번호입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">주소</label>
                    <div>
                        <div>{ data?.companyAddressResponseDto.fullLocation }</div>
                        <p className='info'>회사(단체)의 회사 주소입니다</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label className="form-label">대표 로고</label>
                    <div className="logoimg">
                        {
                            data?.companyBI.map(file => (
                                <img src={`${FILE_HOST}${file.fleUrl}`} alt="회사 로고이미지" key={file.fleUrl}/>
                            ))
                        }
                        <p className="info">회사(단체)을 대표하는 로고입니다.</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label htmlFor="formFileMultiple" className="form-label">소개</label>
                    <div>
                        <div>{ parse(data?.companyIntro ?? "") }</div>
                        <p className="info">회사(단체)의 소개 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label htmlFor="formFileMultiple" className="form-label">연혁</label>
                    <div>
                        <div>{ parse(data?.companyHistory ?? "") }</div>
                        <p className="info">회사(단체)의 연혁입니다. 앱의 회사 소개에 노출됩니다.</p>
                    </div>
                </div>
                <div className="mb-4 d-grid">
                    <label htmlFor="formFileMultiple" className="form-label">오시는길</label>
                    <div>
                        <div>{ parse(data?.companyAddressResponseDto.addressAdditionalDescription ?? "") }</div>
                        <p className="info">회사(단체)의 위치 안내 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                    </div>
                </div>
            </div>

                <div className="btnWrap d-flex justify-content-end align-items-center">
                    <Button content={"대표정보 삭제"} onClick={onClickDeleteBtn}/>
                    <Button content={"대표정보 수정"} onClick={() => navigator(UPDATE_PATH)}/>
                </div>
            </div>
        </div>
    );
}

export default CompanyRead