import { USER_BOARD_BLIND_CONTENT_DEFINE, USER_BOARD_BLIND_REPLY_DEFINE, USER_BOARD_LIST_DEFINE, USER_BOARD_READ_DEFINE, USER_BOARD_UNBLIND_CONTENT_DEFINE, USER_BOARD_UNBLIND_REPLY_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const userBoardListQuery = (queryParam) => ({
    queryKey: ["userBoardList", queryParam.page],
    queryFn: async() => {
        const json = await fetchDataPathVar(USER_BOARD_LIST_DEFINE, {}, queryParam);
        return json;
    }
})

export const userBoardReadQuery = (queryParam) => ({
    queryKey: ["userBoardRead", queryParam.menuCode, queryParam.code],
    queryFn: async() => {
        const json = await fetchDataPathVar(USER_BOARD_READ_DEFINE, {}, queryParam);
        return json;
    }
})

export const userBoardBlindContentQuery = (param) => ({
    queryKey: ["userBoardBlindContentQuery"],
    queryFn: async() => {
        const json = await fetchData(USER_BOARD_BLIND_CONTENT_DEFINE, param);
        return json;
    }
})

export const userBoardUnBlindContentQuery = (param) => ({
    queryKey: ["userBoardUnBlindContentQuery"],
    queryFn: async() => {
        const json = await fetchData(USER_BOARD_UNBLIND_CONTENT_DEFINE, param);
        return json;
    }
})

export const userBoardBlindReplyQuery = (param) => ({
    queryKey: ["userBoardBlindReplyQuery"],
    queryFn: async() => {
        const json = await fetchData(USER_BOARD_BLIND_REPLY_DEFINE, param);
        return json;
    }
})

export const userBoardUnBlindReplyQuery = (param) => ({
    queryKey: ["userBoardUnBlindReplyQuery"],
    queryFn: async() => {
        const json = await fetchData(USER_BOARD_UNBLIND_REPLY_DEFINE, param);
        return json;
    }
})