import React from 'react'
import Button from '../../../atom/button'
import Input from '../../../atom/input';
import { Form, useActionData, useLoaderData, redirect, useNavigate } from 'react-router-dom';
import GroupSelect from '../../../components/groupSelect';
import { DISABLE_STATUS, ENABLE_STATUS, POST_METHOD } from '../../../../const/const';
import { useQueries } from '@tanstack/react-query';
import IconUploader from '../../../components/IconUploader';
import FunctionSelector from '../../../components/FunctionSelector';
import { boardContentTypeQuery, boardIconQuery, boardReadQuery, boardUpdateQuery } from '../../../../queries/boardQueries';
import { checkEnDisable, makeParamsFromFormData } from '../../../../util/util';
import { SETTING_BOARD_PATH, SETTING_PATH } from '../../../../const/path';
import { allGroupListForCreateOrUpdateQuery } from '../../../../queries/groupQueries';

export const boardUpdateLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(boardReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(boardReadQuery(params));
        }
        if(!queryClient.getQueryData(boardContentTypeQuery().queryKey)) {
            (await queryClient.fetchQuery(boardContentTypeQuery()));
        }
        if(!queryClient.getQueryData(boardIconQuery().queryKey)) {
           (await queryClient.fetchQuery(boardIconQuery()));
        }
        if(!queryClient.getQueryData(allGroupListForCreateOrUpdateQuery().queryKey)) {
            (await queryClient.fetchQuery(allGroupListForCreateOrUpdateQuery()));
         }
        return { params };
    }

export const boardUpdateAction = 
    (queryClient) => async({params, request}) => {
        const formData = await request.formData();
        const formParams = makeParamsFromFormData(formData, ["capabilityStatus", "groupList"]);
        const error = {};

        if(!formParams.name) {
            error.name = "그룹 이름은 필수 값입니다.";
            return error;
        }
        else if(!formParams.groupList) {
            error.groupList = "게시판을 조회 할 수 있는 그룹은 필수 값입니다.";
            return error;
        }
        
        const response = await queryClient.fetchQuery(boardUpdateQuery(formParams));
        if(response.result === "SUCCESS") {
            
            return redirect(`${SETTING_PATH}/${SETTING_BOARD_PATH}/${response.data.code}`);
        }

        return null;
}

const BoardUpdate = () => {
    const navigator = useNavigate();
    const errors = useActionData();
    const { params } = useLoaderData();
    const [boardContentTypeList, boardIconList, boardDetail, groupList] = useQueries({
        queries: [boardContentTypeQuery(), boardIconQuery(), boardReadQuery(params), allGroupListForCreateOrUpdateQuery()],
    })
    const contentInfo = boardDetail.data.data.contentInfo;

    return (
        <div className="main boardWrap">
            <div className="contentBg">
                <h2 className="subTit">게시판 수정</h2>
                <Form method={POST_METHOD} replace>
                    <div className="formWrap">
                        <input type='hidden' name='code' value={params.id}/>
                        <Input
                            label={"게시판 이름"}
                            placeholder={"게시판 이름을 작성해주세요."}
                            name={"name"}
                            comment={"게시판을 대표하는 이름입니다."}
                            error={errors?.name}
                            defaultValue={contentInfo.name}
                            required
                        />
                        <input type='hidden' name='sequence' value={0}/>
                        
                        <IconUploader data={boardIconList.data} defaultValue={contentInfo.fileInfo}/>
                        <FunctionSelector data={boardContentTypeList.data.data.capabilityInfo} defaultValue={contentInfo.capabilityInfo}/>
                        <div className="mb-4 d-grid">
                            <label htmlFor="boardGroup" className="form-label">게시판 접속 그룹</label>
                            <div>
                                <GroupSelect 
                                    data={groupList.data.data} 
                                    selectData={contentInfo.groupList}
                                    groupInputName={"groupList"} 
                                    showMember={false}
                                    error={errors?.groupList}/>
                                <p className="info">해당 게시판을 볼 수 있는 권한을 설정하실 수 있습니다. 선택하지 않은 그룹은 해당 게시판을 읽을 수 없습니다.</p>
                            </div>
                        </div>

                        {/* <div className="mb-4  d-grid">
                            <label htmlFor="board" className="form-label">댓글 사용여부</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="replyStatus" id="replyUse"
                                    value={ENABLE_STATUS} defaultChecked={checkEnDisable(contentInfo.replyStatus, ENABLE_STATUS)}/>
                                    <label className="form-check-label" htmlFor="replyUse">사용</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="replyStatus" id="replyNotuse"
                                    value={DISABLE_STATUS} defaultChecked={checkEnDisable(contentInfo.replyStatus, DISABLE_STATUS)}/>
                                    <label className="form-check-label" htmlFor="replyNotuse">미사용</label>
                                </div>
                                <p className="info">미사용시 해당 게시판에서 댓글을 작성할 수 없습니다. </p>
                            </div>
                        </div> */}

                        <div className="mb-4  d-grid">
                            <label htmlFor="board" className="form-label">게시판 사용여부</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="use"
                                    value={ENABLE_STATUS} defaultChecked={checkEnDisable(contentInfo.status, ENABLE_STATUS)}/>
                                    <label className="form-check-label" htmlFor="use">사용</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="notuse"
                                    value={DISABLE_STATUS} defaultChecked={checkEnDisable(contentInfo.status, DISABLE_STATUS)}/>
                                    <label className="form-check-label" htmlFor="notuse">미사용</label>
                                </div>
                                <p className="info">미사용시 앱에서 해당 게시판을 볼 수 없습니다. </p>
                            </div>
                        </div>
                    </div>
                    <div className='btnWrap justify-content-end'>  
                        <div className='off'>
                            <Button content={"취소"} onClick={(e) => {e.preventDefault(); navigator(-1)}}/>
                        </div>
                        <Button content={"게시판 수정"}/>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default BoardUpdate