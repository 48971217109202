import React, { useState } from 'react'
import Button from '../../../atom/button'
import { useFetcher, useNavigate, Form, redirect, useActionData } from 'react-router-dom'
import { GET_METHOD, POST_METHOD } from '../../../../const/const';
import { adminSearchQuery, adminCreateQuery } from '../../../../queries/adminQueries';
import { isEmptyObject, makeParamsFromFormData } from '../../../../util/util';
import { SETTING_ADMIN_PATH, SETTING_PATH } from '../../../../const/path';
import Input from '../../../atom/input';


export const adminSearchLoader = 
    (queryClient) => async({request}) => {
        const url = new URL(request.url);
        const keyword = url.searchParams.get('keyword');
        const params = {
            keyword : keyword
        }

        if(!params.keyword) return null;
        
        if(!queryClient.getQueryData(adminSearchQuery(params).queryKey)) {
            return await queryClient.fetchQuery(adminSearchQuery(params));
        } else {
            const data = queryClient.getQueryData(adminSearchQuery(params).queryKey);
            return data;
        }
    }

export const adminCreateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {};
        
        if(isEmptyObject(params)) {
            errors.appMemberCode = "권한을 부여할 회원을 선택해주세요.";
            return errors;
        }

        const response = await queryClient.fetchQuery(adminCreateQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_ADMIN_PATH}`);
        } else {
            errors.error = response.message;
            return errors;
        }
    }

const AdminSettingCreate = () => {
    const navigator = useNavigate();
    const fetcher = useFetcher();
    const [selectUser, setSelectUser] = useState("");
    const errors = useActionData();

    const searchData = (e) => {
        fetcher.submit(
            { keyword: e.target.value },
            { method: GET_METHOD }
        )
    }

    const onClickUser = (value) => {
        setSelectUser({
            appMemberCode: value.appMemberCode,
            appMemberName: value.appMemberName,
            appMemberEmail: value.appMemberEmail,
        });
    }

    const renderSearchResult = (result) => {
        if(result.data.length !== 0) {
            return (
                <ul>
                    {
                        result.data.map(d => (
                            <li key={d.appMemberCode} onClick={() => onClickUser(d)}>
                                {d.appMemberName} <span>{d.appMemberEmail}</span>
                            </li>
                        ))
                    }
                </ul>
            );   
        } else {
            return (
                <ul>
                    <li>검색 결과가 없습니다.</li>
                </ul>
            )
        }
    }

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">관리자 권한부여</h2>
                <div className="formWrap">
                    <div className="mb-4 d-grid">
                        <label className="form-label">관리자 계정 검색</label>
                        <div className="search">
                            <input type="text" name='keyword' placeholder="검색어 입력" onChange={searchData} autoComplete='off'/>
                            <button><img src={process.env.PUBLIC_URL + "/img/search_b.png"} alt='검색이미지'/> </button>
                            <p className='info'>하위 관리자 권한을 부여할 계정을 검색해주세요. 소셜계정은 지정하실 수 없습니다.</p>
                            {/* 검색 시 뜨는창 */}
                            {
                                fetcher.data ? renderSearchResult(fetcher.data) : ""
                            }
                        </div>
                    </div>
                </div>
                <Form method={POST_METHOD} className='result formWrap'>
                    <div className='mb-4 d-grid'>
                    <label className="form-label">선택된 계정</label>
                    <div>
                        {
                            selectUser ? 
                                <div>
                                    {selectUser.appMemberName} / {selectUser.appMemberEmail}
                                    <Input type='hidden' name='appMemberCode' defaultValue={selectUser.appMemberCode}/>
                                    <Input type={'hidden'} error={errors?.error}/>
                                </div>
                                : ""
                        }
                        <Input type={'hidden'} error={errors?.appMemberCode}/>
                    </div>
                    </div>
                    <div className='btnWrap justify-content-end'>  
                        <div className='off'>
                            <Button content={"취소"} onClick={(e) => {e.preventDefault(); navigator(-1);}}/>
                        </div>
                        <Button content={"관리자 권한부여"}/>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AdminSettingCreate