import { GET_METHOD, PUT_METHOD } from "../../const/const";
import { MEMBER_CHANGE_GRADE_DEFINE, MEMBER_LIST_DEFINE, MEMBER_READ_DEFINE, MEMBER_UPDATE_DEFINE } from "../../const/defineName";
import { MEMBER_CHANGE_GRADE_URL, MEMBER_LIST_URL, MEMBER_READ_URL, MEMBER_UPDATE_URL } from "../../const/url";

export const memberDefine ={
    [MEMBER_LIST_DEFINE] : {
        method: GET_METHOD,
        url: MEMBER_LIST_URL,
    },
    [MEMBER_READ_DEFINE] : {
        method: GET_METHOD,
        url: MEMBER_READ_URL,
    },
    [MEMBER_UPDATE_DEFINE] : {
        method: PUT_METHOD,
        url: MEMBER_UPDATE_URL,
    },
    [MEMBER_CHANGE_GRADE_DEFINE] : {
        method: PUT_METHOD,
        url: MEMBER_CHANGE_GRADE_URL,
    },
}