import React, { useState } from 'react'
import Button from '../../../atom/button'
import Input from '../../../atom/input';
import Editor from '../../../atom/editor';
import { Form, redirect, useActionData } from 'react-router-dom';
import { POST_METHOD } from '../../../../const/const';
import { myInfoQuery } from '../../../../queries/adminQueries';
import FileUploader from '../../../atom/fileUploader';
import { makeParamsFromFormData, uploadFile } from '../../../../util/util';
import AddressSearchPopup from '../../../components/popup/addressSearchPopup';
import { FILE_POST_DEFINE } from '../../../../const/defineName';
import { companyCreateQuery } from '../../../../queries/companyQueries';
import { SETTING_COMPANY_PATH, SETTING_PATH } from '../../../../const/path';

export const companyReadLoader = 
    (queryClient) => async() => {
        if(!queryClient.getQueryData(myInfoQuery().queryKey)) {
            await queryClient.fetchQuery(myInfoQuery());
        }

        return null;
    }

export const companyCreateAction =
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {}

        const companyAddressRequestDto = {
            fullLocation: params.fullLocation,
            depthOne: params.depthOne,
            depthTwo: params.depthTwo,
            depthThree: params.depthThree,
            streetName: params.streetName,
            postNum: params.postNum,
            addressDetail: params.addressDetail,
            addressAdditionalDescription: params.addressAdditionalDescription
        };

        params.companyAddressRequestDto = companyAddressRequestDto;

        if(!params.companyName) {
            errors.companyName = "회사 이름은 필수 값입니다.";
            return errors;
        } else if(!params.companyOwnerName) {
            errors.companyOwnerName = "대표자명은 필수 값입니다.";
            return errors;
        } else if(!params.companyEmail) {
            errors.companyEmail = "대표 이메일은 필수 값입니다.";
            return errors;
        } else if(!params.companyPhone) {
            errors.companyPhone = "대표번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyBusinessNumber) {
            errors.companyBusinessNumber = "사업자등록번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyMailOrderNumber) {
            errors.companyMailOrderNumber = "통신판매번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyIntro) {
            errors.companyIntro = "소개는 필수 값입니다.";
            return errors;
        } else if(!params.companyHistory) {
            errors.companyHistory = "연혁은 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.postNum) {
            errors.postNum = "우편번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.addressDetail) {
            errors.addressDetail = "상세주소는 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.addressAdditionalDescription) {
            errors.addressAdditionalDescription = "오시는길은 필수 값입니다.";
            return errors;
        }

        if(formData.getAll("file")[0].name) {
            const fileId = await uploadFile(FILE_POST_DEFINE, formData);
            params.companyBI = fileId;
        }

        const response = await queryClient.fetchQuery(companyCreateQuery(params));
        
        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_COMPANY_PATH}`);
        }

        return errors;
    }

const CompanyCreate = () => {
    const [isAddressPopupOpen, setIsAddressPopupOpen] = useState(false);
    const [addressData, setAddressData] = useState({});
    const errors = useActionData();

    const openAddressPopup = (e) => {
        e.preventDefault();
        setIsAddressPopupOpen(true);
    }

    const onCompleteAddress = (data) => {
        const addData = {
            fullLocation: data.address,
            depthOne: data.sido,
            depthTwo: data.sigungu,
            depthThree: data.bname,
            streetName: data.roadAddress,
            postNum: data.zonecode,
        }
        setAddressData(addData);
        setIsAddressPopupOpen(false);
    }

    return (
        <Form method={POST_METHOD} encType='multipart/form-data'>
            <div className="main">
                <div className="contentBg">
                <h2 className="subTit">대표 정보 생성</h2>
                {
                    isAddressPopupOpen ?
                    <AddressSearchPopup onComplete={onCompleteAddress}/> : ""
                }
                {
                    Object.keys(addressData).map((key) => (
                        <input type='hidden' name={key} defaultValue={addressData[key]} key={key}/>
                    ))
                }

                <div className='formWrap'>
                    <Input 
                        label={"회사(단체)명"}
                        placeholder={"회사(단체)명을 입력해주세요"}
                        comment={"회사(단체)의 이름을 입력해주세요"}
                        name={"companyName"}
                        error={errors?.companyName}
                        required/>
                    <Input 
                        label={"대표자명"}
                        placeholder={"대표자명을 입력해주세요"}
                        comment={"회사(단체)의 대표자 이름을 입력해주세요"}
                        name={"companyOwnerName"}
                        error={errors?.companyOwnerName}
                        required/>
                    <Input 
                        label={"대표 이메일"}
                        placeholder={"대표 이메일을 입력해주세요"}
                        comment={"회사(단체)를 대표하는 메일 계정입니다."}
                        name={"companyEmail"}
                        error={errors?.companyEmail}
                        required/>
                    <Input 
                        label={"대표번호"}
                        placeholder={"회사 대표번호을 입력해주세요"}
                        comment={"회사(단체)의 회사 대표번호입니다."}
                        name={"companyPhone"}
                        error={errors?.companyPhone}
                        required/>
                    <Input 
                        label={"사업자등록번호"}
                        placeholder={"사업자등록번호를 입력해주세요"}
                        comment={"회사(단체)의 사업자등록번호입니다."}
                        name={"companyBusinessNumber"}
                        error={errors?.companyBusinessNumber}
                        required/>
                    <Input 
                        label={"통신판매번호"}
                        placeholder={"통신판매번호을 입력해주세요"}
                        comment={"회사(단체)의 통신판매번호입니다.."}
                        name={"companyMailOrderNumber"}
                        error={errors?.companyMailOrderNumber}
                        required/>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">주소</label>
                        <div>
                            <div className="address">
                                <div className="d-flex">
                                    <Input 
                                        type="text" 
                                        className="form-control" 
                                        id="company_address" 
                                        defaultValue={addressData.postNum} 
                                        placeholder="우편번호" 
                                        error={errors?.postNum}
                                        readOnly/> 
                                    <button onClick={openAddressPopup}>우편번호 찾기</button>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="company_address" 
                                    defaultValue={addressData.fullLocation} 
                                    placeholder="기본 주소를 입력해주세요"
                                    readOnly/>
                                <Input 
                                    type="text" 
                                    className="form-control" 
                                    id="company_address" 
                                    name='addressDetail'
                                    placeholder="상세 주소를 입력해주세요"
                                    error={errors?.addressDetail}/>
                                <p className="warning"></p>
                            </div>
                            <p className="info">회사(단체)의 회사 주소입니다.</p>
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">대표 로고</label>
                        <div>
                            <FileUploader maxLength={1}/>
                            {/* <div>
                                <div className="filebox">
                                    <label className="input-file-button" htmlFor="input-file">
                                        파일 업로드
                                    </label>
                                    <input type="file" id="input-file"/> 
                                </div>
                                <p className="info">회사(단체)을 대표하는 회사 로고입니다. 파일형식 .png, .svg</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">소개</label>
                        <div>
                            <Editor
                                name={"companyIntro"}
                                error={errors?.companyIntro}/>
                            <p className="info">회사(단체)의 소개 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">연혁</label>
                        <div>
                            <Editor
                                name={"companyHistory"}
                                error={errors?.companyHistory}/>
                            <p className="info">회사(단체)의 연혁입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">오시는길</label>
                        <div>
                            <Editor
                                name={"addressAdditionalDescription"}
                                error={errors?.addressAdditionalDescription}/>
                            <p className="info">회사(단체)의 위치 안내 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                </div>

                <div className="btnWrap d-flex justify-content-end align-items-center">
                    <Button content={"대표정보 생성"}/>
                </div>
                </div>
            </div>
        </Form>
    );
}

export default CompanyCreate