import React from 'react'
import Button from '../../../atom/button'
import Pagination from '../../../atom/pagination'
import { useNavigate, useSearchParams, useLoaderData } from 'react-router-dom'
import { CREATE_PATH } from '../../../../const/path'
import { groupListQuery } from '../../../../queries/groupQueries'
import { useQuery } from "@tanstack/react-query"
import { PAGE_PER_SIZE } from '../../../../const/const'

export const groupListLoader = 
    (queryClient) => async({request}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
        
        const param = {
            page: page,
            size: size,
        }
                
        if(!queryClient.getQueryData(groupListQuery(param).queryKey)) {
            await queryClient.fetchQuery(groupListQuery(param));
        }
        return { param };
    }

const Group = () => {
    const navigator = useNavigate();
    const { param } = useLoaderData();
    const { data: {data: appGroupData} } = useQuery(groupListQuery(param));
    const [, setSearchParams ] = useSearchParams();

    const onChangeHandle = (page) => {
        setSearchParams({page: page.selected});
    }

    return (
        <div className="main">
            <div className="contentBg">
                <div className='d-flex justify-content-between align-items-center subTitWrap'>
                    <h2 className="subTit">그룹 리스트</h2>
                    <Button content={"그룹 생성"} onClick={() => {navigator(CREATE_PATH)}}/>
                </div>
                <div className="tableWrap">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col" className='tableshort'>No</th>
                                <th scope="col">그룹 이름</th>
                                <th scope="col">그룹 회원 관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                appGroupData.appGroupList.length !== 0 ?
                                    appGroupData.appGroupList.map(group => (
                                        <tr key={group.groupCode} onClick={() => {navigator(group.groupCode)}}>
                                            <td className='tableshort'>1</td>
                                            <td>{group.groupName}</td>
                                            <td>
                                                <button>관리</button>
                                            </td>
                                        </tr>
                                    )) : <td colSpan={3}><div className='nodata'><p>등록된 그룹이 없습니다</p></div></td>
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination
                    initialPage={Number(param.page)}
                    onChange={onChangeHandle}
                    totalCount={appGroupData.totalCount}/>

            </div>
        </div>
    )
}

export default Group