import React from 'react'
import Input from '../../../atom/input'
import Editor from '../../../atom/editor'
import FileUploader from '../../../atom/fileUploader'
import Button from '../../../atom/button'
import { Form, useActionData, redirect, useNavigate } from 'react-router-dom'
import { POST_METHOD } from '../../../../const/const'
import { makeParamsFromFormData, uploadFile } from '../../../../util/util'
import { FILE_POST_DEFINE } from '../../../../const/defineName'
import { noticeCreateQuery } from '../../../../queries/noticeQueries'
import { CONTENT_NOTICE_LIST_PATH, CONTENT_PATH } from '../../../../const/path'
import ToggleBtn from '../../../atom/toggleBtn'

export const noticeAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        let fileId = null;
        const params = makeParamsFromFormData(formData);
        const errors = {};

        params.notification = !!params.notification;

        if(!params.noticeTitle) {
            errors.noticeTitle = "공지사항 제목을 입력해주세요.";
            return errors;
        }
        if(!params.noticeStartDate) {
            errors.noticeStartDate = "공지사항 게시일을 입력해주세요.";
            return errors;
        }
        if(!params.noticeContent) {
            errors.noticeContent = "공지사항 내용을 입력해주세요.";
            return errors;
        } 

        if(!formData.getAll("file").name) {
            fileId = await uploadFile(FILE_POST_DEFINE, formData);
        }

        params.fileId = fileId;
        
        const response = await queryClient.fetchQuery(noticeCreateQuery(params));
        
        if(response.result === "SUCCESS") {
            return redirect(`${CONTENT_PATH}/${CONTENT_NOTICE_LIST_PATH}/${response.data.noticeCode}`);
        } else {
            errors.error = response.message;
        }

        return errors;
    }

const NoticeCreate = () => {
    const navigator = useNavigate();
    const errors = useActionData();

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">공지사항 글쓰기</h2>

                <Form method={POST_METHOD} encType='multipart/form-data' replace>
                    <div className="formWrap">
                        <Input 
                            label={"공지사항 제목"}
                            name={"noticeTitle"}
                            placeholder={"제목을 작성해주세요"}
                            comment={"게시글의 제목입니다."}
                            error={errors?.noticeTitle}
                            key={"noticeTitle"}
                            required/>
                        <Input 
                            label={"공지사항 게시 날짜"}
                            type={"date"}
                            name={"noticeStartDate"}
                            comment={"게시글이 앱에 게시되는 날짜입니다. 미리 작성 후 예약 날짜를 설정하실 수 있습니다."}
                            error={errors?.noticeStartDate}
                            key={"noticeStartDate"}
                            required/>

                        <div className="mb-4 d-grid">
                            <label htmlFor="formFileMultiple" className="form-label required">공지사항 내용</label>
                            <div>
                            <Editor
                                name={"noticeContent"}
                                error={errors?.noticeContent}
                                key={"noticeContent"}/>
                            </div>
                        </div>
                        
                        <FileUploader/>
                        <ToggleBtn 
                            name={"notification"}
                            value={true} 
                            description={"알림전송"}/>
                    </div>
                    <p className="warning">{errors?.error}</p>
                    <div className='btnWrap justify-content-end'>  
                        <div className='off'>
                            <Button content={"취소"} onClick={(e) => {
                                e.preventDefault();
                                navigator(-1);
                            }}/>
                        </div>
                        <Button content={"공지사항 작성"}/>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default NoticeCreate