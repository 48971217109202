import React from 'react'

const AdminDeletePopup = ({onCancle, onSubmit}) => {
  return (
    <div className='popupWrap'>
        <div className='popupbg'>
            <h3>관리자 권한 삭제</h3>
            <span>선택한 회원의 관리자 권한을 제거 할 수 있습니다.</span>
            <div className='btnwrap'>
                <button onClick={onCancle}>취소</button>
                <button className='yes' onClick={onSubmit}>삭제</button>
            </div>
        </div>
    </div>
  )
}

export default AdminDeletePopup