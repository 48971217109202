import { Form, redirect, useOutletContext, useActionData } from "react-router-dom";
import { FILE_POST_DEFINE } from "../../../../../const/defineName";
import { replyCreateQuery } from "../../../../../queries/inquiryQueries";
import { makeParamsFromFormData, uploadFile } from "../../../../../util/util";
import FileUploader from "../../../../atom/fileUploader"
import Input from "../../../../atom/input"
import { CONTENT_INQUIRY_LIST_PATH, CONTENT_PATH } from "../../../../../const/path";
import { POST_METHOD } from "../../../../../const/const";
import Editor from "../../../../atom/editor";

export const replyCreateAction = 
  (queryClient) => async({request}) => {
    const formData = await request.formData();
    const params = makeParamsFromFormData(formData);
    const errors = {};

    if(!params.title) {
        errors.title = "답변 제목은 필수 값입니다.";
        return errors;
    }
    if(!params.content) {
        errors.content = "답변은 필수 값입니다.";
        return errors;
    }

    if(!formData.get("fileId")) {
      const fileId = await uploadFile(FILE_POST_DEFINE, formData);
      params.fileId = fileId;
    } else {
        await uploadFile(FILE_POST_DEFINE, formData);
    }
    const response = await queryClient.fetchQuery(replyCreateQuery(params));

    if(response.result === "SUCCESS") {
        return redirect(`${CONTENT_PATH}/${CONTENT_INQUIRY_LIST_PATH}/${params.qnaCode}`);
    }
    

    return null;
  }

const ReplyCreate = () => {
    const { inquiryCode } = useOutletContext();
    const errors = useActionData();

    return (
        <Form method={POST_METHOD} encType='multipart/form-data'>
            <div className="contentBg">
                <h3>문의 답변 생성</h3>
                <input type='hidden' name='qnaCode' value={inquiryCode}/>
                <div className="tableWrap4">
                    <table className="table">
                    <tbody>
                        <tr>
                            <th className="required">제목</th>
                            <td>
                                <Input
                                    placeholder={"답변 제목을 작성해주세요"}
                                    name={"title"}
                                    comment={"제목을 100자 이내로 작성해주세요"}
                                    error={errors?.title}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">내용</th>
                            <td>
                                <Editor
                                    name={"content"}
                                    error={errors?.content}
                                    required/>
                            </td>
                        </tr>
                        <tr className="line"></tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                                <FileUploader/>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <div className="btnWrap d-flex justify-content-end align-items-center">
                        <button>답변하기</button>
                    </div>
                </div>
            </div> 
        </Form>
    )
}

export default ReplyCreate;