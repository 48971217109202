import React from 'react'
import MenuTitle from '../atom/menuTitle'
import MenuGroup from '../atom/menuGroup'
import Menu from '../atom/menu'
import { 
  CONTENT_CALENDAR_PATH
  , CONTENT_INQUIRY_LIST_PATH
  , CONTENT_MEMBER_LIST_PATH
  , CONTENT_NOTICE_LIST_PATH
  , CONTENT_PATH
  , DASHBOARD_PATH,
  GUIDE_PATH,
  SETTING_APP_PATH,
  SETTING_BOARD_PATH,
  SETTING_GROUP_PATH,
  SETTING_PATH,
  SETTING_ENV_PATH,
  SETTING_ADMIN_PATH,
  SETTING_COMPANY_PATH,
  } from '../../const/path'
import { ROLE_APP_ADMIN } from '../../const/const'
import { useLocation } from 'react-router-dom'

const Sidebar = ({ auth }) => {
  const location = useLocation();

  return (
    <div className="sidebar p-3">
      <a href="/" className="d-flex align-items-center justify-content-center pb-3 mb-3">
        <img src={`${process.env.PUBLIC_URL}/img/logo_w.svg`} alt="로고이미지"/>
      </a>
      <ul className="list-unstyled ps-0">
        <MenuTitle 
          src={`${process.env.PUBLIC_URL}/img/icon_home.svg`}
          alt={"홈 아이콘"}
          to={DASHBOARD_PATH}
          content={"대시보드"}/>

        <MenuGroup
          id={"content"}
          src={`${process.env.PUBLIC_URL}/img/icon_contants.svg`}
          alt={"컨텐츠 아이콘"}
          buttonContent={"컨텐츠 관리"}
          expanded={true}>
            <Menu to={CONTENT_PATH + "/" + CONTENT_NOTICE_LIST_PATH} src={`${process.env.PUBLIC_URL}/img/icon_notice.svg`} content={"공지사항"} alt={"공지 아이콘"}/>
            <Menu to={CONTENT_PATH + "/" + CONTENT_INQUIRY_LIST_PATH} src={`${process.env.PUBLIC_URL}${process.env.PUBLIC_URL}/img/icon_ask.svg`} content={"1:1 문의"} alt={"문의 아이콘"}/>
            <Menu to={CONTENT_PATH + "/" + CONTENT_MEMBER_LIST_PATH} src={`${process.env.PUBLIC_URL}/img/icon_member.svg`} content={"회원 관리"} alt={"회원 아이콘"}/>
            <Menu to={CONTENT_PATH + "/" + CONTENT_CALENDAR_PATH} src={`${process.env.PUBLIC_URL}/img/icon_calendar.svg`} content={"일정 관리"} alt={"일정 아이콘"}/>
        </MenuGroup>
          {
            auth === ROLE_APP_ADMIN ?
            <MenuGroup
              id={"setting"}
              src={`${process.env.PUBLIC_URL}/img/icon_setting.svg`}
              alt={"설정 아이콘"}
              buttonContent={"설정 관리"}
              expanded={location.pathname.includes(SETTING_PATH)}
              >
                <Menu to={SETTING_PATH + "/" + SETTING_APP_PATH} src={`${process.env.PUBLIC_URL}/img/icon_phone.svg`} alt={"폰 아이콘"} content={"앱 관리"}/>
                <Menu to={SETTING_PATH + "/" + SETTING_COMPANY_PATH} src={`${process.env.PUBLIC_URL}/img/icon_company.svg`} content={"대표정보 관리"} alt={"대표 아이콘"}/>
                <Menu to={SETTING_PATH + "/" + SETTING_BOARD_PATH} src={`${process.env.PUBLIC_URL}/img/icon_menu.svg`} alt={"메뉴 아이콘"} content={"게시판 관리"}/>
                <Menu to={SETTING_PATH + "/" + SETTING_GROUP_PATH} src={`${process.env.PUBLIC_URL}/img/icon_group.svg`} alt={"그룹 아이콘"} content={"그룹 관리"}/>
                <Menu to={SETTING_PATH + "/" + SETTING_ENV_PATH} src={`${process.env.PUBLIC_URL}/img/icon_setting.svg`} alt={"설정 아이콘"} content={"사용환경설정"}/>
                <Menu to={SETTING_PATH + "/" + SETTING_ADMIN_PATH} src={`${process.env.PUBLIC_URL}/img/icon_admin.svg`} alt={"관리자 아이콘"} content={"관리자 설정"}/>
            </MenuGroup> : ""
          }

          <MenuTitle 
            src={`${process.env.PUBLIC_URL}/img/icon_guide.png`}
            alt={"메뉴얼보기 아이콘"}
            to={GUIDE_PATH}
            content={"매뉴얼보기"} />
      </ul>
    </div>
  )
}

export default Sidebar