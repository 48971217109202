import React from 'react'
import SubTitle from '../../../atom/subTitle';
import Button from '../../../atom/button';
import { useQuery } from '@tanstack/react-query';
import { appDetailQuery, appUpdateQuery } from '../../../../queries/appQueries';
import FileUploader from '../../../atom/fileUploader';
import Input from '../../../atom/input';
import { makeParamsFromFormData, uploadFile } from '../../../../util/util';
import { Form, redirect, useActionData } from 'react-router-dom';
import { PUT_METHOD } from '../../../../const/const';
import { FILE_POST_DEFINE } from '../../../../const/defineName';
import { SETTING_APP_PATH, SETTING_PATH } from '../../../../const/path';

export const appUpdateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {};

        if(!params.appName) {
            errors.appName = "앱 이름은 필수 값입니다.";
            return errors;
        }
        
        if(formData.getAll("file")[0].name) {
            const fileId = await uploadFile(FILE_POST_DEFINE, formData);
            params.iconFile = fileId;
        }
        
        const response = await queryClient.fetchQuery(appUpdateQuery(params));

        if(response.result === "FAIL") {
            errors.error = response.message;
        } else {
            return redirect(`${SETTING_PATH}/${SETTING_APP_PATH}`)
        }

        return errors;
    }

const AppManageUpdate = () => {
    const { data: { data } } = useQuery(appDetailQuery());
    const errors = useActionData();

    return (
        <Form method={PUT_METHOD} encType='multipart/form-data'>
            <div className="main">
                <div className="contentBg">
                    <SubTitle title={"앱관리"}/>
                    <Input type={"hidden"} defaultValue={ data.appIcon } name={"iconFile"}/>
                    <div className='tableWrap5 '>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th className='required'>앱 이름</th>
                                    <td>
                                        <Input defaultValue={ data.appName } name={"appName"} error={errors?.appName} comment={"앱을 대표하는 이름입니다."}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>앱 아이콘</th>
                                    <td className='appicon'>
                                        <FileUploader
                                            fileId={data.appIconFile[0].fleGrpId}
                                            maxLength={1}/>
                                        <p className="info">앱을 대표하는 아이콘입니다. 정사각형의 .svg 파일을 권장드립니다.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="btnWrap d-flex justify-content-end align-items-center">
                        <Button content={"앱수정"}/>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default AppManageUpdate