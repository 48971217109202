import React, { forwardRef } from 'react'

const GroupMember = forwardRef(({memberId, memberName, idx, groupName, disabled=false, defaultChecked, name, onChange}, ref) => {
    return (
        <label htmlFor={groupName + memberId} className="chk_box">
            <input 
                type="checkbox" 
                id={groupName + memberId} 
                name={name} 
                value={memberId} 
                className="chkbox" 
                ref={el => ref.current[idx] = el}
                disabled={disabled}
                defaultChecked={disabled}
                onChange={onChange}
                />

            <span className="checkon"></span>
            <b> {memberName} <span>{memberId}</span></b>
        </label>
    );
})

export default GroupMember