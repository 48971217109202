export const DASHBOARD_DFINE = "DASHBOARD_DFINE";

export const LOGIN_DEFINE = "LOGIN_DEFINE";
export const LOGOUT_DEFINE = "LOGOUT_DEFINE";

export const MEMBER_LIST_DEFINE = "MEMBER_LIST_DEFINE";
export const MEMBER_READ_DEFINE = "MEMBER_READ_DEFINE";
export const MEMBER_UPDATE_DEFINE = "MEMBER_UPDATE_DEFINE";
export const MEMBER_CHANGE_GRADE_DEFINE = "MEMBER_CHANGE_GRADE_DEFINE";

export const BOARD_DEFINE = "BOARD_DEFINE";
export const BOARD_CREATE_DEFINE = "BOARD_CREATE_DEFINE";
export const BOARD_UPDATE_DEFINE = "BOARD_UPDATE_DEFINE";
export const BOARD_CONTENT_TYPE_DEFINE = "BOARD_CONTENT_TYPE_DEFINE";
export const BOARD_ICON_DEFINE = "BOARD_ICON_DEFINE";
export const BOARD_DETAIL_DEFINE = "BOARD_DETAIL_DEFINE";
export const BOARD_CHANGE_MENU_DEFINE = "BOARD_CHANGE_MENU_DEFINE";

export const USER_BOARD_LIST_DEFINE = "USER_BOARD_LIST_DEFINE";
export const USER_BOARD_READ_DEFINE = "USER_BOARD_READ_DEFINE";
export const USER_BOARD_BLIND_CONTENT_DEFINE = "USER_BOARD_BLIND_CONTENT_DEFINE";
export const USER_BOARD_UNBLIND_CONTENT_DEFINE = "USER_BOARD_UNBLIND_CONTENT_DEFINE";
export const USER_BOARD_BLIND_REPLY_DEFINE = "USER_BOARD_BLIND_REPLY_DEFINE";
export const USER_BOARD_UNBLIND_REPLY_DEFINE = "USER_BOARD_UNBLIND_REPLY_DEFINE";

export const GROUP_LIST_DEFINE = "GROUP_LIST_DEFINE";
export const GROUP_READ_DEFINE = "GROUP_READ_DEFINE";
export const GROUP_CREATE_DEFINE = "GROUP_CREATE_DEFINE";
export const GROUP_UPDATE_DEFINE = "GROUP_UPDATE_DEFINE";
export const ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE = "ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE";

export const NOTICE_LIST_DEFINE = "NOTICE_LIST_DEFINE";
export const NOTICE_CREATE_DEFINE = "NOTICE_CREATE_DEFINE";
export const NOTICE_READ_DEFINE = "NOTICE_READ_DEFINE";
export const NOTICE_UPDATE_DEFINE = "NOTICE_UPDATE_DEFINE";
export const NOTICE_DELETE_DEFINE = "NOTICE_DELETE_DEFINE";

export const INQUIRY_LIST_DEFINE = "INQUIRY_LIST_DEFINE";
export const INQUIRY_READ_DEFINE = "INQUIRY_READ_DEFINE";

export const REPLY_CREATE_DEFINE = "REPLY_CREATE_DEFINE";
export const REPLY_UPDATE_DEFINE = "REPLY_UPDATE_DEFINE";
export const REPLY_DELETE_DEFINE = "REPLY_DELETE_DEFINE";

export const COMPANY_LIST_DEFINE = "COMPANY_LIST_DEFINE";
export const COMPANY_READ_DEFINE = "COMPANY_READ_DEFINE";
export const COMPANY_CREATE_DEFINE = "COMPANY_CREATE_DEFINE";
export const COMPANY_UPDATE_DEFINE = "COMPANY_UPDATE_DEFINE";
export const COMPANY_DELETE_DEFINE = "COMPANY_DELETE_DEFINE";

export const ADMIN_LIST_DEFINE = "ADMIN_LIST_DEFINE";
export const ADMIN_READ_DEFINE = "ADMIN_READ_DEFINE";
export const ADMIN_CREATE_DEFINE = "ADMIN_CREATE_DEFINE";
export const ADMIN_UPDATE_DEFINE = "ADMIN_UPDATE_DEFINE";
export const ADMIN_SEARCH_DEFINE = "ADMIN_SEARCH_DEFINE";
export const ADMIN_DELETE_DEFINE = "ADMIN_DELETE_DEFINE";

export const MY_INFO_DEFINE = "MY_INFO_DEFINE";

export const APP_DETAIL_DEFINE = "APP_DETAIL_DEFINE";
export const APP_UPDATE_DEFINE = "APP_UPDATE_DEFINE";

export const CALENDAR_LIST_DEFINE = "CALENDAR_LIST_DEFINE";
export const CALENDAR_CREATE_DEFINE = "CALENDAR_CREATE_DEFINE";
export const CALENDAR_DELETE_DEFINE = "CALENDAR_DELETE_DEFINE";
export const CALENDAR_UPDATE_DEFINE = "CALENDAR_UPDATE_DEFINE";

export const FILE_POST_DEFINE = "FILE_POST_DEFINE";
export const FILE_UPDATE_DEFINE = "FILE_UPDATE_DEFINE";
export const EDITOR_FILE_UPLOAD_URL = "EDITOR_FILE_UPLOAD_URL";
export const FILE_DETAIL_DEFINE = "FILE_DETAIL_DEFINE";