import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter } from './routers/router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import "./css/reset.css";
import "./css/common.css";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {CookiesProvider} from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));

const qureyClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
    }
  }
});

root.render(
  <RecoilRoot>
      <QueryClientProvider client={qureyClient}>
        {/* <QueryErrorResetBoundary>
          {({reset}) => (
            <RouterProvider router={createRouter(qureyClient, reset)}/>
          )}
        </QueryErrorResetBoundary> */}
        <DndProvider backend={HTML5Backend}>
          <CookiesProvider>
            <RouterProvider router={createRouter(qureyClient)}/>
          </CookiesProvider>
        </DndProvider>
        <ReactQueryDevtools initialIsOpen={true} position='right'/>
      </QueryClientProvider>
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
