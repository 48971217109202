/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useRouteError, useSubmit, useNavigate } from 'react-router-dom'
import { LOGOUT_PATH } from '../../../const/path';
import { POST_METHOD } from '../../../const/const';

const Page401 = () => {
    const navigator = useNavigate();
    const error = useRouteError();
    const submit = useSubmit();

    useEffect(() => {
        if(error.status === 401 || error.status === 403) {
            submit({}, {method: POST_METHOD, action: LOGOUT_PATH});
        }
        console.log(error)
    }, [error])

    return (
        <div className='errorWrap'>
            <div className='errorTit'>
                {
                    error.status ? 
                    <>
                        <h2>{error.status}</h2>
                        <p>{error.data}</p>
                    </> : ""
                }
                <button onClick={() => {navigator(-1)}}>뒤로가기</button>
            </div>
        </div>
    )
}

export default Page401