/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from '../../../atom/button'
import Input from '../../../atom/input';
import Editor from '../../../atom/editor';
import { companyListQuery, companyUpdateQuery } from '../../../../queries/companyQueries';
import { useQuery } from '@tanstack/react-query'
import FileUploader from '../../../atom/fileUploader';
import AddressSearchPopup from '../../../components/popup/addressSearchPopup';
import { makeParamsFromFormData, uploadFile } from '../../../../util/util';
import { Form, redirect, useActionData } from 'react-router-dom';
import { FILE_POST_DEFINE } from '../../../../const/defineName';
import { SETTING_COMPANY_PATH, SETTING_PATH } from '../../../../const/path';
import { POST_METHOD } from '../../../../const/const';

export const companyUpdateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {};

        const companyAddressRequestDto = {
            fullLocation: params.fullLocation,
            depthOne: params.depthOne,
            depthTwo: params.depthTwo,
            depthThree: params.depthThree,
            streetName: params.streetName,
            postNum: params.postNum,
            addressDetail: params.addressDetail,
            addressAdditionalDescription: params.addressAdditionalDescription
        };

        params.companyAddressRequestDto = companyAddressRequestDto;

        if(!params.companyName) {
            errors.companyName = "회사 이름은 필수 값입니다.";
            return errors;
        } else if(!params.companyOwnerName) {
            errors.companyOwnerName = "대표자명은 필수 값입니다.";
            return errors;
        } else if(!params.companyEmail) {
            errors.companyEmail = "대표 이메일은 필수 값입니다.";
            return errors;
        } else if(!params.companyPhone) {
            errors.companyPhone = "대표번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyBusinessNumber) {
            errors.companyBusinessNumber = "사업자등록번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyMailOrderNumber) {
            errors.companyMailOrderNumber = "통신판매번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyIntro) {
            errors.companyIntro = "소개는 필수 값입니다.";
            return errors;
        } else if(!params.companyHistory) {
            errors.companyHistory = "연혁은 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.postNum) {
            errors.postNum = "우편번호는 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.addressDetail) {
            errors.addressDetail = "상세주소는 필수 값입니다.";
            return errors;
        } else if(!params.companyAddressRequestDto.addressAdditionalDescription) {
            errors.addressAdditionalDescription = "오시는길은 필수 값입니다.";
            return errors;
        }
        
        if(formData.get("file").name) {
            const fileId = await uploadFile(FILE_POST_DEFINE, formData);
            params.companyBI = fileId;
        }

        const response = await queryClient.fetchQuery(companyUpdateQuery(params));
        
        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_COMPANY_PATH}`);
        } else {
            errors.error = response.message;
        }

        return errors;
    }

const CompanyUpdate = () => {
    const { data: { data } } = useQuery(companyListQuery());
    const [addressData, setAddressData] = useState({});
    const [isAddressPopupOpen, setIsAddressPopupOpen] = useState(false);
    const errors = useActionData();

    const openAddressPopup = (e) => {
        e.preventDefault();
        setIsAddressPopupOpen(true);
    }

    const onCompleteAddress = (data) => {
        const addData = {
            fullLocation: data.address,
            depthOne: data.sido,
            depthTwo: data.sigungu,
            depthThree: data.bname,
            streetName: data.roadAddress,
            postNum: data.zonecode,
        }
        setAddressData(addData);
        setIsAddressPopupOpen(false);
    }

    useEffect(() => {
        const cObj = {...data.companyAddressResponseDto};
        delete cObj.addressAdditionalDescription;
        delete cObj.addressDetail;
        setAddressData(cObj);
    }, [])

    return (
        <Form method={POST_METHOD} encType='multipart/form-data'>
            <div className="main">
                <div className="contentBg">
                <h2 className="subTit">대표 정보 수정</h2>
                {
                    isAddressPopupOpen ?
                    <AddressSearchPopup onComplete={onCompleteAddress}/> : ""
                }
                {
                    Object.keys(addressData).map((key) => (
                        <input type='hidden' name={key} defaultValue={addressData[key]} key={key}/>
                    ))
                }
                <input type='hidden' name='companyBI' value={data.companyBI[0].fleGrpId}/>
                <div className='formWrap'>
                    <Input 
                        label={"회사(단체)명"}
                        placeholder={"회사(단체)명을 입력해주세요"}
                        comment={"회사(단체)의 이름을 입력해주세요"}
                        defaultValue={data?.companyName}
                        name={"companyName"}
                        error={errors?.companyName}
                        required/>
                    <Input 
                        label={"대표자명"}
                        placeholder={"대표자명을 입력해주세요"}
                        comment={"회사(단체)의 대표자 이름을 입력해주세요"}
                        defaultValue={data?.companyOwnerName}
                        name={"companyOwnerName"}
                        error={errors?.companyOwnerName}
                        required/>
                    <Input 
                        label={"대표 이메일"}
                        placeholder={"대표 이메일을 입력해주세요"}
                        comment={"회사(단체)를 대표하는 메일 계정입니다."}
                        defaultValue={data?.companyEmail}
                        name={"companyEmail"}
                        error={errors?.companyEmail}
                        required/>
                    <Input 
                        label={"대표 전화번호"}
                        placeholder={"회사 대표 전화번호을 입력해주세요"}
                        comment={"회사(단체)의 회사 대표 전화번호입니다."}
                        defaultValue={data?.companyPhone}
                        name={"companyPhone"}
                        error={errors?.companyPhone}
                        required/>
                    <Input 
                        label={"사업자등록번호"}
                        placeholder={"사업자등록번호를 입력해주세요"}
                        comment={"회사(단체)의 사업자등록번호입니다."}
                        defaultValue={data?.companyBusinessNumber}
                        name={"companyBusinessNumber"}
                        error={errors?.companyBusinessNumber}
                        required/>
                    <Input 
                        label={"통신판매번호"}
                        placeholder={"통신판매번호을 입력해주세요"}
                        comment={"회사(단체)의 통신판매번호입니다.."}
                        defaultValue={data?.companyMailOrderNumber}
                        name={"companyMailOrderNumber"}
                        error={errors?.companyMailOrderNumber}
                        required/>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label required">주소</label>
                        <div>
                            <div className="address">
                                <div className="d-flex">
                                    <Input
                                        type="text" 
                                        className="form-control" 
                                        id="company_address" 
                                        defaultValue={data.companyAddressResponseDto.postNum} 
                                        placeholder="우편번호" 
                                        error={errors?.postNum}
                                        readOnly/>
                                    {/* <input 
                                        type="text" 
                                        className="form-control" 
                                        id="company_address" 
                                        defaultValue={data.companyAddressResponseDto.postNum} 
                                        placeholder="우편번호" 
                                        readOnly/>  */}
                                    <button onClick={openAddressPopup}>우편번호 찾기</button>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="company_address" 
                                    defaultValue={data.companyAddressResponseDto.fullLocation} 
                                    placeholder="기본 주소를 입력해주세요"
                                    readOnly/>
                                <Input 
                                    type="text" 
                                    className="form-control" 
                                    id="company_address" 
                                    placeholder="상세 주소를 입력해주세요"
                                    name='addressDetail'
                                    defaultValue={data.companyAddressResponseDto.addressDetail}
                                    error={errors?.addressDetail}/>
                                <p className="warning"></p>
                            </div>
                            <p className="info">회사(단체)의 회사 주소입니다.</p>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="formFileMultiple" className="form-label">대표 로고</label>
                        <FileUploader
                            fileId={data.companyBI[0].fleGrpId}
                            maxLength={1}/>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label required">소개</label>
                        <div>
                            <Editor 
                                name={"companyIntro"} 
                                defaultValue={data?.companyIntro}
                                error={errors?.companyIntro}/>
                            <p className="info">회사(단체)의 소개 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label required">연혁</label>
                        <div>
                            <Editor 
                                name={"companyHistory"} 
                                defaultValue={data?.companyHistory}
                                error={errors?.companyHistory}/>
                            <p className="info">회사(단체)의 연혁입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label required">오시는길</label>
                        <div>
                            <Editor 
                                name={"addressAdditionalDescription"} 
                                defaultValue={data?.companyAddressResponseDto.addressAdditionalDescription}
                                error={errors?.addressAdditionalDescription}
                                />
                            <p className="info">회사(단체)의 위치 안내 내용입니다. 앱의 회사 소개에 노출됩니다.</p>
                        </div>
                    </div>
                </div>

                <div className="btnWrap d-flex justify-content-end align-items-center">
                    <Button content={"대표정보 수정"}/>
                </div>
                </div>
            </div>
        </Form>
    );
}

export default CompanyUpdate