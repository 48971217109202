import { MEMBER_CHANGE_GRADE_DEFINE, MEMBER_LIST_DEFINE, MEMBER_READ_DEFINE, MEMBER_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const memberListQuery = (params) => ({
    queryKey: ["memberList", params.page, params.keyword ?? "", params.keywordType ?? ""],
    queryFn: async() => {
        const json = await fetchDataPathVar(MEMBER_LIST_DEFINE, {}, params);
        return json;
    }
})

export const memberReadQuery = (params, queryParams) => ({
    queryKey: ["memberRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(MEMBER_READ_DEFINE, params, queryParams);
        return json;
    }
})

export const memberUpdateQuery = (params) => ({
    queryKey: ["memberUpdate"],
    queryFn: async() => {
        const json = await fetchData(MEMBER_UPDATE_DEFINE, params);
        return json;
    }
})

export const memberChangeGradeQuery = (params) => ({
    queryKey: ["memberChangeGrade"],
    queryFn: async() => {
        const json = await fetchData(MEMBER_CHANGE_GRADE_DEFINE, params);
        return json;
    }
})