import { CALENDAR_CREATE_DEFINE, CALENDAR_DELETE_DEFINE, CALENDAR_LIST_DEFINE, CALENDAR_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const calendarCreateQuery = (params) => ({
    queryKey: ["calendarCreate"],
    queryFn: async() => {
        const json = await fetchData(CALENDAR_CREATE_DEFINE, params);
        return json;
    }
})

export const calendarListQuery = () => ({
    queryKey: ["calendarList"],
    queryFn: async() => {
        const json = await fetchDataPathVar(CALENDAR_LIST_DEFINE);
        return json;
    }
})

export const calendarDeleteQuery = (params) => ({
    queryKey: ["calendarDelete"],
    queryFn: async() => {
        const json = await fetchData(CALENDAR_DELETE_DEFINE, params);
        return json;
    }
})

export const calendarUpdateQuery = (params) => ({
    queryKey: ["calendarUpdate"],
    queryFn: async() => {
        const json = await fetchData(CALENDAR_UPDATE_DEFINE, params);
        return json;
    }
})