import { BOARD_CHANGE_MENU_DEFINE, BOARD_CONTENT_TYPE_DEFINE, BOARD_CREATE_DEFINE, BOARD_DEFINE, BOARD_DETAIL_DEFINE, BOARD_ICON_DEFINE, BOARD_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const boardListQuery = () => ({
    queryKey: ["boardList"],
    queryFn: async() => {
        const json = await fetchDataPathVar(BOARD_DEFINE, {});
        return json;
    }
})

export const boardCreateQuery = (params) => ({
    queryKey: ["boardCreate"],
    queryFn: async() => {
        const json = await fetchData(BOARD_CREATE_DEFINE, params);
        return json;
    }
})

export const boardUpdateQuery = (params) => ({
    queryKey: ["boardUpdate"],
    queryFn: async() => {
        const json = await fetchData(BOARD_UPDATE_DEFINE, params);
        return json;
    }
})

export const boardContentTypeQuery = () => ({
    queryKey: ["boardContentType"],
    queryFn: async() => {
        const json = await fetchData(BOARD_CONTENT_TYPE_DEFINE);
        return json;
    }
})

export const boardIconQuery = () => ({
    queryKey: ["boardIcon"],
    queryFn: async() => {
        const json = await fetchData(BOARD_ICON_DEFINE);
        return json;
    }
})

export const boardReadQuery = (params) => ({
    queryKey: ["boardRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(BOARD_DETAIL_DEFINE, params);
        return json;
    }
})

export const boardMenuChangeQuery = (params) => ({
    queryKey: ["boardMenuChange"],
    queryFn: async() => {
        const json = await fetchData(BOARD_CHANGE_MENU_DEFINE, params);
        return json;
    }
})