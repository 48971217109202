import React, { useEffect } from 'react'
import { inquiryReadQuery } from '../../../../queries/inquiryQueries';
import { useQuery } from '@tanstack/react-query'
import { useLoaderData, Outlet } from 'react-router-dom';

export const inquiryReadLoader =
  (queryClient) => async({params}) => {
    // if(!queryClient.getQueryData(inquiryReadQuery(params).queryKey)) {
      await queryClient.fetchQuery(inquiryReadQuery(params));
    // }
    return { params };
  }

const InquiryRead = () => {
  const { params: loaderParams } = useLoaderData();
  const { data: { data: inquiry} } = useQuery(inquiryReadQuery(loaderParams));

  return (
    <div className="main askWrap">
      <h2 className="subTit">1:1 문의 상세</h2>

      <div className="contentBg">
        <h3>문의 내용</h3>
        <div className="tableWrap4">
          <table className="table">
            <tbody>
                <tr>
                    <th>제목</th>
                    <td>{inquiry.title}</td>
                </tr>
                <tr>
                    <th>답변상태</th>
                    <td>
                      <p className={inquiry.answer ? "status_green" : "status_gray"}>
                        {inquiry.answer ? "답변" : "미답변"}
                      </p>
                    </td>
                </tr>
                <tr>
                    <th>문의자</th>
                    <td>{inquiry.name}</td>
                </tr>
                <tr>
                    <th>문의일</th>
                    <td>{inquiry.createAt}</td>
                </tr>
                <tr>
                    <th>내용</th>
                    <td>
                      <div className='contentTxt'>
                        {inquiry.content}
                      </div>
                    </td>
                </tr>
                <tr className="line"></tr>
                <tr>
                    <th>첨부파일</th>
                    <td>
                      {
                        inquiry.fileUrl.length !== 0 ? 
                          inquiry.fileUrl.map(url => (
                            <div className='fileImg' key={url}>
                              <img src={url} alt={url} />
                              <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                            </div>
                          )) : "-"
                      }
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div> 
      <Outlet context={{reply: inquiry.reply, inquiryCode: inquiry.qnaCode}}/>
    </div>
  )
}

export default InquiryRead