import React, { useEffect, useRef } from 'react'
import { Form, useActionData, redirect } from 'react-router-dom'
import { POST_METHOD } from '../../const/const'
import { fetchData } from '../../util/util';
import { LOGIN_DEFINE } from '../../const/defineName';

export const loginAction = async ({request}) => {
    const formData = await request.formData();
    const email = formData.get("appManagerEmail");
    const password = formData.get("appManagerPassword");
    const rememberId = formData.get("rememberId");
    const project = formData.get("projectName");

    const errors = {};

    if(!project) {
        errors.project = "프로젝트명을 확인해주세요.";
        return errors;
    }
    if(typeof email !== "string" || !email.includes("@") ) {
        errors.email = "이메일 형식을 확인해주세요.";
        return errors;
    }
    if(!password) {
        errors.password = "비밀번호 형식을 확인해주세요.";
        return errors;
    }

    const params = Object.fromEntries(formData);

    const response = await fetchData(LOGIN_DEFINE, params);

    if(response.result === "SUCCESS") {
        localStorage.setItem("login_project_name", project);
        if(rememberId) {
            localStorage.setItem("remember_id_flag", true);
            localStorage.setItem("remember_id", email);
        }
        
        return redirect("/");
    } else {
        return {message : response.message};
    }
}

const Login = () => {
    const errors = useActionData();

    const idRef = useRef();
    const pwRef = useRef();
    const pjtRef = useRef();

    useEffect(() => {
        if(errors?.email) {
            idRef.current.focus();
        }
        if(errors?.password) {
            pwRef.current.focus();
        }
    }, [errors])

    return (
        <div className="loginBg">
            <div className="login-wrapper">
                <h2>AiApp 관리자 로그인</h2>
                <p>AiApp 관리자 전용 페이지 입니다. <br/>계정이 없을 시 담당자에게 문의주세요.</p>
                <p className="warning">ID/PW가 유출되지 않도록 각별히 주의해주시기 바라며, mBaaS가 인가하지 않은 유출이나 로그인이 발생한 경우 법적인 책임이 발생할 수 있습니다.</p>
                <Form method={POST_METHOD} id="login-form">
                    <input type="text" ref={pjtRef} name="projectName" placeholder="프로젝트 명" defaultValue={localStorage.getItem("login_project_name")}/>
                    <p>{errors?.project ? errors.project : ""}</p>

                    <input type="text" ref={idRef} name="appManagerEmail" placeholder="Email" defaultValue={localStorage.getItem("remember_id")}/>
                    <p>{errors?.email ? errors.email : ""}</p>

                    <input type="password" ref={pwRef} name="appManagerPassword" placeholder="Password" />
                    <p>{errors?.password ? errors.password : ""}</p>

                    <p>{errors?.message ? errors.message : ""}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="remember-check">
                        <input type="checkbox" id="remember-check" name='rememberId' defaultChecked={localStorage.getItem("remember_id_flag")}/> 아이디 저장하기
                        </label>
                        <button className="passwordbtn">비밀번호재설정</button>
                    </div>
                    <input type="submit" value="로그인" className="loginBtn"/>
                </Form>
            </div>
            <p className="copyright">Copyright © 2024 ㈜mBaaS. All Rights Reserved</p>
        </div>
    )
}

export default Login