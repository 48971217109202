import React from 'react'
import Input from '../../../atom/input'
import Editor from '../../../atom/editor'
import FileUploader from '../../../atom/fileUploader'
import Button from '../../../atom/button'
import { noticeReadQuery, noticeUpdateQuery } from '../../../../queries/noticeQueries'
import { useLoaderData, Form, redirect } from 'react-router-dom'
import { useQuery } from "@tanstack/react-query"
import { PUT_METHOD } from '../../../../const/const'
import { makeParamsFromFormData, uploadFile } from '../../../../util/util'
import { FILE_POST_DEFINE, FILE_UPDATE_DEFINE } from '../../../../const/defineName'
import { CONTENT_NOTICE_LIST_PATH, CONTENT_PATH } from '../../../../const/path'

export const noticeUpdateLoader =
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(noticeReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(noticeReadQuery(params));
        }

        return { params };
    }

export const noticeUpdateAction =
    (queryClient) => async({request}) => {
        const formData = await request.formData();

        const params = makeParamsFromFormData(formData);
        const errors = {}

        if(!formData.get("fileId")) {
            const fileId = await uploadFile(FILE_POST_DEFINE, formData);
            params.fileId = fileId;
        } else {
            await uploadFile(FILE_UPDATE_DEFINE, formData);
        }
        const response = await queryClient.fetchQuery(noticeUpdateQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${CONTENT_PATH}/${CONTENT_NOTICE_LIST_PATH}/${response.data}`);
        } else {
            errors.error = response.message;
        }

        return errors;
    }

const NoticeUpdate = () => {
    const { params } = useLoaderData();
    const { data: { data: notice } } = useQuery(noticeReadQuery(params));

    return (
        <div className="main">
            <Form method={PUT_METHOD} encType='multipart/form-data'>
                <div className="contentBg">
                    <h2 className="subTit">공지사항 수정</h2>
                    <input type='hidden' name='noticeCode' value={notice.noticeCode}/>
                    <div className="formWrap">
                        <Input 
                            label={"공지사항 제목"}
                            name={"noticeTitle"}
                            placeholder={"제목을 작성해주세요"}
                            comment={"게시글의 제목입니다."}
                            defaultValue={notice.title}
                            required/>

                        <Input 
                            label={"공지사항 게시 날짜"}
                            type={"date"}
                            name={"noticeStartDate"}
                            comment={"게시글이 앱에 게시되는 날짜입니다. 미리 작성 후 예약 날짜를 설정하실 수 있습니다."}
                            defaultValue={notice.startDate}
                            required/>

                        <div className="mb-4 d-grid">
                            <label htmlFor="formFileMultiple" className="form-label required">공지사항 내용</label>
                            <div>
                                <Editor 
                                    name={"noticeContent"}
                                    defaultValue={notice.content}/>
                            </div>
                        </div>
                        
                        <div>
                            <label htmlFor="formFileMultiple" className="form-label">첨부파일</label>
                            <FileUploader fileId={notice.fileId}/>
                        </div>
                    </div>

                    <div className='btnWrap justify-content-end'>  
                        <div className='off'>
                            <Button content={"취소"}/>
                        </div>
                        <Button content={"공지사항 수정"}/>
                    </div>

                </div>
            </Form>
        </div>
    )
}

export default NoticeUpdate