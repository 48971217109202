import React from 'react'
import Button from '../../../atom/button'
import Input from '../../../atom/input';
import { Form, redirect, useActionData } from 'react-router-dom';
import GroupSelect from '../../../components/groupSelect';
import { makeParamsFromFormData } from '../../../../util/util';
import { POST_METHOD } from '../../../../const/const';
import { useQueries } from '@tanstack/react-query';
import { SETTING_BOARD_PATH, SETTING_PATH } from '../../../../const/path';
import IconUploader from '../../../components/IconUploader';
import FunctionSelector from '../../../components/FunctionSelector';
import { boardContentTypeQuery, boardCreateQuery, boardIconQuery } from '../../../../queries/boardQueries';
import { allGroupListForCreateOrUpdateQuery } from '../../../../queries/groupQueries';

export const boardCreateLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(boardContentTypeQuery().queryKey)) {
            (await queryClient.fetchQuery(boardContentTypeQuery()));
        }
        if(!queryClient.getQueryData(boardIconQuery().queryKey)) {
           (await queryClient.fetchQuery(boardIconQuery()));
        }
        if(!queryClient.getQueryData(allGroupListForCreateOrUpdateQuery().queryKey)) {
            (await queryClient.fetchQuery(allGroupListForCreateOrUpdateQuery()));
         }

        return { params };
    }

export const boardCreateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData, ["capabilityStatus", "groupList"]);
        const error = {};

        if(!params.name) {
            error.name = "그룹 이름은 필수 값입니다.";
            return error;
        }
        else if(!params.groupList) {
            error.groupList = "게시판을 조회 할 수 있는 그룹은 필수 값입니다.";
            return error;
        }
        
        const response = await queryClient.fetchQuery(boardCreateQuery(params));
        
        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_BOARD_PATH}/${response.data.code}`);
        }

        return null;
}

const BoardCreate = () => {
    const errors = useActionData();
    const [boardContentTypeList, boardIconList, groupList] = useQueries({
        queries: [boardContentTypeQuery(), boardIconQuery(), allGroupListForCreateOrUpdateQuery()],
    })

    return (
        <div className="main boardWrap">
            <div className="contentBg">
                <h2 className="subTit">게시판 생성</h2>

                <Form method={POST_METHOD} encType="multipart/form-data" replace>
                    <div className="formWrap">
                        <Input
                            label={"게시판 이름"}
                            placeholder={"게시판 이름을 작성해주세요."}
                            name={"name"}
                            comment={"게시판을 대표하는 이름입니다."}
                            error={errors?.name}
                            required
                        />
                        <input type='hidden' name='sequence' value={0}/>
                        
                        <IconUploader data={boardIconList.data}/>
                        {/* <div className="mb-4 d-grid">
                            <label htmlFor="boardFuntion" className='form-label'>게시판 형태</label>
                            <div>
                                <div className='d-flex'>
                                    {
                                        boardContentTypeList.data.data.contentTypeInfo.map((type, idx) => (
                                            <div className="form-check form-check-inline" key={type.code}>
                                                <input 
                                                    className="form-check-input"
                                                    type="radio" 
                                                    name="typeCode"
                                                    value={type.code}
                                                    id={type.code + idx}
                                                    defaultChecked={idx === 0}
                                                    />
                                                <label className="form-check-label" htmlFor={type.code + idx}>{type.name}</label>
                                                <div className="boardinfo">{type.description}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <p className="info">게시판에 필요한 기능을 선택해 주세요</p>
                            </div>
                        </div> */}

                        <FunctionSelector data={boardContentTypeList.data.data.capabilityInfo}/>
                        <div className="mb-4 d-grid">
                            <label htmlFor="boardGroup" className="form-label">게시판 접속 그룹</label>
                            <div>
                                <GroupSelect
                                    data={groupList.data.data} 
                                    groupInputName={"groupList"} 
                                    showMember={false}/>
                                <p className="info">해당 게시판을 볼 수 있는 권한을 설정하실 수 있습니다. 선택하지 않은 그룹은 해당 게시판을 읽을 수 없습니다.</p>
                            </div>
                        </div>

                        {/* <div className="mb-4  d-grid">
                            <label htmlFor="board" className="form-label">댓글 사용여부</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="replyStatus" id="use"
                                    value="ENABLE" defaultChecked/>
                                    <label className="form-check-label" htmlFor="use">사용</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="replyStatus" id="notuse"
                                    value="UNABLE"/>
                                    <label className="form-check-label" htmlFor="notuse">미사용</label>
                                </div>
                                <p className="info">미사용시 해당 게시판에서 댓글을 작성할 수 없습니다. </p>
                            </div>
                        </div> */}

                        <div className="mb-4  d-grid">
                            <label htmlFor="board" className="form-label">게시판 사용여부</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="boardUse"
                                    value="ENABLE" defaultChecked/>
                                    <label className="form-check-label" htmlFor="boardUse">사용</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="status" id="boardNotUse"
                                    value="UNABLE"/>
                                    <label className="form-check-label" htmlFor="boardNotUse">미사용</label>
                                </div>
                                <p className="info">미사용시 앱에서 해당 게시판을 볼 수 없습니다. </p>
                            </div>
                        </div>
                    </div>
                        <div className='btnWrap justify-content-end'>  
                            <div className='off'>
                                <Button content={"취소"}/>
                            </div>
                            <Button content={"게시판 생성"}/>
                        </div>
                </Form>
            </div>
        </div>
    )
}

export default BoardCreate