import { createBrowserRouter } from "react-router-dom"
import Layout, { layoutLoader } from "../views/templates/layout"
import AppManage, { appManageLoader } from "../views/pages/settings/app/appManage";
import { 
    CONTENT_CALENDAR_PATH,
    CONTENT_INQUIRY_LIST_PATH
    , CONTENT_MEMBER_LIST_PATH
    , CONTENT_NOTICE_LIST_PATH
    , CONTENT_PATH, CREATE_PATH
    , GUIDE_PATH, LOGIN_PATH, LOGOUT_PATH, SETTING_APP_PATH
    , SETTING_BOARD_PATH, SETTING_GROUP_PATH, SETTING_ENV_PATH
    , SETTING_PATH, SETTING_USER_BOARD_PATH, UPDATE_PATH, MYPAGE_PATH,
    CHANGE_PATH, SETTING_ADMIN_PATH,
    SETTING_COMPANY_PATH,
} from "../const/path";
import Page404 from "../views/pages/error/page404";
import Dashboard, { dashBoardLoader } from "../views/pages/dashboard";
import Notice, { noticeDeleteAction, noticeListLoader } from "../views/pages/contents/notice/notice";
import NoticeCreate, { noticeAction } from "../views/pages/contents/notice/noticeCreate";
import NoticeRead, { noticeReadLoader } from "../views/pages/contents/notice/noticeRead";
import NoticeUpdate, { noticeUpdateAction, noticeUpdateLoader } from "../views/pages/contents/notice/noticeUpdate";
import Inquiry, { inquiryListLoader } from "../views/pages/contents/inquiry/inquiry";
import InquiryRead, { inquiryReadLoader } from "../views/pages/contents/inquiry/inquiryRead";
import Member, { memberChangeGradeAction, memberListLoader } from "../views/pages/contents/member/member";
import MemberRead, { memberReadLoader } from "../views/pages/contents/member/memberRead";
import MemberUpdate, { memberUpdateAction } from "../views/pages/contents/member/memberUpdate";
import Calendar, { calendarAction, calendarLoader } from "../views/pages/contents/calendar/calendar";
import UserBoard, { userBoardLoader } from "../views/pages/settings/board/userBoard/userBoard";
import UserBoardRead, { userBoardReadLoader, userBoardUpdateAction } from "../views/pages/settings/board/userBoard/userBoardRead";
import Board, { boardLoader } from "../views/pages/settings/board/board";
import BoardRead, { boardReadLoader } from "../views/pages/settings/board/boardRead";
import BoardCreate, { boardCreateAction, boardCreateLoader } from "../views/pages/settings/board/boardCreate";
import BoardUpdate, { boardUpdateAction, boardUpdateLoader } from "../views/pages/settings/board/boardUpdate";
import Group, { groupListLoader } from "../views/pages/settings/group/group";
import GroupRead, { groupReadLoader } from "../views/pages/settings/group/groupRead";
import Guide from "../views/pages/guide";
import Login, { loginAction } from "../views/pages/login";
import Page500 from "../views/pages/error/page500";
import Logout, { logoutAction, logoutLoader } from "./actions/logout";
import DndTest from "../views/pages/playground/dndTest";
import GroupCreate, { groupCreateMemberListAction, groupCreateMemberListLoader } from "../views/pages/settings/group/groupCreate";
import EnvSetting from "../views/pages/settings/envsettings/envsettings";
import Mypage, { myPageLoader } from "../views/pages/mypage"
import GroupUpdate, { groupUpdateAction, groupUpdateLoader } from "../views/pages/settings/group/groupUpdate";
import BoardChangeMenu, { boardChangeMenuAction } from "../views/pages/settings/board/boardChangeMenu";
import ReplyRead, { replyDeleteAction } from "../views/pages/contents/inquiry/reply/replyRead";
import ReplyUpdate, { replyUpdateAction } from "../views/pages/contents/inquiry/reply/replyUpdate";
import ReplyCreate, { replyCreateAction } from "../views/pages/contents/inquiry/reply/replyCreate";
import ReplyContainer from "../views/pages/contents/inquiry/reply/replyContainer";
import AdminSettingRead, { adminReadLoader } from "../views/pages/settings/admin/adminSettingRead";
import AdminSettingCreate, { adminCreateAction, adminSearchLoader } from "../views/pages/settings/admin/adminSettingCreate";
import AdminSetting, { adminDeleteAction, adminListLoader } from "../views/pages/settings/admin/adminSetting";
import CompanyRead, { companyDeleteAction, companyDetailLoader } from "../views/pages/contents/company/companyRead";
import Page401 from "../views/pages/error/page401";
import CompanyCreate, { companyCreateAction } from "../views/pages/contents/company/companyCreate";
import Company, { companyReadLoader } from "../views/pages/contents/company/company";
import AppManageUpdate, { appUpdateAction } from "../views/pages/settings/app/appManageUpdate";
import CompanyUpdate, { companyUpdateAction } from "../views/pages/contents/company/companyUpdate";
import { settingLoader } from "./loaders/loaders";

export const createRouter = (queryClient) => {
    return createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            loader: layoutLoader(queryClient),
            errorElement: <Page500/>,
            children: [
                {
                    index: true,
                    element: <Dashboard/>,
                    loader: dashBoardLoader(queryClient),
                },
                {
                    path : MYPAGE_PATH,
                    element : <Mypage/>,
                    loader: myPageLoader(queryClient),
                },
                {
                    path: "dndTest",
                    element: <DndTest/>,
                    loader: boardLoader(queryClient),
                },
                ...settingRouter(queryClient),
                ...contentRouter(queryClient),
            ],
        },
        {
            path: LOGIN_PATH,
            element: <Login/>,
            action: loginAction,
            errorElement: <Page500/>,
        },
        {
            path: LOGOUT_PATH,
            loader: logoutLoader,
            action: logoutAction,
            element: <Logout/>
        },
        {
            path: "*",
            element: <Page404/>
        },
        {
            path: GUIDE_PATH,
            element: <Guide/>
        },
    ])
}

const settingRouter = (queryClient) => {
    return [
        {
            path: SETTING_PATH,
            loader: settingLoader(queryClient),
            errorElement: <Page401/>,
            children: [
                {
                    path: SETTING_APP_PATH,
                    children: [
                        {
                            index: true,
                            element: <AppManage/>,
                            loader: appManageLoader(queryClient),
                        }, 
                        {
                            path: UPDATE_PATH,
                            element: <AppManageUpdate/>,
                            loader: appManageLoader(queryClient),
                            action: appUpdateAction(queryClient)
                        }, 
                    ]
                },
                {
                    path: SETTING_COMPANY_PATH,
                    children: [
                        {
                            index: true,
                            element: <Company/>,
                            loader: companyReadLoader(queryClient),
                        },
                        {
                            path: CREATE_PATH,
                            element: <CompanyCreate/>,
                            action: companyCreateAction(queryClient),
                        },
                        {
                            path: "detail",
                            children: [
                                {
                                    index: true,
                                    element: <CompanyRead/>,
                                    loader: companyDetailLoader(queryClient),
                                    action: companyDeleteAction(queryClient),
                                },
                                {
                                    path: UPDATE_PATH,
                                    element: <CompanyUpdate/>,
                                    loader: companyDetailLoader(queryClient),
                                    action: companyUpdateAction(queryClient),
                                }
                            ]
                        },
                    ]
                },
                {
                    path: SETTING_BOARD_PATH,
                    children: [
                        {
                            index: true,
                            element: <Board/>,
                            loader: boardLoader(queryClient),
                        },
                        {
                            path: ":id",
                            children: [
                                {
                                    index: true,
                                    element: <BoardRead/>,
                                    loader: boardReadLoader(queryClient),
                                },
                                {
                                    path: UPDATE_PATH,
                                    element: <BoardUpdate/>,
                                    loader: boardUpdateLoader(queryClient),
                                    action: boardUpdateAction(queryClient),
                                },
                            ]
                        },
                        {
                            path: CREATE_PATH,
                            element: <BoardCreate/>,
                            loader: boardCreateLoader(queryClient),
                            action: boardCreateAction(queryClient)
                        },
                        {
                            path: CHANGE_PATH,
                            element: <BoardChangeMenu/>,
                            loader: boardLoader(queryClient),
                            action: boardChangeMenuAction(queryClient),
                        },
                        {
                            path: SETTING_USER_BOARD_PATH,
                            children: [
                                {
                                    path: ":userBoardId",
                                    children: [
                                        {
                                            index: true,
                                            element: <UserBoard/>,
                                            loader: userBoardLoader(queryClient),
                                        },
                                        {
                                            path: ":contentId",
                                            element: <UserBoardRead/>,
                                            loader: userBoardReadLoader(queryClient),
                                            action: userBoardUpdateAction(queryClient),
                                        }
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    path: SETTING_GROUP_PATH,
                    children: [
                        {
                            index: true,
                            element: <Group/>,
                            loader: groupListLoader(queryClient),
                        },
                        {
                            path: ":id",
                            children: [
                                {
                                    index: true,
                                    element: <GroupRead/>,
                                    loader: groupReadLoader(queryClient),
                                },
                                {
                                    path: UPDATE_PATH,
                                    element: <GroupUpdate/>,
                                    loader: groupUpdateLoader(queryClient),
                                    action: groupUpdateAction(queryClient),
                                }
                            ]
                        },
                        {
                            path: CREATE_PATH,
                            element: <GroupCreate/>,
                            loader: groupCreateMemberListLoader(queryClient),
                            action: groupCreateMemberListAction(queryClient),
                        },
                    ]
                },
                {
                    path: SETTING_ENV_PATH,
                    children: [
                        {
                            index:true,
                            element: <EnvSetting/>
                        }
                    ]
                },
                {
                    path: SETTING_ADMIN_PATH,
                    children: [
                        {
                            index: true,
                            element : <AdminSetting/>,
                            loader: adminListLoader(queryClient),
                            action: adminDeleteAction(queryClient),
                        }, 
                        {
                            path: ":id",
                            element : <AdminSettingRead/>,
                            loader: adminReadLoader(queryClient),
                        }, 
                        {
                            path: CREATE_PATH,
                            element : <AdminSettingCreate/>,
                            loader: adminSearchLoader(queryClient),
                            action: adminCreateAction(queryClient),
                        }, 
                    ]
                }
            ]
        }
    ]
};

const contentRouter = (queryClient) => {
    return [
        {
            path: CONTENT_PATH,
            errorElement: <Page401/>,
            children: [
                {
                    path: CONTENT_NOTICE_LIST_PATH,
                    children: [
                        {
                            index: true,
                            element: <Notice/>,
                            loader: noticeListLoader(queryClient),
                        },
                        {
                            path: "delete",
                            action: noticeDeleteAction(queryClient),
                        },
                        {
                            path: CREATE_PATH,
                            element: <NoticeCreate/> ,
                            action: noticeAction(queryClient),
                        },
                        {
                            path: ":id",
                            element: <NoticeRead/>,
                            loader: noticeReadLoader(queryClient),
                        },
                        {
                            path: ":id/" + UPDATE_PATH,
                            element: <NoticeUpdate/>,
                            loader: noticeUpdateLoader(queryClient),
                            action: noticeUpdateAction(queryClient),
                        }
                    ]
                },
                {
                    path: CONTENT_INQUIRY_LIST_PATH,
                    children: [
                        {
                            index: true,
                            element: <Inquiry/>,
                            loader: inquiryListLoader(queryClient),
                        },
                        {
                            path: ":id",
                            element: <InquiryRead/>,
                            loader: inquiryReadLoader(queryClient),
                            children: [
                                {
                                    index: true,
                                    element: <ReplyContainer/>,
                                    loader: inquiryReadLoader(queryClient),
                                },
                                {
                                    path: "read",
                                    children: [
                                        {
                                            index: true,
                                            element: <ReplyRead/>,
                                            action: replyDeleteAction(queryClient),
                                        },
                                        {
                                            path: UPDATE_PATH,
                                            element: <ReplyUpdate/>,
                                            action: replyUpdateAction(queryClient)
                                        }, 
                                    ]
                                },
                                
                                {
                                    path: CREATE_PATH,
                                    element: <ReplyCreate/>,
                                    action: replyCreateAction(queryClient),
                                }
                            ],
                        }
                    ]
                },
                {
                    path: CONTENT_MEMBER_LIST_PATH,
                    children: [
                        {
                            index: true,
                            element: <Member/>,
                            loader: memberListLoader(queryClient),
                            action: memberChangeGradeAction(queryClient),
                        },
                        {
                            path: ":id",
                            element: <MemberRead/>,
                            loader: memberReadLoader(queryClient),
                        },
                        {
                            path: ":id/" + UPDATE_PATH,
                            element: <MemberUpdate/>,
                            loader: memberReadLoader(queryClient),
                            action: memberUpdateAction(queryClient)
                        }
                    ]
                },
                {
                    path: CONTENT_CALENDAR_PATH,
                    children: [
                        {
                            index: true,
                            element: <Calendar/>,
                            loader: calendarLoader(queryClient),
                            action: calendarAction(queryClient),
                        },
                    ]
                }
            ],
        }
    ]
}