import React, { useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/map')(Highcharts)

const LineChart = ({ title, yTitle, xAxisCategories, data}) => {

    const [state, ] = useState({
        chartOptions: {
            chart: {
                type: 'column',
                style: {
                    fontWeight: "bold"
                },
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                categories: xAxisCategories,
                crosshair: true,
                
            },
            yAxis: {
                min: 0,
                title: {
                    text: yTitle
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            credits: {
                enabled: false,
            },
            colors: [
                '#516be0', 
                ],
            plotOptions: {
                column: {
                    olorByPoint: true
                    }
                }, 
            series: data
        },
    })

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={state.chartOptions}/>
        </div>
    )
}

export default LineChart