import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { INQUIRY_LIST_DEFINE, INQUIRY_READ_DEFINE, REPLY_CREATE_DEFINE, REPLY_DELETE_DEFINE, REPLY_UPDATE_DEFINE } from "../../const/defineName";
import { INQUIRY_LIST_URL, INQUIRY_READ_URL, REPLY_CREATE_URL, REPLY_DELETE_URL, REPLY_UPDATE_URL } from "../../const/url";

export const inquiryDefine = {
    [INQUIRY_LIST_DEFINE] : {
        method: GET_METHOD,
        url: INQUIRY_LIST_URL,
    },
    [INQUIRY_READ_DEFINE] : {
        method: GET_METHOD,
        url: INQUIRY_READ_URL,
    },
    [REPLY_CREATE_DEFINE] : {
        method: POST_METHOD,
        url: REPLY_CREATE_URL,
    },
    [REPLY_UPDATE_DEFINE] : {
        method: PUT_METHOD,
        url: REPLY_UPDATE_URL,
    },
    [REPLY_DELETE_DEFINE] : {
        method: DELETE_METHOD,
        url: REPLY_DELETE_URL,
    },
}