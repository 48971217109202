export const GET_METHOD = "GET";
export const POST_METHOD = "POST";
export const PUT_METHOD = "PUT";
export const DELETE_METHOD = "DELETE";

export const ACCESS_TOKEN = "access_token";

export const ENABLE_STATUS = "ENABLE";
export const DISABLE_STATUS = "DISABLE";

export const PAGE_PER_SIZE = 50;

export const ROLE_APP_ADMIN = "ROLE_APP_ADMIN";
export const ROLE_APP_MANGER = "ROLE_APP_MANAGER";