export const statusOptions = [
    { value: "GREEN", label: "활동" },
    { value: "YELLOW", label: "사용정지" },
    { value: "BLACK", label: "강퇴" },
]

export const chartData = [
    {
      "country": "AD",
      "hot dog": 4,
      "hot dogColor": "hsl(268, 70%, 50%)",
      // "burger": 12,
      // "burgerColor": "hsl(282, 70%, 50%)",
      // "sandwich": 1,
      // "sandwichColor": "hsl(335, 70%, 50%)",
      // "kebab": 18,
      // "kebabColor": "hsl(148, 70%, 50%)",
      // "fries": 2,
      // "friesColor": "hsl(184, 70%, 50%)",
      // "donut": 12,
      // "donutColor": "hsl(272, 70%, 50%)"
    },
    // {
    //   "country": "AE",
    //   "hot dog": 6,
    //   "hot dogColor": "hsl(24, 70%, 50%)",
    //   "burger": 2,
    //   "burgerColor": "hsl(88, 70%, 50%)",
    //   "sandwich": 19,
    //   "sandwichColor": "hsl(154, 70%, 50%)",
    //   "kebab": 16,
    //   "kebabColor": "hsl(44, 70%, 50%)",
    //   "fries": 18,
    //   "friesColor": "hsl(211, 70%, 50%)",
    //   "donut": 15,
    //   "donutColor": "hsl(220, 70%, 50%)"
    // },
    // {
    //   "country": "AF",
    //   "hot dog": 70,
    //   "hot dogColor": "hsl(211, 70%, 50%)",
    //   "burger": 18,
    //   "burgerColor": "hsl(348, 70%, 50%)",
    //   "sandwich": 34,
    //   "sandwichColor": "hsl(59, 70%, 50%)",
    //   "kebab": 158,
    //   "kebabColor": "hsl(151, 70%, 50%)",
    //   "fries": 101,
    //   "friesColor": "hsl(203, 70%, 50%)",
    //   "donut": 107,
    //   "donutColor": "hsl(29, 70%, 50%)"
    // },
    // {
    //   "country": "AG",
    //   "hot dog": 151,
    //   "hot dogColor": "hsl(249, 70%, 50%)",
    //   "burger": 0,
    //   "burgerColor": "hsl(337, 70%, 50%)",
    //   "sandwich": 163,
    //   "sandwichColor": "hsl(239, 70%, 50%)",
    //   "kebab": 30,
    //   "kebabColor": "hsl(281, 70%, 50%)",
    //   "fries": 97,
    //   "friesColor": "hsl(271, 70%, 50%)",
    //   "donut": 184,
    //   "donutColor": "hsl(284, 70%, 50%)"
    // },
    // {
    //   "country": "AI",
    //   "hot dog": 89,
    //   "hot dogColor": "hsl(66, 70%, 50%)",
    //   "burger": 58,
    //   "burgerColor": "hsl(139, 70%, 50%)",
    //   "sandwich": 151,
    //   "sandwichColor": "hsl(24, 70%, 50%)",
    //   "kebab": 29,
    //   "kebabColor": "hsl(239, 70%, 50%)",
    //   "fries": 86,
    //   "friesColor": "hsl(266, 70%, 50%)",
    //   "donut": 168,
    //   "donutColor": "hsl(150, 70%, 50%)"
    // },
    // {
    //   "country": "AL",
    //   "hot dog": 112,
    //   "hot dogColor": "hsl(16, 70%, 50%)",
    //   "burger": 101,
    //   "burgerColor": "hsl(321, 70%, 50%)",
    //   "sandwich": 110,
    //   "sandwichColor": "hsl(13, 70%, 50%)",
    //   "kebab": 13,
    //   "kebabColor": "hsl(289, 70%, 50%)",
    //   "fries": 172,
    //   "friesColor": "hsl(353, 70%, 50%)",
    //   "donut": 112,
    //   "donutColor": "hsl(96, 70%, 50%)"
    // },
    // {
    //   "country": "AM",
    //   "hot dog": 1,
    //   "hot dogColor": "hsl(62, 70%, 50%)",
    //   "burger": 69,
    //   "burgerColor": "hsl(354, 70%, 50%)",
    //   "sandwich": 132,
    //   "sandwichColor": "hsl(168, 70%, 50%)",
    //   "kebab": 149,
    //   "kebabColor": "hsl(116, 70%, 50%)",
    //   "fries": 129,
    //   "friesColor": "hsl(245, 70%, 50%)",
    //   "donut": 74,
    //   "donutColor": "hsl(24, 70%, 50%)"
    // }
];