import React, { forwardRef } from 'react'
import Select from 'react-select'
import Input from '../../atom/input'

const options = [
    { value: "RARE", label: "준회원" },
    { value: "MEDIUM", label: "정회원" }
]

const colourStyles = {
    control: (baseStyles, state) => ({ //current
        ...baseStyles,
        border: state.isFocused  ? '1px solid #516be0' : '1px solid #C4C4C4',
        boxShadow: '#516be0',
        backgroundColor:'none',
        outline: state.isFocused ? '3px solid #e4e6ff' : "#fff" ,
        color: "#333",
        
    }),
    option: (baseStyles, state) => { //option
        return {
        ...baseStyles,
        backgroundColor: state.isFocused ? '#e4e6ff' : null,
        color: "#333333",
        }
    },
    singleValue: (baseStyles, state)  => ({ 
        ...baseStyles,
        color: "#333" //current color
    }),
}

const ChangeMemberGradePopup = forwardRef(({onCancle, onSubmit, error}, ref) => {
  return (
    <div className='popupWrap'>
        <div className='popupbg'>
            <h3>선택회원 등급 수정</h3>
            <span>선택한 회원의 등급을 수정하실 수 있습니다.<br/>준회원의 경우 앱의 기능을 이용하는데 제한을 둘 수 있습니다.</span>
            <div className='memberClass'>
                <Select options={options} styles={colourStyles} name='appMemberRole'/>
            </div>
            <Input type={"hidden"} error={error}/>
            <div className='btnwrap'>
                <button onClick={onCancle}>취소</button>
                <button className='yes' onClick={onSubmit} ref={ref}>등급 수정</button>
            </div>
        </div>
    </div>
  )
})

export default ChangeMemberGradePopup