import React from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'
import { adminReadQuery } from '../../../../queries/adminQueries';
import { useQuery } from '@tanstack/react-query';

export const adminReadLoader = 
    (queryClient) => async({params}) => {

        if(!queryClient.getQueryData(adminReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(adminReadQuery(params));
        }

        return { params };
    }

const AdminSettingRead = () => {
    const navigator = useNavigate();
    const { params } = useLoaderData();
    const { data: { data } } = useQuery(adminReadQuery(params));

    return (
        <div className="main memberWrap">
            <h2 className="subTit"><span>김철수</span> 관리자 상세</h2>

            <div className="d-flex">
            <div className="contentBg">
                    <h3>회원정보</h3>
                    <div className="tableWrap3">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>이름</th>
                                    <td>{ data.appManagerName }</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{ data.appManagerEmail }</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>{ data.appManagerPhone }</td>
                                </tr>
                                <tr>
                                    <th>권한 부여일</th>
                                    <td>{ data.createAt }</td>
                                </tr>
                                {/* <tr> 
                                    <th>권한</th>
                                    <td>공지사항 작성, 회원 관리</td>
                                </tr> */}
                            </thead>
                        </table>
                    </div>
                </div>   

                {/* <div className="contentBg">
                    <h3>활동 내역</h3>
                    <div className="history">
                        <div>
                            <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
                            <ol>
                                <li>공지사항 '<span>[세미나]오픈 공지내용 입니다</span>' 작성</li>
                                <li>문의글 '<span>문의드립니다.</span>' 답변</li>
                            </ol>
                        </div>
         
                        <div>
                            <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
                            <ol>
                                <li>일정 '<span>추수감사절 예배</span>' 설정</li>
                            </ol>
                        </div>
         
                        <div>
                            <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
                            <ol>
                                <li>앱 수정</li>
                            </ol>
                        </div>
                     </div>
                </div>      */}
            </div>
        </div>
    )
}

export default AdminSettingRead