import React from 'react'
import DaumPostcode from 'react-daum-postcode'

const AddressSearchPopup = ({ onComplete }) => {
  return (
    <div className='popupWrap'>
        <div className='popupbg'>
            <DaumPostcode
            onComplete={onComplete}/>
        </div>
    </div>
  )
}

export default AddressSearchPopup