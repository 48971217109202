import React from 'react'
import { useNavigate } from 'react-router-dom'
import MenuTitle from '../atom/menuTitle'
import MenuGroup from '../atom/menuGroup'
import Menu from '../atom/menu'
import ScrollToAnchor from '../components/scrollToAnchor'

const Guide = () => {
  const navigator = useNavigate();

    return (
        <div className='main guideWrap'>
            <ScrollToAnchor/>
            <div className="sidebar p-3">
                <div className="d-flex align-items-center justify-content-center pb-3 mb-3" onClick={() => {navigator(-1)}}>
                    <img src={`${process.env.PUBLIC_URL}/img/logo_w.svg`} alt="로고이미지"/><br/>
                </div>
                <h1>사용 매뉴얼</h1>

                {/* <div className="guideSearch">
                    <Searchbar/>
                </div> */}
                {/* 메뉴타이틀 누르면 해당 위치로 스크롤 */}
                <ul className="list-unstyled ps-0">
                    <MenuTitle to={"#start"} content={"AiApp 시작하기"}/>
                    <MenuTitle to={"#join"} content={"회원가입"}/>
                    <MenuGroup id={"content"} buttonContent={"로그인"} expanded={true}>
                        <Menu to={"#login"} content={"- 아이디/비밀번호찾기"} />
                    </MenuGroup>
                    <MenuTitle to={"#createApp"} content={"앱 생성"}/>
                    <MenuTitle to={"#mypage"} content={"마이페이지"}/>
                    <MenuTitle to={"/"} content={"대쉬보드"}/>
                    {/* <MenuTitle content={"기획서 관리"}/>
                    <MenuGroup  id={"content"} buttonContent={"설정 관리"} expanded={true}>
                        <Menu content={"- 엡 관리"} />
                        <Menu content={"- 대표정보 관리"} />
                        <Menu content={"- 게시판 관리"} />
                        <Menu content={"- 그룹 관리"} />
                        <Menu content={"- 사용환경설정"} />
                        <Menu content={"- 관리자 설정"} />
                    </MenuGroup>
                    <MenuGroup  id={"content"} buttonContent={"컨텐츠 관리"} expanded={true}>
                        <Menu content={"- 공지사항 관리"} />
                        <Menu content={"- 1:1 문의 관리"} />
                        <Menu content={"- 회원 관리"} />
                        <Menu content={"- 일정 관리"} />
                    </MenuGroup>
                    <MenuGroup  id={"content"} buttonContent={"기타"} expanded={true}>
                        <Menu content={"- 개인정보 처리방침"} />
                        <Menu content={"- 서비스 이용약관"} />
                    </MenuGroup> */}
                </ul>
            </div>


            <div className='contentBg'>
                <div className='guideTit'>
                    <h2>사용 매뉴얼</h2>
                    <p>AiApp이 처음이신가요? 사용 매뉴얼을 통해 궁금하신 점을 해결해보세요.</p>
                </div>
                <div className='guideCon' id='start'>
                    <h3>AiApp 시작하기</h3>
                    <div className='guideInfo d-flex'>
                        <div><img src="../../img/icon_info.svg" alt="설명 아이콘" /></div>
                        <p>AiApp은 <span>간단한 체크리스트를 통해 무료로 앱을 생성</span>하고, 기획서와 관리자 페이지를 자동으로 생성받아 관리하실 수 있습니다. 앱을 만들어보기 위해서는 가장 먼저 <span>계정이 필요</span>합니다. 계정이 없다면 회원가입을 통해 계정을 먼저 생성해 주시기 바랍니다.</p>
                    </div>
                </div>
                <div className='guideCon' id='join'>
                    <h4>1단계 : 회원가입하기</h4>
                    <p>앱을 생성하기 위해서는 계정이 필요합니다. <span>앱을 생성하는 계정이 슈퍼관리자</span>가 되기 때문에 단체 혹은 기업(회사)에서 생성하는 경우에는 대표적인 메일로 가입바랍니다. <br/> 생성 후 앱을 다른사람과 함께 관리하기 위해 관리자 권한을 부여하고 싶다면 <span>생성한 앱에 이메일 가입을 한 계정에게 권한을 부여</span>해 주실 수 있습니다.</p>
                    <p className='description'>소셜로그인(SNS 로그인)으로 한 계정에게는 관리자 권한을 부여하실 수 없습니다. 관리자권한을 받은 계정은 반드시 이메일 계정으로 가입해주세요.</p>
                    <div onClick={() => {window.open("https://www.mbaas.co.kr/p_joinTerms")}} className='link'>회원가입 하러가기 {'>'}</div>
                    <div className='guideImg'>
                        <img src="../../img/G_join.png" alt="회원가입 가이드" />
                    </div>
                </div>
                <div className='guideCon' id='login'>
                    <h4>2단계 : 로그인하기</h4>
                    <p>회원 가입한 계정으로 로그인시 계정당 무료앱 1개를 생성하실 수 있습니다.</p>
                    {/* 7일 부분 사용환경설정에서 기한 바꿀 시 같이 바뀔 수 있는지 */}
                    <p>무료앱 사용기한은 <span>7일</span>이고, 무료기간 동안 일부 기능들이 제한될 수 있습니다.</p>
                    <div className='guideInfo'>
                        <h5>2-1. 아이디/비밀번호 찾기</h5>
                        <p>아이디 혹은 비밀번호를 잃어버린 경우, <span>로그인 페이지에서</span> 아이디 찾기/비밀번호 찾기를 통해 가입시 사용한 정보를 통해 찾아주세요.</p>
                        <div className='guideImg'>
                            <img src="../../img/G_idPassword.png" alt="회원가입 가이드" />
                        </div>
                    </div>
                </div>
                <div className='guideCon' id='createApp'>
                    <h4>3단계 : 앱 생성</h4>
                    <p>간단한 체크리스트들을 통해 원하는 템플릿과 추가기능을 선택하여 앱을 생성해 주세요.<br/><span>앱 유형은 수정하실 수 없으며, 기본적으로 계정당 앱 1개만 생성이 가능합니다. 신중하게 선택해 주시길 바랍니다.</span></p>
                    <p className='description'>혹시나 생성 후 다른 앱을 생성하고자 한다면, 새 계정을 가입하여 생성해 주세요.</p>
                    <div className='addguide'>
                        <h5><img src="../../img/icon_congrats.svg" alt="축하 아이콘"/>원하는 추가기능도 선택해보세요</h5>
                        <ol>
                            <li>지속적인 피드백과 개발을 통해 더욱 다양한 추가기능을 만나 보실 수 있습니다. 많은 피드백 부탁드립니다!</li>
                            <li><span>앱 생성 이후</span>에도 추가기능을 <span>수정하실 수 있습니다.</span> 단, 추가기능 <span>수정 후에는 앱을 새로 다운</span>받아서 확인해주세요.</li>
                        </ol>
                    </div>
                    <div className='guideInfo d-flex'>
                        <div><img src="../../img/icon_info.svg" alt="설명 아이콘" /></div>
                        <dl>
                            <dt>앱 이름</dt>
                            <dd>앱 이름은 앱을 대표하는 이름입니다. 앱 생성 후 수정이 가능합니다.</dd>
                            <dt>프로젝트 명</dt>
                            <dd>프로젝트 명은 앱 생성 이후, 관리자 페이지에 로그인할 때 필요한 코드입니다. 수정이 불가능하니 신중하게 작성해 주세요.<br/>또한, 잃어버리거나 유출이 되지 않게 유의해주시기 바랍니다.</dd>
                        </dl>
                    </div>
                    <div className='guideImg'>
                        <img src="../../img/G_appcreate.png" alt="앱생성 가이드" />
                    </div>
                </div>
                <div className='guideCon' id='mypage'>
                    <h4>4단계 : 마이페이지</h4>
                    <p>앱을 생성하셨다면 앱에 상응하는 관리자페이지와 기획서가 생성됩니다. <br/>기획서와 관리자 페이지를 통행 앱설정을 변경하거나, 앱 회원 관리 등을 하실 수 있으며, 앱 다운로드 QR를 통해 앱 테스트를 진행하실 수 있습니다.</p>
                    <p>앱 테스트의 경우, apk 파일로 <span>현재 AOS 기반(안드로이드)에서만 테스트가 가능</span>합니다. IOS(아이폰) 혹은 그외 OS에서는 테스트할 수 없습니다. </p>
                    <div className='guideImg'>
                        <img src="../../img/G_mypage.png" alt="앱생성 가이드" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guide