import { ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE, GROUP_CREATE_DEFINE, GROUP_LIST_DEFINE, GROUP_READ_DEFINE, GROUP_UPDATE_DEFINE } from "../const/defineName"
import { fetchData, fetchDataPathVar } from "../util/util"

export const allGroupListForCreateOrUpdateQuery = () => ({
    queryKey: ["groupListForCreateOrUpdate"],
    queryFn: async() => {
        const json = await fetchData(ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE);
        return json;
    }
})

export const groupCreateQuery = (params) => ({
    queryKey: ["groupCreateQuery"],
    queryFn: async() => {
        const json = await fetchData(GROUP_CREATE_DEFINE, params);
        return json;
    }
})

export const groupListQuery = (params) => ({
    queryKey: ["groupList", params.page],
    queryFn: async() => {
        const json = await fetchDataPathVar(GROUP_LIST_DEFINE, {},params);
        return json;
    }
})

export const groupReadQuery = (params) => ({
    queryKey: ["groupRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(GROUP_READ_DEFINE, params);
        return json;
    }
})

export const groupUpdateQuery = (params) => ({
    queryKey: ["groupUpdate", params.id],
    queryFn: async() => {
        const json = await fetchData(GROUP_UPDATE_DEFINE, params);
        return json;
    }
})