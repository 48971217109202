import { useEffect, useState } from "react";
import { FILE_HOST } from "../../server/host";

const IconUploader = ({data:{data}, defaultValue={fileUrl: null, fileId: null}}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectIcon, setSelectIcon] = useState(defaultValue.fileUrl ? `${FILE_HOST}${defaultValue.fileUrl}` : null);
    const [selectFileId, setSelectFileId] = useState(defaultValue.fileId);
    const [isNoIcon, setIsNoIcon] = useState(defaultValue.fileId ? false : true);

    useEffect(() => {
        if(selectIcon) {
            setIsNoIcon(false);
        } else {
            setIsNoIcon(true);
        }
    }, [selectIcon])

    useEffect(() => {
        if(isNoIcon) {
            setSelectIcon("");
            setSelectFileId(null);
        }
    }, [isNoIcon])

    const onClickIconBtn = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    const onClickIcon = (idx) => {
        setSelectIcon(`${FILE_HOST}${data[idx].fileUrl}`);
        setSelectFileId(data[idx].fileId);
        setIsOpen(!isOpen);
    }

    const onClickNoIcon = () => {
        setIsNoIcon(!isNoIcon);
    }

    return (
        <div className="mb-4 d-grid">
            <label htmlFor="formFile" className="form-label">게시판 아이콘</label>
            <div>
            <div className="d-flex align-items-center iconWrap">
                <button className="iconSelect" onClick={onClickIconBtn}>아이콘 선택하기</button>
                <div className='iconSelectCon' style={{display: isOpen ? "block" : "none"}}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2>아이콘 선택하기</h2>
                        <div className='d-flex align-items-center'>
                            {/* <div>
                                <input type="text" />
                                <button><img src={`${process.env.PUBLIC_URL}/img/search_b.png`} alt="검색 아이콘" /></button>
                            </div> */}
                            <button className='closeBtn' onClick={onClickIconBtn}></button>
                        </div>
                    </div>
                    <ul className='iconList d-flex justify-content-start align-items-center'>
                        {
                            data.map((icon, idx) => (
                                <li onClick={() => onClickIcon(idx)} key={idx}>
                                    <img src={`${FILE_HOST}${icon.fileUrl}`} alt="" />
                                </li>
                            ))
                        }
                    </ul>
                    { selectFileId ? <input type='hidden' name='fileId' value={selectFileId} readOnly/> : "" }
                </div>
                {
                    !isNoIcon && selectIcon ? 
                    <div className='boardiconWrap'>
                        <p>아이콘 미리보기</p>
                        <div className='boardicon'>
                            <img src={selectIcon} alt='앱 아이콘 이미지'/>
                            <div  className='deleteBtn'></div>
                        </div>
                    </div> : ""
                }
            </div>
            <div>
                <div className="small">아이콘 직접 첨부시 깨짐방지를 위해 .svg 파일를 권장합니다</div>
                <div className="checkBox d-flex justify-content-start align-items-center">
                    <label htmlFor="noicon" className="chk_box">
                        <input type="checkbox" id="noicon" onChange={onClickNoIcon} checked={isNoIcon}/>
                        <span className="checkon"></span>
                        아이콘 없음
                    </label>
                </div>
            </div>
            </div>
        </div>
    );
}

export default IconUploader;