import React from 'react'

const GroupSearch = ({ onChangeSearch }) => {
  return (
    <div className="boardListWrap d-flex justify-content-start align-items-center">
        <div className="input-group mb-3">
            {/* <Select>
                <option value="javascript">전체</option>
                <option value="python">이름</option>
                <option value="c++">휴대폰 번호</option>
                <option value="java">이메일</option>
            </Select> */}
            <input type="text" onChange={onChangeSearch} className="form-control" aria-label="Text input with button" placeholder="검색어를 입력하세요"/>
        </div>
    </div>
  )
}

export default GroupSearch