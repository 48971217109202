import React from 'react'
import Button from '../atom/button'
import { myInfoQuery } from '../../queries/adminQueries'
import { useQuery } from '@tanstack/react-query';
import { getRoleName } from '../../util/util';

export const myPageLoader = 
    (queryClient) => async() => {
        if(!queryClient.getQueryData(myInfoQuery().queryKey)) {
            return await queryClient.fetchQuery(myInfoQuery());
        } 
        return null;
    }

const Mypage = () => {
    const { data: { data } } = useQuery(myInfoQuery());

    return (
        <div className="main">
            <h2 className="subTit">내 정보</h2>
            <div className="contentBg mypage">
                    <div className="tableWrap3">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>이름</th>
                                    <td>{ data.appManagerName }</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{ data.appManagerEmail }</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>{ data.appManagerPhone }</td>
                                </tr>
                                <tr>
                                    <th>가입일</th>
                                    <td>{ data.createAt }</td>
                                </tr>
                                {/* <tr>
                                    <th>상태</th>
                                    <td>{ data.appManagerName }</td>
                                </tr> */}
                                <tr>
                                    <th>권한</th>
                                    <td>{ getRoleName(data.appManagerRole) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <Button content={"내 정보 수정"}/>

                </div>  
        </div>
    )
}

export default Mypage