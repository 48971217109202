import { APP_DETAIL_DEFINE, APP_UPDATE_DEFINE } from "../../const/defineName";
import { APP_DETAIL_URL, APP_UPDATE_URL } from "../../const/url";
import { GET_METHOD, PUT_METHOD} from "../../const/const";

export const appDefine = {
    [APP_DETAIL_DEFINE]: {
        method: GET_METHOD,
        url: APP_DETAIL_URL
    },
    [APP_UPDATE_DEFINE]: {
        method: PUT_METHOD,
        url: APP_UPDATE_URL
    }
}