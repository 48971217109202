import React from 'react'
import Sidebar from '../components/sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../components/header'
import { useLocation } from "react-router-dom"
import { motion } from "framer-motion"
import { myInfoQuery } from '../../queries/adminQueries'
import { useQuery } from '@tanstack/react-query'

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.2
};

export const layoutLoader = 
  (queryClient) => async() => {
    return await queryClient.fetchQuery(myInfoQuery());
  }

const Layout = () => {
  const { pathname } = useLocation();
  const { data: { data } } = useQuery(myInfoQuery());

  return (
    <div className="wrap">
      <Header name={data.appManagerName} profile={"/img/profile.png"}/>
      <Sidebar auth={data.appManagerRole}/>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet/>
      </motion.div>
    </div>
  )
}

export default Layout