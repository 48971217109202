import React, { useEffect } from 'react'
import { useLocation, useLoaderData, useFetcher } from 'react-router-dom'
import { userBoardBlindContentQuery, userBoardBlindReplyQuery, userBoardReadQuery, userBoardUnBlindContentQuery, userBoardUnBlindReplyQuery } from '../../../../../queries/userBoardQueries';
import { useQuery } from '@tanstack/react-query';
import ToggleBtnChangeEvent from '../../../../atom/toggleBtnChangeEvent';
import { POST_METHOD } from '../../../../../const/const';
import { makeParamsFromFormData } from '../../../../../util/util';

export const userBoardReadLoader = 
    (queryClient) => async({params}) => {
        const queryParam = {
            code: params.contentId,
            menuCode: params.userBoardId,
        }

        if(!queryClient.getQueryData(userBoardReadQuery(queryParam).queryKey)) {
            (await queryClient.fetchQuery(userBoardReadQuery(queryParam)));
        }

        return {queryParam};
    }

export const userBoardUpdateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        
        switch(params.type) {
            case "content": 
                if(params.flag === "true") {
                    queryClient.fetchQuery(userBoardBlindContentQuery(params));
                    return null;
                } else {
                    queryClient.fetchQuery(userBoardUnBlindContentQuery(params));
                    return null;
                }

            case "reply":
                if(params.flag === "true") {
                    queryClient.fetchQuery(userBoardBlindReplyQuery(params));
                    return null;
                } else {
                    queryClient.fetchQuery(userBoardUnBlindReplyQuery(params));
                    return null;
                }

            default:
                break;
        }

        return null;
    }

const UserBoardRead = () => {
    const { queryParam } = useLoaderData();
    const { state } = useLocation();
    const { data : { data }} = useQuery(userBoardReadQuery(queryParam));
    const fetcher = useFetcher();

    const onChangeBtn = (flag, type, value) => {
        const params = {
            flag: flag,
            code: value,
            type: type,
        }

        fetcher.submit(params, {method: POST_METHOD});
    }

    return (
        <div className="main">
            <div className="contentBg">
                <div className='d-flex justify-content-between align-content-center'>
                    <h2 className="subTit"><span>{state.name}</span> 게시글</h2>
                    <ToggleBtnChangeEvent 
                        description={"게시글 블라인드 처리"}
                        onClick={onChangeBtn}
                        value={state.code}
                        type={"content"}
                        defaultChecked={state.status === "BLIND"}/>
                </div>
                
                <div className="formWrap">
                    <div className="mb-4 d-grid">
                        <label  className="form-label">게시글 제목</label>
                        <div>{state.title}</div>
                    </div>

                    <div className="mb-4 d-grid">
                        <label className="form-label">게시글 내용</label>
                        <div className='contentTxt'>
                           {state.content}
                        </div>
                    </div>
                    {/* 영상없으면 - 표시 혹은 안보이게 처리 */}
                    <div className="mb-4 d-grid">
                        <label className="form-label">영상 링크</label>
                        {
                            data.videoLink ? 
                            <div onClick={() => {window.open(data.videoLink)}} className='link'>{data.videoLink}</div> : "-"
                        }
                    </div>

                    <div className="mb-4 d-grid">
                        <div className='d-flex align-centent-center downloadWrap'>
                            <label  className="form-label">첨부파일</label>
                            <button className='download'></button>
                        </div>
                        <div className='d-flex'>
                        {
                            state.fileInfoList.map(file => (
                                <div className="fileImg attachment" key={file.fileUrl}>
                                    <img src={file.fileUrl} alt={file.fileUrl} />
                                    <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBg">
                <h2 className="subTit">댓글 ({data.replyCount})</h2>
                <div className="formWrap">
                    {
                        data.reply.length !== 0 ?
                            data.reply.map(rep => (
                                <div className="mb-4 d-grid" key={rep.code}>
                                    <label className="form-label">작성자</label>
                                    <div className='d-flex comment'>
                                        <div>작성자가 댓글내용을 작성한 부문입니다.작성자가 댓글내용을 작성한 부문입니다.작성자가 댓글내용을 작성한 부문입니다.작성자가 댓글내용을 작성한 부문입니다.작성자가 댓글내용을 작성한 부문입니다.작성자가 댓글내용을 작성한 부문입니다.</div>
                                        <ToggleBtnChangeEvent 
                                            description={"댓글 블라인드 처리"}
                                            onClick={onChangeBtn}
                                            value={rep.code}
                                            type={"reply"}
                                            defaultChecked={rep.status === "BLIND"}/>
                                    </div>
                                </div>
                            ))
                            : <div  className="nodata"><p>등록된 댓글이 없습니다</p></div>
                    }
                </div>  
            </div>
        </div>
    )
}

export default UserBoardRead