import React from 'react'
import Button from '../../../atom/button'
import Select from "react-select"
import Input from '../../../atom/input'


const EnvSettings = () => {
    const options = [
        { value: "", label: "준회원" },
        { value: "", label: "정회원" },
      ]
      const colourStyles = {
        control: (baseStyles, state) => ({ //current
          ...baseStyles,
          border: state.isFocused  ? '1px solid #516be0' : '1px solid #C4C4C4',
          boxShadow: '#516be0',
          backgroundColor:'none',
          outline: state.isFocused ? '3px solid #e4e6ff' : "#fff" ,
          color: "#333",
          width:"400px"
        }),
        option: (baseStyles, state) => { //option
          return {
            ...baseStyles,
            backgroundColor: state.isFocused ? '#e4e6ff' : null,
            color: "#333333",
          }
        },
        singleValue: (baseStyles, state)  => ({ 
          ...baseStyles,
          color: "#333" //current color
        }),
      }

    return (
        <div className="main">
        <div className="contentBg">
            <h2 className="subTit">사용환경설정</h2>

            {/* 기본시에는 저장된 값 보여주기 */}
            <div className='formWrap envWrap'>
                <div className='mb-4 d-grid'>
                    <label htmlFor="join"  className="form-label">최초 회원가입 회원 권한</label>
                    <div>
                        <div>
                            <Select options={options} styles={colourStyles} />
                        </div>
                        <p className="info">최초 회원가입한 회원에게 어떤 권한을 줄지 선택하실 수 있습니다.</p>
                    </div>
                </div>
                <Input 
                  label={"자동 로그아웃 시간"}
                  name={"time"}
                  placeholder={"대기시간을 입력해주세요"}
                  comment={"관리자 페이지에서 아무동작없이 일정 대기시간이 지나면 자동 로그아웃 되게 설정할 수 있습니다. (600 = 10분)"}/>

                  {/* 엠바스 슈퍼관리자만 보이는 권한설정 */}
                  {/* <Input 
                  label={"무료 사용기간 설정"}
                  name={"number"}
                  placeholder={"사용기간을 입력해주세요"}
                  comment={"앱사용자들의 앱 무료 사용기간을 설정하실 수 있습니다. (24=24시간, 48=48시간)"}/>
                  <Input 
                  label={"무료 사용기간 연장 횟수"}
                  name={"number"}
                  placeholder={"연장 횟수를 입력해주세요."}
                  comment={"무료 사용기간을 연장할 수 있습니다."}
                  defaultValue={"0"}/>
                  <Input 
                  label={"무료앱 생성횟수"}
                  name={"number"}
                  placeholder={"횟수를 입력해주세요."}
                  comment={"무료앱 생성 가능 횟수를 설정하실 수 있습니다."}
                  defaultValue={"1"}/> */}
            </div>

            <Button content={"설정 저장"}/>

        </div>
        </div>
    )
}

export default EnvSettings