import React, { useRef } from 'react'
import { useNavigate, useLoaderData, useSearchParams, useFetcher } from 'react-router-dom'
import { CREATE_PATH } from '../../../../const/path'
import Button from '../../../atom/button'
import SearchAndDelete from '../../../components/searchAndDelete'
import Pagination from '../../../atom/pagination'
import { DELETE_METHOD, PAGE_PER_SIZE } from '../../../../const/const'
import { noticeDeleteQuery, noticeListQuery } from '../../../../queries/noticeQueries'
import { useQuery } from '@tanstack/react-query'
import { makeParamsFromFormData } from '../../../../util/util'

export const noticeListLoader = 
    (queryClient) => async({request}) => {
      const url = new URL(request.url);
      const page = url.searchParams.get('page') ?? 0;
      const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
      const title = url.searchParams.get('title') ?? "";

      const param = {
          page: page,
          size: size,
      }

      if(title) {
        param.title = title
      }
              
      await queryClient.fetchQuery(noticeListQuery(param));
      return { param };
    }

export const noticeDeleteAction = 
  (queryClient) => async({request}) => {
    const formData = await request.formData();
    const params = makeParamsFromFormData(formData, "noticeCodeList");

    await queryClient.fetchQuery(noticeDeleteQuery(params));

    return null;
  }

const Notice = () => {
  const navigator = useNavigate();
  const { param } = useLoaderData();
  const { data: { data: notice } } = useQuery(noticeListQuery(param));
  const [, setSearchParams ] = useSearchParams();
  const noticeRef = useRef([]);
  const fetcher = useFetcher();

  const onChangeHandle = (page) => {
    setSearchParams({page: page.selected});
  }

  const checkAll = (e) => {
    noticeRef.current.forEach(ref => {
      ref.checked = e.target.checked;
    });
  }

  return (
    <div className="main">
      <div className="contentBg">
        <div className='d-flex justify-content-between align-items-center subTitWrap'>
          <h2 className="subTit">공지사항 리스트</h2>
          <Button content={"공지 글쓰기"} onClick={() => {navigator(CREATE_PATH)}}/>
        </div>
          <SearchAndDelete 
            isDelete={true}
            htmlFor={"deleteBtn"}
            searchName={"title"}>
            {/* <option value="all">전체</option> */}
            <option value="title">제목</option>
            {/* <option value="writedate">작성일</option>
            <option value="uploaddate">게시일</option>
            <option value="writer">작성자</option> */}
          </SearchAndDelete>

          <fetcher.Form method={DELETE_METHOD} action='delete'>
            <button style={{display: "none"}} id="deleteBtn">삭제 버튼</button>
            <div className="tableWrap">
              <table className="table table-hover boardCheck">
                <thead>
                  <tr>
                    <th scope="col" className='tableshort'>
                      <label htmlFor="selectAll" className="chk_box">
                        <input 
                          type="checkbox" 
                          id="selectAll" 
                          className="checkAll" 
                          onChange={checkAll}/>
                        <span className="checkon"></span>
                      </label>
                    </th>
                    <th scope="col" className='tableshort'>No</th>
                    <th scope="col">제목</th>
                    <th scope="col">작성일</th>
                    <th scope="col">게시일</th>
                    <th scope="col">작성자</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {
                    notice.noticeList.length !== 0 ? notice.noticeList.map((n, idx) => (
                      <tr onClick={() => {navigator(n.noticeCode)}} key={n.noticeCode}>
                        <td onClick={(e) => {e.stopPropagation();}} className='tableshort'>
                          <label htmlFor={n.noticeCode} className="chk_box">
                            <input type="checkbox" 
                              id={n.noticeCode} 
                              name="noticeCodeList" 
                              className="chkbox" 
                              value={n.noticeCode}
                              ref={e => noticeRef.current[idx] = e}
                              onChange={() => {}}/>
                            <span className="checkon"></span>
                          </label>
                        </td>
                        <td className='tableshort'>{n.sequence}</td>
                        <td>{n.noticeTitle}</td>
                        <td>{n.createAt}</td>
                        <td>{n.noticeStartDate}</td>
                        <td>김철수 관리자</td>
                      </tr>
                    )) : <td colSpan={6}><div className='nodata'><p>등록된 공지사항이 없습니다</p></div></td>
                  }
                </tbody>
              </table>
            </div>
        </fetcher.Form>
        <Pagination
          initialPage={Number(param.page)}
          onChange={onChangeHandle}
          totalCount={notice.totalCount}/>

      </div>
    </div>
  )
}

export default Notice