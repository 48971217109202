import LineChart from "./lineChart";

export const DndTest = () => {
  return (
    <div>
      <LineChart/>
    </div>
  );    
}
  

export default DndTest