/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useLoaderData } from 'react-router-dom';
import { CREATE_PATH } from '../../../../../const/path';
import { inquiryReadQuery } from '../../../../../queries/inquiryQueries';

const ReplyContainer = () => {
    const { params } = useLoaderData();
    const { data: { data: inquiry} } = useQuery(inquiryReadQuery(params));
    const navigator = useNavigate();

    const renderReply = () => {
        if(inquiry.answer) {
            navigator("read", {state: {...inquiry}})
        } else {
            navigator(CREATE_PATH);
        }
    }

    useEffect(() => {
        renderReply();
    }, [inquiry])

    return (
        <>
        </>
    )
}

export default ReplyContainer