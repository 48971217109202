import React from 'react'
import Button from '../../../atom/button'
import { companyListQuery } from '../../../../queries/companyQueries';
import { redirect, useNavigate } from 'react-router-dom'
import { CREATE_PATH } from '../../../../const/path';

export const companyReadLoader = 
    (queryClient) => async() => {
        const response = await queryClient.fetchQuery(companyListQuery());
        
        if(response.result === "FAIL") {
            return null;
        } else {
            return redirect("detail")
        }
    }

const Company = () => {
    const navigator = useNavigate();
    return (
        <div className="main">
            <div className="contentBg">
            <h2 className="subTit">대표 정보 관리</h2>

            {/* 데이터가 없는 경우 */}
            <div className='nodata'><p>등록된 회사 정보가 없습니다.</p></div>

            <div className="btnWrap d-flex justify-content-end align-items-center">
                <Button content={"대표정보 생성"} onClick={() => navigator(CREATE_PATH)}/>
            </div>
            </div>
        </div>
    );
}

export default Company