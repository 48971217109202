import React from 'react'
import Input from '../../../atom/input'
import Button from '../../../atom/button'
import { Form, redirect, useActionData } from 'react-router-dom'
import GroupAndMemberSelect from '../../../components/groupAndMemberSelect'
import { allGroupListForCreateOrUpdateQuery, groupCreateQuery } from '../../../../queries/groupQueries'
import { useQuery } from "@tanstack/react-query"
import { POST_METHOD } from '../../../../const/const'
import { makeParamsFromFormData } from '../../../../util/util'
import { SETTING_GROUP_PATH, SETTING_PATH } from '../../../../const/path'

export const groupCreateMemberListLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(allGroupListForCreateOrUpdateQuery().queryKey)) {
            return (await queryClient.fetchQuery(allGroupListForCreateOrUpdateQuery()));
        }
        return {params};
    }

export const groupCreateMemberListAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData, "appMemberCode");
        const error = {};

        if(!params.groupName) {
            error.groupName = "그룹 이름은 필수 값입니다.";
            return error;
        }
        else if(!params.appMemberCode) {
            error.appMemberCode = "그룹에 할당할 회원을 지정해주세요.";
            return error;
        }

        params.appMemberCodeDtoList = [];

        params.appMemberCode.forEach(c => {
            params.appMemberCodeDtoList.push({
                appMemberCode: c
            })
        });
        params.appMemberCode = null;

        const response = await queryClient.fetchQuery(groupCreateQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_GROUP_PATH}/${response.data}`);
        }

        return error;
    }

const GroupCreate = () => {
    const errors = useActionData();
    const {data: {data: groupList}} = useQuery(allGroupListForCreateOrUpdateQuery());

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">그룹 생성</h2>
                <Form method={POST_METHOD}>
                    <div className="formWrap">
                        <Input 
                            label={"그룹 이름"}
                            name={"groupName"}
                            placeholder={"그룹 이름을 작성해주세요"}
                            error={errors?.groupName}
                            required/>
                    
                        <GroupAndMemberSelect 
                            data={groupList} 
                            memberInputName={"appMemberCode"}
                            error={errors?.appMemberCode}/>
                            <p className='info'>그룹에 포함할 사람을 검색하여 해당 화살표를 눌러 추가/제거해주세요</p>
                    
                    <div className='btnWrap justify-content-end'>  
                        <div className='off'>
                            <Button content={"취소"}/>
                        </div>
                        <Button content={"그룹 생성"}/>
                    </div>

                    </div>
                </Form>
            </div>


        </div>
    )
}

export default GroupCreate