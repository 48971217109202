import React, { useEffect, useRef, useState } from 'react'

const MenuGroup = ({id, children, buttonContent, src, alt, expanded}) => {
  const [isPending, setIsPending] = useState(expanded ?? false);
  const parentRef = useRef();
  const childRef = useRef();

  useEffect(() => {
    if(expanded) {
      parentRef.current.style.height = `${childRef.current.clientHeight}px`;
    }
  }, [expanded])

  const handleButtonClick = (event) => {
    event.stopPropagation();

    if (parentRef.current === null || childRef.current === null) {
      return;
    }
    if (parentRef.current.clientHeight > 0) {
      parentRef.current.style.height = "0";
    } else {
      parentRef.current.style.height = `${childRef.current.clientHeight}px`;
    }
    setIsPending(!isPending);
  }


  return (
    <li className="mb-1 border-bottom">
        <img src={src} alt={alt}/>
        <button 
            className={"btn btn-toggle align-items-center rounded"} 
            aria-expanded={isPending}
            onClick={handleButtonClick}>
            {buttonContent}
        </button>
        {/* className={isPending ? "collapse show" : "collapse"} */}
        <div ref={parentRef} style={{overflow: "hidden", height: "0px", transition: "all 0.3s ease-out"}}>
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small" ref={childRef}>
              {children}
            </ul>
        </div>
    </li>
  )
}

export default MenuGroup