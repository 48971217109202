import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { CALENDAR_CREATE_DEFINE, CALENDAR_DELETE_DEFINE, CALENDAR_LIST_DEFINE, CALENDAR_UPDATE_DEFINE } from "../../const/defineName";
import { CALENDAR_CREATE_URL, CALENDAR_DELETE_URL, CALENDAR_LIST_URL, CALENDAR_UPDATE_URL } from "../../const/url";

export const calendarDefine = {
    [CALENDAR_CREATE_DEFINE]: {
        method: POST_METHOD,
        url: CALENDAR_CREATE_URL,
    },
    [CALENDAR_LIST_DEFINE]: {
        method: GET_METHOD,
        url: CALENDAR_LIST_URL,
    },
    [CALENDAR_DELETE_DEFINE]: {
        method: DELETE_METHOD,
        url: CALENDAR_DELETE_URL,
    },
    [CALENDAR_UPDATE_DEFINE]: {
        method: PUT_METHOD,
        url: CALENDAR_UPDATE_URL,
    }
}