import React from 'react'

const Select = ({ children, name, onChange }) => {
  return (
    <select name={name} id="search" onChange={onChange}>
        {children}
    </select>
  )
}

export default Select