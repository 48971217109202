import { INQUIRY_LIST_DEFINE, INQUIRY_READ_DEFINE, REPLY_CREATE_DEFINE, REPLY_DELETE_DEFINE, REPLY_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const inquiryListQuery = (params) => ({
    queryKey: ["inquiryList", params.page],
    queryFn: async() => {
        const json = await fetchDataPathVar(INQUIRY_LIST_DEFINE, {}, params);
        return json;
    }
})

export const inquiryReadQuery = (params) => ({
    queryKey: ["inquiryRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(INQUIRY_READ_DEFINE, params);
        return json;
    }
})

export const replyCreateQuery = (params) => ({
    queryKey: ["replyCreate"],
    queryFn: async() => {
        const json = await fetchData(REPLY_CREATE_DEFINE, params);
        return json;
    }
})

export const replyUpdateQuery = (params) => ({
    queryKey: ["replyUpdate"],
    queryFn: async() => {
        const json = await fetchData(REPLY_UPDATE_DEFINE, params);
        return json;
    }
})

export const replyDeleteQuery = (params) => ({
    queryKey: ["replyDelete"],
    queryFn: async() => {
        const json = await fetchData(REPLY_DELETE_DEFINE, params);
        return json;
    }
})