import React, { useState } from 'react'
import SearchAndDelete from '../../../components/searchAndDelete'
import { useNavigate, useLoaderData, useSearchParams } from 'react-router-dom'
import Pagination from '../../../atom/pagination';
import { PAGE_PER_SIZE } from '../../../../const/const';
import { inquiryListQuery } from '../../../../queries/inquiryQueries';
import { useQuery } from '@tanstack/react-query';

export const inquiryListLoader = 
    (queryClient) => async({request}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
        const title = url.searchParams.get('title');
        const name = url.searchParams.get('name');

        const param = {
            page: page,
            size: size,
        }

        if(title) param.title = title;
        else if(name) param.name = name;
                
        if(!queryClient.getQueryData(inquiryListQuery(param).queryKey)) {
            await queryClient.fetchQuery(inquiryListQuery(param));
        }
        return { param };
    }

const Inquiry = () => {
    const navigator = useNavigate();
    const { param } = useLoaderData();
    const { data: { data } } = useQuery(inquiryListQuery(param));
    const [, setSearchParams ] = useSearchParams();
    const [searchType, setSearchType] = useState("title");

    const onChangeHandle = (page) => {
        setSearchParams({page: page.selected});
      }

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">1:1 문의 리스트</h2>

                <SearchAndDelete 
                    isDelete={false}
                    searchName={searchType}
                    onChageSearchType={(e) => setSearchType(e.target.value)}>
                    <option value="title">제목</option>
                    <option value="name">문의자</option>
                </SearchAndDelete>

                <div className="tableWrap">
                <table className="table table-hover boardCheck">
                    <thead>
                        <tr>
                            <th scope="col"  className="tableshort">No</th>
                            <th scope="col">유형</th>
                            <th scope="col">제목</th>
                            <th scope="col">문의자</th>
                            <th scope="col">문의일</th>
                            <th scope="col">답변자</th>
                            <th scope="col">답변일</th>
                            <th scope="col">상태</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.qnaList.length !== 0 ?
                            data.qnaList.map(qna => (
                                <tr onClick={() => {navigator(qna.qnaCode)}} key={qna.qnaCode}>
                                    <td className="tableshort">{qna.sequence}</td>
                                    <td>유형1</td>
                                    <td>{qna.title} </td>
                                    <td>{qna.name}</td>
                                    <td>{qna.createAt}</td>
                                    <td>관리자</td>
                                    <td>{qna.replyCreateAt}</td>
                                    <td>
                                        <p className={qna.answer ? "status_green" : "status_gray"}>
                                            {qna.answer ? "답변" : "미답변"}
                                        </p>
                                    </td>
                                </tr>
                            )) : <td colSpan={8}><div className='nodata'><p>등록된 문의사항이 없습니다</p></div></td>
                    }
                    </tbody>
                </table>
                </div>
                <Pagination
                    initialPage={param.page}
                    totalCount={data.totalCount}
                    onChange={onChangeHandle}
                    />
            </div>      
        </div>
    )
}

export default Inquiry