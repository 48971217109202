import React from 'react'
import FileUploader from '../../../../atom/fileUploader'
import Input from '../../../../atom/input'
import Editor from '../../../../atom/editor'
import { Form, useOutletContext, redirect, useActionData } from 'react-router-dom'
import { POST_METHOD } from '../../../../../const/const'
import { CONTENT_INQUIRY_LIST_PATH, CONTENT_PATH } from '../../../../../const/path'
import { makeParamsFromFormData, uploadFile } from '../../../../../util/util'
import { FILE_POST_DEFINE, FILE_UPDATE_DEFINE } from '../../../../../const/defineName'
import { replyUpdateQuery } from '../../../../../queries/inquiryQueries'

export const replyUpdateAction = 
  (queryClient) => async({request}) => {
    const formData = await request.formData();
    const params = makeParamsFromFormData(formData);
    const errors = {};

    if(!params.title) {
        errors.title = "답변 제목은 필수 값입니다.";
        return errors;
    }
    if(!params.content) {
        errors.content = "답변은 필수 값입니다.";
        return errors;
    }

    if(!formData.get("fileId")) {
      const fileId = await uploadFile(FILE_POST_DEFINE, formData);
      params.fileId = fileId;
    } else {
        await uploadFile(FILE_UPDATE_DEFINE, formData);
    }
    const response = await queryClient.fetchQuery(replyUpdateQuery(params));

    if(response.result === "SUCCESS") {
        return redirect(`${CONTENT_PATH}/${CONTENT_INQUIRY_LIST_PATH}/${params.code}`);
    }
    

    return errors;
  }

const ReplyUpdate = () => {
    const { reply, inquiryCode } = useOutletContext();
    const errors = useActionData();

    return (
        <Form method={POST_METHOD} encType='multipart/form-data'>
            <div className="contentBg">
                <h3>문의 답변 수정</h3>
                <input type='hidden' name='replyCode' value={reply.replyCode}/>
                <input type='hidden' name='code' value={inquiryCode}/>
                <div className="tableWrap4">
                    <table className="table">
                    <tbody>
                        <tr>
                            <th>제목</th>
                            <td>
                                <Input
                                    placeholder={"답변 제목을 작성해주세요"}
                                    comment={"제목을 100자 이내로 작성해주세요"}
                                    name={"title"}
                                    error={errors?.title}
                                    defaultValue={reply.title}
                                    required/>
                            </td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td>
                                <Editor
                                    name={"content"}
                                    defaultValue={reply.content}
                                    error={errors?.content}
                                    required/>
                            </td>
                        </tr>
                        <tr className="line"></tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                                <FileUploader fileId={reply.fileId}/>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <div className="btnWrap d-flex justify-content-end align-items-center">
                        <button>답변수정</button>
                    </div>
                </div>
            </div> 
        </Form>
  )
}

export default ReplyUpdate