import React from 'react'
import Input from '../../../atom/input'
import Button from '../../../atom/button'
import { Form, redirect, useActionData, useLoaderData, useNavigate } from 'react-router-dom'
import GroupAndMemberSelect from '../../../components/groupAndMemberSelect'
import { allGroupListForCreateOrUpdateQuery, groupReadQuery, groupUpdateQuery } from '../../../../queries/groupQueries'
import { useQueries } from "@tanstack/react-query"
import { POST_METHOD } from '../../../../const/const'
import { makeParamsFromFormData } from '../../../../util/util'
import { SETTING_GROUP_PATH, SETTING_PATH } from '../../../../const/path'

export const groupUpdateLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(allGroupListForCreateOrUpdateQuery().queryKey)) {
            await queryClient.fetchQuery(allGroupListForCreateOrUpdateQuery());
        }
        if(!queryClient.getQueryData(groupReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(groupReadQuery(params));
          }
        return {params};
    }

export const groupUpdateAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData, "appMemberCode");
        const error = {};

        params.appMemberCodeDtoList = [];

        params.appMemberCode.forEach(code => {
            params.appMemberCodeDtoList = [...params.appMemberCodeDtoList, {appMemberCode: code}];
        })

        if(!params.groupName) {
            error.groupName = "그룹 이름은 필수 값입니다.";
            return error;
        } else if(params.appMemberCodeDtoList.length === 0) {
            error.appMemberCodeDtoList = "그룹에 할당할 회원을 지정해주세요.";
            return error;
        }

        const response = await queryClient.fetchQuery(groupUpdateQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_GROUP_PATH}/${response.data}`);
        } else {
            return null;
        }
    }

const GroupUpdate = () => {
    const navigator = useNavigate();
    const { params } = useLoaderData();
    const errors = useActionData();
    const [groupList, groupRead] = useQueries({
        queries: [allGroupListForCreateOrUpdateQuery(), groupReadQuery(params)]
    })

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">그룹 수정</h2>
                <Form method={POST_METHOD}>
                    <input type='hidden' name='groupCode' value={groupRead.data.data.groupCode}/>
                    <div className="formWrap">
                        <Input 
                            label={"그룹 이름"}
                            name={"groupName"}
                            placeholder={"그룹 이름을 작성해주세요"}
                            error={errors?.groupName}
                            defaultValue={groupRead.data.data.groupName}
                            required/>
                    
                        <GroupAndMemberSelect 
                            data={groupList.data.data}
                            memberInputName={"appMemberCode"}
                            selectData={groupRead.data.data.appMemberInGroupList}
                            currGroupCode={params.id}
                            error={errors?.appMemberCodeDtoList}/>
                
                        <div className='btnWrap justify-content-end'>  
                            <div className='off'>
                                <Button content={"취소"} onClick={(e) => {e.preventDefault(); navigator(-1)}}/>
                            </div>
                            <Button content={"그룹 수정"}/>
                        </div>
                    </div>
                </Form>
            </div>


        </div>
    )
}

export default GroupUpdate