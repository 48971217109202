import { adminDefine } from "./adminDefine";
import { appDefine } from "./appDefine";
import { boardDefine } from "./boardDefine";
import { calendarDefine } from "./calendarDefine";
import { companyDefine } from "./companyDefine";
import { dashboardDefine } from "./dashboardDefine";
import { fileDefine } from "./fileDefine";
import { groupDefine } from "./groupDefine";
import { inquiryDefine } from "./inquiryDefine";
import { loginDefine } from "./loginDefine";
import { memberDefine } from "./memberDefine";
import { noticeDefine } from "./noticeDefine";
import { userBoardDefine } from "./userBoardDefine";

export const define = {
    ...dashboardDefine,
    ...loginDefine,
    ...memberDefine,
    ...boardDefine,
    ...fileDefine,
    ...groupDefine,
    ...noticeDefine,
    ...inquiryDefine,
    ...companyDefine,
    ...adminDefine,
    ...appDefine,
    ...userBoardDefine,
    ...calendarDefine,
}