import React, { useEffect } from 'react'
import { useNavigate, useLoaderData, useSearchParams, useLocation } from 'react-router-dom'
import SearchAndDelete from '../../../../components/searchAndDelete';
import Pagination from '../../../../atom/pagination';
import { userBoardListQuery } from '../../../../../queries/userBoardQueries';
import { PAGE_PER_SIZE } from '../../../../../const/const';
import { useQuery } from '@tanstack/react-query';

export const userBoardLoader =
    (queryClient) => async({request, params}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
        
        const queryParam = {
            page: page,
            size: size,
            menuCode: params.userBoardId,
        }

        if(!queryClient.getQueryData(userBoardListQuery(queryParam).queryKey)) {
            (await queryClient.fetchQuery(userBoardListQuery(queryParam)));
        }

        return { params, queryParam }; 
    }

const UserBoard = () => {
    const navigator = useNavigate();
    const { state } = useLocation();
    const { params, queryParam } = useLoaderData();
    const { data: { data } } = useQuery(userBoardListQuery(queryParam, params));
    const [, setSearchParams ] = useSearchParams();

    const onChangeHandle = (page) => {
        setSearchParams({page: page.selected});
    }

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit"><span>{state.name}</span> 게시판</h2>

                <SearchAndDelete
                    searchName={"keyword"}
                    searchTypeName={"keywordType"}>
                    <option value="all">전체</option>
                    <option value="title">제목</option>
                    <option value="writedate">작성일</option>
                    <option value="uploaddate">게시일</option>
                    <option value="writer">작성자</option>
                </SearchAndDelete>

                <div className="tableWrap">
                <table className="table table-hover boardCheck">
                    <thead>
                        <tr>
                            {/* <th scope="col">
                                <label htmlFor="selectAll" className="chk_box">
                                    <input type="checkbox" id="selectAll" name="selectAll" className="checkAll" />
                                    <span className="checkon"></span>
                                </label>
                            </th> */}
                            <th scope="col">#</th>
                            <th scope="col">제목</th>
                            <th scope="col">작성일</th>
                            <th scope="col">게시일</th>
                            <th scope="col">작성자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.communityMainSimpleList.length !== 0 ?
                            data.communityMainSimpleList.map(com => (
                                <tr onClick={() => navigator(com.code, {state: {...com, name: state.name}})} key={com.code}>
                                    {/* <td>
                                        <label htmlFor="board9" className="chk_box">
                                            <input type="checkbox" id="board9" />
                                            <span className="checkon"></span>
                                        </label>
                                    </td> */}
                                    <td>9</td>
                                    <td>{com.title}</td>
                                    <td>{com.createAt}</td>
                                    <td>yyyy.mm.dd</td>
                                    <td>김철수 관리자</td>
                                </tr>
                            )) : <tr><td colSpan={5}><div className='nodata'><p>등록된 게시물이 없습니다</p></div></td></tr>
                        }
                    </tbody>
                </table>
                </div>

                <Pagination
                    initialPage={Number(queryParam.page)}
                    onChange={onChangeHandle}
                    totalCount={data.totalCount}/>

                {/* <Button content={"게시판 글쓰기"}/> */}

            </div>
        </div>
    )
}

export default UserBoard