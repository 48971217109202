import React, { useEffect, useState } from 'react'
import Button from '../../../atom/button'
import Select from "react-select"
import { memberReadQuery, memberUpdateQuery } from '../../../../queries/memberQueries'
import { Form, useLoaderData, redirect, useActionData, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { statusOptions } from '../../../../const/data'
import { getDefaultValueInStatusOptions, makeParamsFromFormData } from '../../../../util/util'
import { PUT_METHOD } from '../../../../const/const'
import { CONTENT_MEMBER_LIST_PATH, CONTENT_PATH } from '../../../../const/path'
import Input from '../../../atom/input'

const colourStyles = {
    control: (baseStyles, state) => ({ //current
        ...baseStyles,
        border: state.isFocused  ? '1px solid #516be0' : '1px solid #C4C4C4',
        boxShadow: '#516be0',
        backgroundColor:'none',
        outline: state.isFocused ? '3px solid #e4e6ff' : "#fff" ,
        color: "#333",
        width:"350px"
    }),
    option: (baseStyles, state) => { //option
        return {
        ...baseStyles,
        backgroundColor: state.isFocused ? '#e4e6ff' : null,
        color: "#333333",
        }
    },
    singleValue: (baseStyles, state)  => ({ 
        ...baseStyles,
        color: "#333" //current color
    }),
}

export const memberUpdateAction =
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {};

        const response = await queryClient.fetchQuery(memberUpdateQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${CONTENT_PATH}/${CONTENT_MEMBER_LIST_PATH}/${response.data}`);
        } else {
            errors.error = response.message;
            return errors;
        }
    }

const MemberUpdate = () => { 
    const navigator = useNavigate();
    const { params } = useLoaderData();
    const errors = useActionData();
    const { data: { data } } = useQuery(memberReadQuery(params));
    const [isOpenDate, setIsOpenDate] = useState(false);

    const onChangeStatus = ({value}) => {
        if(value !== "YELLOW") setIsOpenDate(false);
        else setIsOpenDate(true);
    }

    useEffect(() => {
        if(data.appMemberStatus === "YELLOW") setIsOpenDate(true);
    }, [data])

    return (
        <div className="main memberWrap">
            <h2 className="subTit"><span>{data.appMemberName}</span> 회원 상세</h2>
            <Form method={PUT_METHOD}>
                <input type='hidden' name='appMemberCode' value={data.appMemberCode}/>
                {/* <input type='hidden' name='appMemberStatusContents' value={null}/> */}
                <div className="d-flex">
                    <div className="contentBg">
                        <h3>회원정보</h3>
                        <div className="tableWrap3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>이름</th>
                                        <td>{ data.appMemberName }</td>
                                    </tr>
                                    <tr>
                                        <th>아이디</th>
                                        <td>{ data.appMemberEmail }</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td>{ data.appMemberPhone }</td>
                                    </tr>
                                    <tr>
                                        <th>소속 그룹</th>
                                        <td className='memberGroup'>
                                            { 
                                                data.appMemberGroupList ? 
                                                    data.appMemberGroupList.map((group, idx) => 
                                                    <div key={idx}>{group.groupName}{idx}{data.appMemberGroupList.length === idx+1 ? "" : ""}</div>
                                                    ) : "-"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>가입일</th>
                                        <td>{ data.createAt }</td>
                                    </tr>
                                    {/* <tr>
                                        <th>등급</th>
                                        <td><Select options={options2} styles={colourStyles}/></td>
                                    </tr> */}
                                    <tr>
                                        <th>상태</th>
                                        <td className='stausTd'>
                                            <Select 
                                                name='status'
                                                options={statusOptions} 
                                                styles={colourStyles} 
                                                defaultValue={getDefaultValueInStatusOptions(data.appMemberStatus)}
                                                onChange={onChangeStatus}/>
                                            <Input type={"hidden"} error={errors?.error}/>
                                        </td>
                                    </tr>
                                    {/* 상태가 활동 정지기한일때만 보일수 있게 */}
                                    {
                                        isOpenDate ? 
                                        <tr>
                                            <th>활동정지 기한</th>
                                            <td>
                                                <div>
                                                    <input type="date" name='appMemberBanStartDate' defaultValue={data.banStartDate}/> 
                                                    ~ 
                                                    <input type="date" name='appMemberBanEndDate' defaultValue={data.banEndDate}/>
                                                </div>
                                            </td>
                                        </tr> : ""
                                    }
                                    <tr>
                                        <th>메모</th>
                                        <td></td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        
                        <div className='btnWrap justify-content-end'>  
                            <div className='off'>
                                <Button content={"취소"} onClick={(e) => {e.preventDefault(); navigator(-1)}}/>
                            </div>
                            <Button content={"회원 정보 수정"}/>
                        </div>
                    </div>   

                    {/* <div className="contentBg">
                    <h3>문의 내역</h3>
                        <div className="tableWrap3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="tableshort">#</th>
                                        <th>제목</th>
                                        <th>등록일</th>
                                        <th>상태</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tableshort">1</td>
                                        <td>문의내용입니다</td>
                                        <td>yyyy.mm.dd</td>
                                        <td><p className="status_gray">미답변</p></td>
                                    </tr>
                                    <tr>
                                        <td className="tableshort">2</td>
                                        <td>문의내용입니다</td>
                                        <td>yyyy.mm.dd</td>
                                        <td><p className="status_gray">미답변</p></td>
                                    </tr>
                                    <tr>
                                        <td className="tableshort">3</td>
                                        <td>문의내용입니다</td>
                                        <td>yyyy.mm.dd</td>
                                        <td><p className="status_gray">미답변</p></td>
                                    </tr>
                                    <tr>
                                        <td className="tableshort">4</td>
                                        <td>문의내용입니다</td>
                                        <td>yyyy.mm.dd</td>
                                        <td><p className="status_gray">미답변</p></td>
                                    </tr>
                                    <tr>
                                        <td className="tableshort">5</td>
                                        <td>문의내용입니다</td>
                                        <td>yyyy.mm.dd</td>
                                        <td><p className="status_green">답변</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        <Pagination/>
                        </div>
                    </div>    */}
                </div>
            </Form>
        </div>
    )
}

export default MemberUpdate