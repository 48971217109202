/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import LineChart from './playground/lineChart'
import { dashboardQuery } from '../../queries/dashboardQueries';
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { CONTENT_INQUIRY_LIST_PATH, CONTENT_PATH } from '../../const/path';

export const dashBoardLoader = 
  (queryClient) => async() => {
    let data = {}
    if(!queryClient.getQueryData(dashboardQuery().queryKey)) {
      data = await await queryClient.fetchQuery(dashboardQuery());
    } else {
      data = queryClient.getQueryData(dashboardQuery().queryKey);
    }
    // 요일별 가입자
    const weekData = {
      xAxisCategory: [],
      data: [],
    };
    
    if(data.data.weekList) {
      const xAxisCategory = [];
      const seriesData = [];
      data.data.weekList.forEach(week => {
        xAxisCategory.push(`${week.dayOfWeek}`);
        seriesData.push(week.appMemberCount);
      })

      weekData.xAxisCategory = xAxisCategory;
      weekData.data = [{name: "앱가입자수", data: seriesData}];
    }

    //////////////////////

    const qnaList = data.data.qnaPage.qnaList;
    const appMemberCountList = data.data.appMemberCountList;
    return { weekData, qnaList, appMemberCountList };
  }

const Dashboard = () => {
  const navigator = useNavigate();
  const { weekData, qnaList, appMemberCountList } = useLoaderData();

  return (
    <div className="main">
      <h2 className="subTit">대시보드</h2>
      <div className="dashboardWrap">
        <div className="contentBg">
          {/* <h3>앱 가입자 현황</h3> */}
          <LineChart
            title={"이번주 앱 가입자 현황"}
            yTitle={"명"}
            xAxisCategories={weekData.xAxisCategory}
            data={weekData.data}/>
            <div className='d-flex justify-content-center total'>
              <div className='d-flex'>
                <h3>전체회원 수</h3>
                <p><span>{appMemberCountList[0].appMemberCount}</span>명</p>
              </div>
              <div className='d-flex'>
                <h3>정회원 수</h3>
                <p><span>{appMemberCountList[2].appMemberCount}</span>명</p>
              </div>
              <div className='d-flex'>
                <h3>준회원 수</h3>
                <p><span>{appMemberCountList[1].appMemberCount}</span>명</p>
              </div>
            </div>
          {/* <div className="vertical_chart_box">
          <div className='d-flex align-content-center justify-content-between '>
            <h3>이번주 앱 가입자 현황</h3>
            <div className='d-flex total'>
              <div className='d-flex'>
                <h3>전체회원</h3>
                <p><span>1,000</span>명</p>
              </div>
              <div className='d-flex'>
                <h3>정회원</h3>
                <p><span>800</span>명</p>
              </div>
              <div className='d-flex'>
                <h3>준회원</h3>
                <p><span>200</span>명</p>
              </div>
            </div>
          </div>
          <div className="vertical_chart_box">
            <div className="chart_box">
              <ul className="axis_y">
                <li className="item">0<span className="blind">명</span></li>
                <li className="item">5<span className="blind">명</span></li>
                <li className="item">10<span className="blind">명</span></li>
                <li className="item">15<span className="blind">명</span></li>
                <li className="item">20<span className="blind">명</span></li>
              </ul>
              <ul className="axis_x">
                <li className="item">
                  <div className="text_box">
                    <strong className="day">02.05</strong>
                    <span className="time">월</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:10+"%"}}><span className="number">10명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day">02.06</strong>
                    <span className="time">화</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:40+"%"}}><span className="number">40명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day">02.07</strong>
                    <span className="time">수</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:20+"%"}}><span className="number">20명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day">02.08</strong>
                    <span className="time">목</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:100+"%"}}><span className="number">100명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day">02.09</strong>
                    <span className="time">금</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:40+"%"}}><span className="number">40명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day sat">02.10</strong>
                    <span className="time">토</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:20+"%"}}><span className="number">20명</span></span>
                  </button>
                </li>
                <li className="item">
                  <div className="text_box">
                    <strong className="day sun">02.11</strong>
                    <span className="time">일</span>
                  </div>
                  <button type="button" className="graph">
                    <span className="time data" style={{height:90+"%"}}><span className="number">90명</span></span>
                  </button>
                </li>
              </ul>
            </div>                                                 
          </div> */}
        </div>
        {/* <div className="contentBg">
          <h3>최근 활동</h3>
          <div className="history">
            <div>
              <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
              <ol>
                <li>공지사항 '<span>[세미나]오픈 공지내용 입니다</span>' 작성</li>
                <li>문의글 '<span>문의드립니다.</span>' 답변</li>
               </ol>
            </div>
         
            <div>
              <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
              <ol>
                <li>일정 '<span>추수감사절 예배</span>' 설정</li>
              </ol>
            </div>
         
            <div>
              <h2>yyyy.mm.dd hh:mm <span>000 관리자</span></h2>
              <ol>
                <li>앱 수정</li>
              </ol>
            </div>
         
         </div>
        </div> */}

        {/* 최근 문의 5건 정도만 보여주고 더보기 클릭시 문의 게시판으로 이동 */}
        <div className="contentBg">
          <div className='d-flex align-content-center justify-content-between'>
            <h3>최근 1:1 문의</h3>
            <Link to={`${CONTENT_PATH}/${CONTENT_INQUIRY_LIST_PATH}`}><button className='link'>더보기</button></Link>
          </div>
          <div className="tableWrap3">
            <table className="table">
              <thead>
                <tr>
                  <th className="tableshort">#</th>
                  <th className="tablelong">제목</th>
                  <th>문의자</th>
                  <th>문의일</th>
                  <th>답변자</th>
                  <th>답변일</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                {
                  qnaList.length !== 0 ?
                    qnaList.map((qna) => (
                      <tr onClick={() => navigator(`${CONTENT_PATH}/${CONTENT_INQUIRY_LIST_PATH}/${qna.qnaCode}`)} key={qna.qnaCode}>
                        <td className="tableshort">{qna.sequence}</td>
                        <td className="tablelong">{qna.title}</td>
                        <td>{qna.name}</td>
                        <td>{qna.createAt}</td>
                        <td>{qna.replyName}</td>
                        <td>{qna.replyCreateAt}</td>
                        <td>
                          <p className={qna.answer ? "status_green" : "status_gray"}>
                            {qna.answer ? "답변" : "미답변"}
                          </p>
                        </td>
                      </tr>
                    )) : <td colSpan={7}><div className='nodata'><p>등록된 문의사항이 없습니다</p></div></td>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard