/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react'
import { useQuery } from "@tanstack/react-query"
import { boardListQuery, boardMenuChangeQuery } from '../../../../queries/boardQueries'
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'
import { ItemTypes } from '../../playground/ItemTypes'
import Button from '../../../atom/button'
import { SETTING_BOARD_PATH, SETTING_PATH } from '../../../../const/path'
import { useSubmit, redirect } from 'react-router-dom'
import { POST_METHOD } from '../../../../const/const'

export const boardChangeMenuAction =
    (queryClient) => async({request}) => {
        const params = await request.json();
        const error = {};
        
        const response = await queryClient.fetchQuery(boardMenuChangeQuery(params));

        if(response.result === "SUCCESS") {
            return redirect(`${SETTING_PATH}/${SETTING_BOARD_PATH}`);
        }

        return {error};
    }

export const Card = ({ id, content, index, moveCard }) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            
            if (dragIndex === hoverIndex) {
                return
            }
            
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            
            moveCard(dragIndex, hoverIndex)
            item.index = hoverIndex

        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0.5 : 1
    drag(drop(ref))
    return (
        <div ref={ref} className="boardlayer" style={{opacity}} data-handler-id={handlerId} key={index}>
            <h4>{content.name}</h4>
            <div className="d-flex">
                {/* <input type='hidden' value={content} name="updateRequests"/>   */}
            </div>
        </div>
    )
  }
    
const BoardChangeMenu = () => {
    const { data: {data: contentInfo} } = useQuery(boardListQuery());
    const [cards, setCards] = useState(contentInfo);
    const submit = useSubmit();

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) =>
            update(prevCards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevCards[dragIndex]],
            ],
            }),
        )
    }, []);

    const renderCard = useCallback((card, index) => {
        return (
            <Card
            key={index}
            index={index}
            id={card.id}
            content={card}
            moveCard={moveCard}
            />
        )
    }, [])

    return (
        <div className="main">
            <h2 className="subTit">게시판 관리</h2>
            <div className="phoneBg">
                <div className="d-flex justify-content-between ">
                    <div>
                        <h3>화면 미리보기</h3>
                        <p>드래그앤 드롭으로 게시판의 순서를 변경하실 수 있습니다.<br/>순서를 변경하고 싶은 게시판을 클릭하여 원하는 위치에 배치해주세요.</p>
                    </div>
                    <div className="btnWrap">
                        <div className='off'>
                            <Button content={"취소"} />
                        </div>
                        <div>
                        <Button content={"게시판 순서 수정"} onClick={() => submit({updateRequests: cards}, {method: POST_METHOD, encType: "application/json"})}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="phone">
                        <div className="phoneFrame">
                            <div className="phoneCon">
                                <div className="topbar d-flex justify-content-between align-items-center">
                                    <div>12:30</div>
                                    <div><img src={`${process.env.PUBLIC_URL + "/img/System Status.png"}`} alt=""/></div>
                                </div>
                                <div className="phonemain">
                                    <div className="notice">
                                    <h4>공지사항</h4>
                                    <p>공지사항 슬라이드가 들어가는 곳입니다. 가장 최근 공지글을 보여줍니다.</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {cards.map((card, i) => renderCard(card, i))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoardChangeMenu