import { POST_METHOD } from "../../const/const";
import { LOGIN_DEFINE, LOGOUT_DEFINE } from "../../const/defineName";
import { LOGIN_URL, LOGOUT_URL } from "../../const/url";

export const loginDefine = {
    [LOGIN_DEFINE]: {
        method: POST_METHOD,
        url: LOGIN_URL,
    },
    [LOGOUT_DEFINE]: {
        method: POST_METHOD,
        url: LOGOUT_URL,
    }
}