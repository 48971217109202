import { Link } from "react-router-dom";
import PersonalGroup from "../atom/personalGroup";
import { CONTENT_MEMBER_LIST_PATH, CONTENT_PATH } from "../../const/path";

const GroupMemberOnlyView = ({data}) => {
    return (
        <div> 
            {/* <GroupSearch/> */}

            <div className="groupConWrap">
                <div>
                    <h4>그룹 회원</h4>
                    <div className="groupConList">
                        {
                            data.appMemberInGroupList.map(member => (
                                <Link to={`${CONTENT_PATH}/${CONTENT_MEMBER_LIST_PATH}/${member.appMemberCode}`}>
                                    <PersonalGroup
                                        key={member.appMemberCode}
                                        memberName={member.appMemberName} 
                                        memberId={member.appMemberEmail}
                                        disabled={true}
                                        defaultChecked={true}/>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupMemberOnlyView