import { NOTICE_CREATE_DEFINE, NOTICE_DELETE_DEFINE, NOTICE_LIST_DEFINE, NOTICE_READ_DEFINE, NOTICE_UPDATE_DEFINE } from "../const/defineName"
import { fetchData, fetchDataPathVar } from "../util/util"

export const noticeListQuery = (params) => ({
    queryKey: ["noticeList", params.page],
    queryFn: async() => {
        const json = await fetchDataPathVar(NOTICE_LIST_DEFINE, {}, params);
        return json;
    }
})

export const noticeReadQuery = (params) => ({
    queryKey: ["noticeRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(NOTICE_READ_DEFINE, params);
        return json;
    }
})

export const noticeCreateQuery = (params) => ({
    queryKey: ["noticeCreate"],
    queryFn: async() => {
        const json = await fetchData(NOTICE_CREATE_DEFINE, params);
        return json;
    }
})

export const noticeUpdateQuery = (params) => ({
    queryKey: ["noticeUpdate"],
    queryFn: async() => {
        const json = await fetchData(NOTICE_UPDATE_DEFINE, params);
        return json;
    }
})

export const noticeDeleteQuery = (params) => ({
    queryKey: ["noticeDelete"],
    queryFn: async() => {
        const json = await fetchData(NOTICE_DELETE_DEFINE, params);
        return json;
    }
})