import React, { useLayoutEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EDITOR_FILE_UPLOAD_URL } from '../../const/defineName';
import { uploadFileEditor } from '../../util/util';

const Editor = ({ name, defaultValue, error }) => {
    const [data, setData] = useState(defaultValue ?? "");

    const customUploadAdapter = (loader) => {
        return {
            upload() {
                return new Promise((resolve, reject) => {
                    const data = new FormData();

                    loader.file.then(async (file) => {
                        data.append("upload", file);
                        try {
                            const url = await uploadFileEditor(EDITOR_FILE_UPLOAD_URL, data);                            
                            resolve({
                                default: url
                            });
                        } catch(e) {
                            reject(e);
                        }
                    })
                })
            }
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        }
    }

    const ref = useRef();

    useLayoutEffect(() => {
        if(error) {
            ref.current.style.scrollMargin = "90px";
            ref.current.scrollIntoView();
        }
    }, [error])

    return (
        <>
            <div ref={ref}></div>
            <input type='hidden' name={name} value={data}/>
            <CKEditor
                editor={ ClassicEditor }
                config={{
                    extraPlugins: [uploadPlugin],
                }}
                data={data}
                id={"formFileMultiple"}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const value = editor.getData();
                    setData(value);
                    console.log(value);
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor );
                } }
            />
            {error ? <p className="warning">{error}</p> : ""}
        </>
    )
}

export default Editor