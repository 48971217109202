import React from 'react'
import Button from '../../../atom/button'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { groupReadQuery } from '../../../../queries/groupQueries'
import { useQuery } from "@tanstack/react-query"
import GroupMemberOnlyView from '../../../components/groupMemberOnlyView'
import { UPDATE_PATH } from '../../../../const/path'

export const groupReadLoader = 
  (queryClient) => async({params}) => {
    if(!queryClient.getQueryData(groupReadQuery(params).queryKey)) {
      await queryClient.fetchQuery(groupReadQuery(params));
    }
    return { params };
  }

const GroupRead = () => {
  const navigator = useNavigate();
  const { params } = useLoaderData();
  const { data: {data} } = useQuery(groupReadQuery(params));

  return (
    <div className="main">
      <div className="contentBg">
        <h2 className="subTit"><span>{data.groupName}</span> 그룹 상세</h2>
        
        <div className="formWrap">
          <div className="mb-4 d-grid">
            <label htmlFor="groupCon" className="form-label">그룹 이름</label>
            <div>{data.groupName}</div>
          </div>

          <div className="mb-4 d-grid">
            <label htmlFor="groupCon" className="form-label">그룹 회원관리</label>
            <GroupMemberOnlyView data={data}/>
          </div>
          
          <Button content={"그룹 수정"} onClick={() => {navigator(UPDATE_PATH)}}/>

        </div>

      </div>


    </div>
  )
}

export default GroupRead