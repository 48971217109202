import { GET_METHOD, POST_METHOD } from "../../const/const";
import { USER_BOARD_BLIND_CONTENT_DEFINE, USER_BOARD_BLIND_REPLY_DEFINE, USER_BOARD_LIST_DEFINE, USER_BOARD_READ_DEFINE, USER_BOARD_UNBLIND_CONTENT_DEFINE, USER_BOARD_UNBLIND_REPLY_DEFINE } from "../../const/defineName";
import { USER_BOARD_BLIND_CONTENT_URL, USER_BOARD_BLIND_REPLY_URL, USER_BOARD_LIST_URL, USER_BOARD_READ_URL, USER_BOARD_UNBLIND_CONTENT_URL, USER_BOARD_UNBLIND_REPLY_URL } from "../../const/url";

export const userBoardDefine = {
    [USER_BOARD_LIST_DEFINE]: {
        method: GET_METHOD,
        url: USER_BOARD_LIST_URL,
    },
    [USER_BOARD_READ_DEFINE]: {
        method: GET_METHOD,
        url: USER_BOARD_READ_URL,
    },
    [USER_BOARD_BLIND_CONTENT_DEFINE]: {
        method: POST_METHOD,
        url: USER_BOARD_BLIND_CONTENT_URL,
    },
    [USER_BOARD_UNBLIND_CONTENT_DEFINE]: {
        method: POST_METHOD,
        url: USER_BOARD_UNBLIND_CONTENT_URL,
    },
    [USER_BOARD_BLIND_REPLY_DEFINE]: {
        method: POST_METHOD,
        url: USER_BOARD_BLIND_REPLY_URL,
    },
    [USER_BOARD_UNBLIND_REPLY_DEFINE]: {
        method: POST_METHOD,
        url: USER_BOARD_UNBLIND_REPLY_URL,
    }
}