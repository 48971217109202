import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { NOTICE_CREATE_DEFINE, NOTICE_DELETE_DEFINE, NOTICE_LIST_DEFINE, NOTICE_READ_DEFINE, NOTICE_UPDATE_DEFINE } from "../../const/defineName";
import { NOTICE_DELETE_URL, NOTICE_LIST_URL } from "../../const/url";

export const noticeDefine = {
    [NOTICE_LIST_DEFINE] : {
        method: GET_METHOD,
        url: NOTICE_LIST_URL
    },
    [NOTICE_CREATE_DEFINE] : {
        method: POST_METHOD,
        url: NOTICE_LIST_URL
    },
    [NOTICE_READ_DEFINE] : {
        method: GET_METHOD,
        url: NOTICE_LIST_URL
    },
    [NOTICE_UPDATE_DEFINE] : {
        method: PUT_METHOD,
        url: NOTICE_LIST_URL
    },
    [NOTICE_DELETE_DEFINE] : {
        method: DELETE_METHOD,
        url: NOTICE_DELETE_URL
    }
}