import React from 'react'

const PersonalGroup = ({memberName, memberId, name, onClick, disabled, defaultChecked}) => {
  return (
    <label htmlFor={memberId} className="chk_box">
        <input 
          type="checkbox" 
          id={memberId} 
          name={name}
          disabled={disabled}
          defaultChecked={defaultChecked ?? false}
          onClick={onClick}/>
        <span className="checkon"></span>
        <b> {memberName} <span>{memberId}</span></b>
    </label>
  )
}

export default PersonalGroup