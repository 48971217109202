import React, { useEffect } from 'react'
import { useFetcher } from 'react-router-dom'
import { POST_METHOD } from '../../../const/const'
import Input from '../../atom/input';
import ToggleBtn from '../../atom/toggleBtn';

const CalendarCreatePopup = ({start, end, onCancle, onSubmit}) => {
    const fetcher = useFetcher("create-fetcher");

    useEffect(() => {
        if(fetcher?.data?.params) {
            onSubmit();
        }
    }, [fetcher, onSubmit])

    return (
        <fetcher.Form method={POST_METHOD}>
            <div className="modal fade" id="eventModal" tabIndex="-1" aria-labelledby="eventModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-header d-flex justify-content-between align-items-center">
                            <h5 className="modal-title" id="eventModalLabel">새 일정</h5>
                            <button className='closeBtn' onClick={onCancle}></button>
                        </div>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                {/* <label htmlFor="inputTitle" className="form-label">일정제목</label> */}
                                <Input type="text" name='title' id="inputTitle" label={"일정제목"} error={fetcher?.data?.error?.title} required/>
                            </div>
                            <div className="mb-3">
                                {/* <label htmlFor="inputTitle" className="form-label">메모</label> */}
                                <Input type="text" name='content' id="inputTitle" label={"메모"} error={fetcher?.data?.error?.content} required/>
                            </div>
                            <input type='hidden' name='start' defaultValue={start}/>
                            <input type='hidden' name='end' defaultValue={end}/>
                            <input type='hidden' name='type' defaultValue={"create"}/>
                            <div className='mb-3'>
                                <ToggleBtn
                                    name={"notification"}
                                    value={true} 
                                    description={"알림전송"}/>
                            </div>
                        </div>
                        <div className="btnwrap d-flex justify-content-between">
                            <button onClick={onCancle}>취소</button>
                            <button className="yes">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </fetcher.Form>
    )
}

export default CalendarCreatePopup