import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { COMPANY_CREATE_DEFINE, COMPANY_DELETE_DEFINE, COMPANY_LIST_DEFINE, COMPANY_READ_DEFINE, COMPANY_UPDATE_DEFINE } from "../../const/defineName";
import { COMPANY_CREATE_URL, COMPANY_DELETE_URL, COMPANY_LIST_URL, COMPANY_READ_URL, COMPANY_UPDATE_URL } from "../../const/url";

export const companyDefine = {
    [COMPANY_LIST_DEFINE] :{
        method: GET_METHOD,
        url : COMPANY_LIST_URL,
    },
    [COMPANY_READ_DEFINE] :{
        method: GET_METHOD,
        url : COMPANY_READ_URL,
    },
    [COMPANY_CREATE_DEFINE] :{
        method: POST_METHOD,
        url : COMPANY_CREATE_URL,
    },
    [COMPANY_UPDATE_DEFINE] :{
        method: PUT_METHOD,
        url : COMPANY_UPDATE_URL,
    },
    [COMPANY_DELETE_DEFINE] :{
        method: DELETE_METHOD,
        url : COMPANY_DELETE_URL,
    },
}