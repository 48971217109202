export const DASHBOARD_URL = "/api/manager/dashboard";

export const LOGIN_URL = "/api/manager/login";
export const LOGOUT_URL = "/api/manager/logout";

export const MEMBER_LIST_URL = "/api/manager/list";
export const MEMBER_READ_URL = "/api/manager/list";
export const MEMBER_UPDATE_URL = "/api/manager/list";
export const MEMBER_CHANGE_GRADE_URL = "/api/manager/member/role";

export const BOARD_URL = "/content/v2/partner/menu";
export const BOARD_CONTENT_TYPE_URL = "/content/type";
export const BOARD_ICON_URL = "/content/v2/partner/menu/img";
export const BOARD_DETAIL_URL = "/content/v2/partner/menu/detail";
export const BOARD_UPDATE_URL = "/content/v2/partner/menu";
export const BOARD_CHANGE_MENU_URL = "/content/v2/partner/menu/sequence";

export const USER_BOARD_LIST_URL = "/content/v2/partner/community";
export const USER_BOARD_READ_URL = "/content/v2/partner/community/detail";

export const USER_BOARD_BLIND_CONTENT_URL = "/content/v2/partner/community/blind";
export const USER_BOARD_UNBLIND_CONTENT_URL = "/content/v2/partner/community/unblind";
export const USER_BOARD_BLIND_REPLY_URL = "/content/v2/partner/community/reply/blind";
export const USER_BOARD_UNBLIND_REPLY_URL = "/content/v2/partner/community/reply/unblind";

export const GROUP_LIST_URL = "/api/manager/group/list";
export const GROUP_READ_URL = "/api/manager/group";
export const GROUP_UPDATE_URL = "/api/manager/group";
export const GROUP_URL = "/api/manager/group";
export const GROUP_CREATE_URL = "/api/manager/group";

export const NOTICE_LIST_URL = "/app-board/manager/notice";
export const NOTICE_READ_URL = "/app-board/manager/notice";
export const NOTICE_CREATE_URL = "/app-board/manager/notice";
export const NOTICE_UPDATE_URL = "/app-board/manager/notice";
export const NOTICE_DELETE_URL = "/app-board/manager/notice";

export const INQUIRY_LIST_URL = "/app-board/manager/qna";
export const INQUIRY_READ_URL = "/app-board/manager/qna";

export const REPLY_CREATE_URL = "/app-board/manager/qna/reply"
export const REPLY_UPDATE_URL = "/app-board/manager/qna/reply"
export const REPLY_DELETE_URL = "/app-board/manager/qna/reply"

export const COMPANY_LIST_URL = "/api/manager/setting/company"
export const COMPANY_READ_URL = "/api/manager/setting/company"
export const COMPANY_CREATE_URL = "/api/manager/setting/company"
export const COMPANY_UPDATE_URL = "/api/manager/setting/company"
export const COMPANY_DELETE_URL = "/api/manager/setting/company"

export const ADMIN_LIST_URL = "/api/manager/member";
export const ADMIN_READ_URL = "/api/manager/info";
export const MY_INFO_URL = "/api/manager/info";
export const ADMIN_UPDATE_URL = "/api/manager/member";
export const ADMIN_CREATE_URL = "/api/manager/member/selection";
export const ADMIN_DELETE_URL = "/api/manager/member/selection";
export const ADMIN_SEARCH_URL = "/api/manager/member/selection";

export const APP_DETAIL_URL = "/api/manager";
export const APP_UPDATE_URL = "/api/manager/setting/update";

export const CALENDAR_CREATE_URL = "/content/v2/partner/schedule";
export const CALENDAR_LIST_URL = "/content/v2/partner/schedule";
export const CALENDAR_DELETE_URL = "/content/v2/partner/schedule";
export const CALENDAR_UPDATE_URL = "/content/v2/partner/schedule";