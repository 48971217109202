import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from "@tanstack/react-query"
import { boardListQuery } from '../../../../queries/boardQueries'
import Button from '../../../atom/button'
import { CHANGE_PATH, CREATE_PATH, SETTING_USER_BOARD_PATH } from '../../../../const/path'

export const boardLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(boardListQuery().queryKey)) {
            return (await queryClient.fetchQuery(boardListQuery()));
        }
        return {params}
    }
    
const Board = () => {
    const navigator = useNavigate();
    const { data: { data: contentInfo } } = useQuery(boardListQuery());

    return (
        <div className="main">
            <h2 className="subTit">게시판 관리</h2>
            <div className="phoneBg">
                <div className="d-flex justify-content-between ">
                    <div>
                        <h3>화면 미리보기</h3>
                        <p>드래그앤 드롭으로 게시판의 순서를 변경하실 수 있습니다.<br/>순서를 변경하고 싶은 경우 오른쪽상단 게시판 순서변경을 통해 변경해주세요.</p>
                    </div>
                    {/* <Button content={"게시판 생성"} onClick={() => {navigator(CREATE_PATH)}}/> */}
                    <div className="btnWrap">
                        <div>
                            <Button content={"게시판 생성"} onClick={() => {navigator(CREATE_PATH)}}/>
                        </div>
                        <div>
                            <Button content={"게시판 순서 변경"} onClick={() => {navigator(CHANGE_PATH)}}/>
                        </div>
                        {/* <button>게시판 생성</button> */}
                    </div>
                </div>
                <div className='d-flex boardCon  justify-content-between'>
                <div className="d-flex justify-content-between">
                    <div className="phone">
                        <div className="phoneFrame">
                            <div className="phoneCon">
                                <div className="topbar d-flex justify-content-between align-items-center">
                                    <div>12:30</div>
                                    <div><img src={`${process.env.PUBLIC_URL + "/img/System Status.png"}`} alt=""/></div>
                                </div>
                                <div className="phonemain">
                                    <div className="notice">
                                    <h4>공지사항</h4>
                                    <p>공지사항 슬라이드가 들어가는 곳입니다. 가장 최근 공지글을 보여줍니다.</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {
                                            contentInfo.map(c => (
                                                <div className="boardlayer" key={c.code}>
                                                    <h4>{c.name}</h4>
                                                    <div className="d-flex">
                                                    <button onClick={() => {navigator(c.code)}}>게시판 관리</button>
                                                    {/* <button>글 관리</button> */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tableWrap'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th>게시판 이름</th>
                                <th>게시글 관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                contentInfo.length !== 0 ?
                                    contentInfo.map(content => (
                                        <tr key={content.code}>
                                            <td>{content.name}</td>
                                            <td><button onClick={() => navigator(`${SETTING_USER_BOARD_PATH}/${content.code}`, {state: {name: content.name}})}>게시글 관리</button></td>
                                        </tr>
                                    )) : <td colSpan={2}><div className='nodata'><p>등록된 게시판이 없습니다</p></div></td>
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Board