import React from 'react'
import Pagination from '../../../atom/pagination'
import Button from '../../../atom/button'
import { useNavigate, useLoaderData, Link } from 'react-router-dom'
import { SETTING_GROUP_PATH, SETTING_PATH, UPDATE_PATH } from '../../../../const/path'
import { memberReadQuery } from '../../../../queries/memberQueries'
import { useQuery } from '@tanstack/react-query'
import { getGradeName, getNameByStatus } from '../../../../util/util'
import { PAGE_PER_SIZE } from '../../../../const/const'

export const memberReadLoader = 
    (queryClient) => async({request, params}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;

        const queryParam = {
            page: page,
            size: size,
        }

        if(!queryClient.getQueryData(memberReadQuery(params, queryParam).queryKey)) {
            await queryClient.fetchQuery(memberReadQuery(params, queryParam));
        }

        return { params, queryParam };
    }

const MemberRead = () => {
    const { params, queryParam } = useLoaderData();
    const { data: { data } } = useQuery(memberReadQuery(params, queryParam));
    const navigator = useNavigate();

    return (
        <div className="main memberWrap">
            <h2 className="subTit"><span>{ data.appMemberName }</span> 회원 상세</h2>

            <div className="d-flex">
                <div className="contentBg">
                    <h3>회원정보</h3>
                    <div className="tableWrap3">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>이름</th>
                                    <td>{ data.appMemberName }</td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td>{ data.appMemberEmail }</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>{ data.appMemberPhone }</td>
                                </tr>
                                <tr>
                                    <th>소속 그룹</th>
                                    <td className='memberGroup'>
                                        { 
                                        data.appMemberGroupList ? 
                                            data.appMemberGroupList.map((group) => 
                                            <Link 
                                                to={`${SETTING_PATH}/${SETTING_GROUP_PATH}/${group.groupCode}`} 
                                                key={group.groupCode}>
                                                <div>
                                                    {group.groupName}
                                                </div>
                                            </Link>
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>가입앱</th>
                                    <td>교회앱</td>
                                </tr> */}
                                <tr>
                                    <th>가입일</th>
                                    <td>{ data.createAt }</td>
                                </tr>
                                <tr>
                                    <th>등급</th>
                                    <td>{getGradeName(data.appMemberRole)}</td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>{ getNameByStatus(data.appMemberStatus) }</td>
                                </tr>
                                {/* 상태가 사용정지 기한일때만 보일수 있게 */}
                                {
                                    data.banStartDate ? 
                                    <tr>
                                        <th>사용정지 기한</th>
                                        <td>{data.banStartDate} ~ {data.banEndDate}</td>
                                    </tr>
                                    : ""
                                }
                                <tr>
                                    <th>메모</th>
                                    <td></td>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <Button content={"회원 정보 수정"} onClick={() => {navigator(UPDATE_PATH)}}/>

                </div>   

                <div className="contentBg">
                <h3>문의 내역</h3>
                    <div className="tableWrap3">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="tableshort">#</th>
                                    <th>제목</th>
                                    <th>등록일</th>
                                    <th>상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.appMemberQnaPage.qnaList.length !== 0 ?
                                        data.appMemberQnaPage.qnaList.map(qna => (
                                            <tr>
                                                <td className="tableshort">1</td>
                                                <td>문의내용입니다</td>
                                                <td>yyyy.mm.dd</td>
                                                <td><p className="status_gray">미답변</p></td>
                                            </tr>
                                        )) : <td colSpan={4}><div className='nodata'><p>등록된 문의사항이 없습니다</p></div></td>
                                }
                            </tbody>
                        </table>

                    <Pagination
                        initialPage={Number(data.appMemberQnaPage.totalCount)}
                        totalCount={data.appMemberQnaPage.totalCount}
                        />

                    </div>
                </div>   
            </div>
        </div>
    )
}

export default MemberRead