import { ACCESS_TOKEN, ENABLE_STATUS, GET_METHOD } from "../const/const";
import { statusOptions } from "../const/data";
import { LOGOUT_DEFINE } from "../const/defineName";
import { define } from "../server/define/define";
import { FILE_HOST, HOST } from "../server/host";
import { LOG_LEVEL } from "./log-level";
import { Cookies } from 'react-cookie'

export const randomNumber = () => {
    return Math.floor(Math.random()
        * (100 - 10 + 1)) + 10;
};

export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

export const replaceItemAtIndex = (arr, index, newValue) => {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1, arr.length)];
}

export const removeItemAtList = (arr, index) => {
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
}

export const fetchData = async (defineName, param) => {
    const headers = {
        "Content-Type": "application/json",
    }

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `${localStorage.getItem(ACCESS_TOKEN)}`
    }

    const opt = {
        method: define[defineName].method,
        headers: headers,
        credentials: "include",
    };

    let newUrl = define[defineName].url;

    if(opt.method !== GET_METHOD) {
        opt.body = JSON.stringify(param);
    } else {
        newUrl += "?";
        for(let key in param) {
            newUrl += `${key}=${param[key]}`
        }
    }
    fn_Debug(newUrl);

    const response = await fetch(HOST + newUrl, opt);

    if(response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    if((response.status === 401 || response.status === 403) && defineName !== LOGOUT_DEFINE) {
        throw new Response(response.body, {status: response.status});
    }
    if(response.status >= 500) {
        throw new Response(response.body, {status: response.status});
    }

    const json = await response.json();

    // if(json.result === "FAIL") {
    //     throw new Response(json.message, {status: 500});
    // }

    fn_Debug(defineName);
    fn_Debug(json);
    fn_Debug(param);

    return json;
}

export const fetchDataPathVar = async (defineName, param, queryStrings) => {
    let newUrl = HOST + define[defineName].url;

    for(let value in param) {
        newUrl += `/${param[value]}`;
    }

    if(queryStrings) {
        newUrl += "?";
        for(let value in queryStrings) {
            newUrl += `${value}=${queryStrings[value]}&`
        }
    }

    const headers = {
        "Content-Type": "application/json",
    }
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `${localStorage.getItem(ACCESS_TOKEN)}`
    }

    const response = await fetch(newUrl, {
        method: define[defineName].method,
        headers: headers,
    });

    if(response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    if(response.status === 401 || response.status === 403) {
        throw new Response(response.body, {status: response.status});
    }
    if(response.status >= 500) {
        throw new Response(response.body, {status: response.status});
    }

    const json = await response.json();
    
    if(json.errorCode === "COMMON_NO_LOGIN") {
        throw new Response(json.message, {status: 401});
    }

    fn_Debug(defineName);
    fn_Debug(json);

    return json;
}

export const fn_Debug = (data) => {
    if(LOG_LEVEL === "DEBUG") {
        console.log(data);
    }
}

export const makeParamsFromFormData = (formData, reqiredListKey) => {
    const keys = formData.keys();
    const params = {}

    for(let key of keys) {
        if(key === "file") continue;

        if(typeof reqiredListKey === "object") {
            for(let rk of reqiredListKey) {
                if(key === rk) {
                    const rkey = formData.getAll(rk);
                    if(typeof rkey === "object") {
                        params[rk] = rkey;
                    } else if(rkey === "string") {
                        params[rk] = [rkey];
                    }
                    continue;
                }
            }
        } else {
            if(key === reqiredListKey) {
                const rkey = formData.getAll(reqiredListKey);
                if(typeof rkey === "object") {
                    params[reqiredListKey] = rkey;
                } else if(rkey === "string") {
                    params[reqiredListKey] = [rkey];
                }
                continue;
            }
        }

        if(params[key]) {
            params[key] = formData.getAll(key);
        } else {
            params[key] = formData.get(key);
        }
    }

    return params;
}

export const uploadFile = async(defineName, formData) => {
    const newFormData = new FormData();

    for(let [name, value] of formData) {
        if(name === "file") {
            if(value.size === 0) {
                continue;
            }
        } 
        newFormData.append(name, value);
    }
    
    const opt = {
        method: define[defineName].method,
        body: newFormData,
    };

    const response = await fetch(FILE_HOST + define[defineName].url, opt);
    if(response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);
    
    return json.fleGrpId;
}

export const uploadFileEditor = async(defineName, formData) => {
    const opt = {
        method: define[defineName].method,
        body: formData,
    };

    const response = await fetch(FILE_HOST + define[defineName].url, opt);
    if(response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);

    return json.url;
}

export const getFiles = async(defineName, param, queryStrings) => {
    let newUrl = FILE_HOST + define[defineName].url;

    for(let value in param) {
        newUrl += `/${param[value]}`;
    }

    if(queryStrings) {
        newUrl += "?";
        for(let value in queryStrings) {
            newUrl += `${value}=${queryStrings[value]}&`
        }
    }

    const headers = {
        "Content-Type": "application/json",
    }

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }

    const response = await fetch(newUrl, {
        method: define[defineName].method,
        headers: headers,
    });

    if(response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    if(response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);

    return json;
}

export const convertEnDisableToKr = (data) => {
    if(data === ENABLE_STATUS) return "사용";
    else return "미사용";
}

export const checkEnDisable = (data, value) => {
    return data === value;
}

export const getClassNameByStatus = (status) => {
    //"GREEN" : 활동, yellow : 사용정지, red : 탈퇴, black : 강퇴, blue : 휴면
    switch (status) {
        case "GREEN":
            return "status_green"

        case "YELLOW":
            return "status_yellow"
            
        case "RED":
            return "status_red"

        case "BLUE":
            return "status_blue"

        case "BLACK":
            return "status_black"
    
        default:
            return "status_green"
    }
}

export const getNameByStatus = (status) => {
    switch (status) {
        case "GREEN":
            return "활동"

        case "YELLOW":
            return "사용정지"
            
        case "RED":
            return "탈퇴"

        case "BLUE":
            return "휴면"

        case "BLACK":
            return "강퇴"
    
        default:
            return "활동"
    }
}

export const getDefaultValueInStatusOptions = (value) => {
    for(let opt of statusOptions) {
        if(opt.value === value) {
            return opt;
        }
    }
}

const cookies = new Cookies()
export const setCookie = (name, value, options)=>{
	return cookies.set(name, value, {...options})
}

export const getCookie = (name)=>{
	return cookies.get(name)
}

export const deleteCookie = (name) => {
    cookies.remove(name);
}

export function isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
}

export function getRoleName(value) {
    //"ROLE_APP_ADMIN": 슈퍼관리자, "ROLE_APP_MANAGER": 관리자
    return value === "ROLE_APP_ADMIN" ? "슈퍼관리자" : "관리자";
}

export function getGradeName(value) {
    return value === "RARE" ? "준회원" : "정회원";
}

export function getMemberType(value) {
    switch(value) {
        case "KAKAO": 
            return "카카오";
        case "NAVER":
            return "네이버";
        case "GOOGLE":
            return "구글";
        case "APPLE":
            return "애플";
        default:
            return "일반";
    }
}

export const dateFormat = (date) => {
    if(!date) return null;
	let dateFormat2 = 
        date.getFullYear() +
		'.' + ( (date.getMonth()+1) <= 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) )+
		'.' + ( (date.getDate()) <= 9 ? "0" + (date.getDate()) : (date.getDate())) +
        '. ' + ( (date.getHours()) <= 9 ? "0" + (date.getHours()) : (date.getHours())) +
        ':' + ( (date.getMinutes()) <= 9 ? "0" + (date.getMinutes()) : (date.getMinutes()));
	return dateFormat2;
}