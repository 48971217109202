import { GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { BOARD_CHANGE_MENU_DEFINE, BOARD_CONTENT_TYPE_DEFINE, BOARD_CREATE_DEFINE, BOARD_DEFINE, BOARD_DETAIL_DEFINE, BOARD_ICON_DEFINE, BOARD_UPDATE_DEFINE } from "../../const/defineName";
import { BOARD_CHANGE_MENU_URL, BOARD_CONTENT_TYPE_URL, BOARD_DETAIL_URL, BOARD_ICON_URL, BOARD_UPDATE_URL, BOARD_URL } from "../../const/url";

export const boardDefine = {
    [BOARD_DEFINE]: {
        method: GET_METHOD,
        url: BOARD_URL,
    },
    [BOARD_CREATE_DEFINE]: {
        method: POST_METHOD,
        url: BOARD_URL,
    },
    [BOARD_CONTENT_TYPE_DEFINE]: {
        method: GET_METHOD,
        url: BOARD_CONTENT_TYPE_URL,
    },
    [BOARD_ICON_DEFINE]: {
        method: GET_METHOD,
        url: BOARD_ICON_URL,
    },
    [BOARD_DETAIL_DEFINE]: {
        method: GET_METHOD,
        url: BOARD_DETAIL_URL,
    },
    [BOARD_UPDATE_DEFINE]: {
        method: PUT_METHOD,
        url: BOARD_UPDATE_URL,
    },
    [BOARD_CHANGE_MENU_DEFINE]: {
        method: POST_METHOD,
        url: BOARD_CHANGE_MENU_URL,
    },

}