export const CREATE_PATH = "create";
export const UPDATE_PATH = "update";
export const CHANGE_PATH = "change";

export const DASHBOARD_PATH = "/";
export const GUIDE_PATH = "/guide";
export const LOGIN_PATH = "/login";
export const LOGOUT_PATH = "/logout";
export const MYPAGE_PATH = "/mypage";

// setting
export const SETTING_PATH = "/settings";

//app
export const SETTING_APP_PATH = "app";

//board
export const SETTING_BOARD_PATH = "board";

//userBoard
export const SETTING_USER_BOARD_PATH = "userBoard";

//group
export const SETTING_GROUP_PATH = "group";

//envsetting
export const SETTING_ENV_PATH = "envsettings";

//adminsetting
export const SETTING_ADMIN_PATH = "adminsetting";

export const SETTING_COMPANY_PATH = "company";

////////////////////////////////////////////////////

//content
export const CONTENT_PATH = "/content";

//notice
export const CONTENT_NOTICE_LIST_PATH = "notice";

//inquiry
export const CONTENT_INQUIRY_LIST_PATH = "inquiry";

//member
export const CONTENT_MEMBER_LIST_PATH = "member";

//calendar
export const CONTENT_CALENDAR_PATH = "calendar";

////////////////////////////////////////////////////