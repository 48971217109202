/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import GroupWrapper from '../atom/groupWrapper'
import GroupSearch from './groupSearch';
import GroupMember from '../atom/groupMember';
import PersonalGroup from '../atom/personalGroup';
import { removeItemAtList, replaceItemAtIndex } from '../../util/util';

const GroupAndMemberSelect = ({data, memberInputName, groupInputName, error, selectData, currGroupCode}) => {
    const ref = useRef();
    const [allGroup, setAllGroup] = useState(data ?? []);
    const [checkMember, setCheckMember] = useState([]);

    const [selectMember, setSelectMember] = useState(selectData ?? []);
    const [checkSelectMember, setCheckSelectMember] = useState([]);

    const [search, setSearch] = useState();
    const [searchType, ] = useState("appMemberName");
    const [searchMember, setSearchMember] = useState([]);

    const [flagSelect, setFlagSelect] = useState(false);

    // useEffect(() => {
    //     console.log("======== allGroup =========")
    //     console.log(allGroup)
    //     console.log("============================")
    //     console.log("======== checkMember =========")
    //     console.log(checkMember)
    //     console.log("============================")
    //     console.log("======== selectMember =========")
    //     console.log(selectMember)
    //     console.log("============================")
    //     console.log("======== checkSelectMember =========")
    //     console.log(checkSelectMember)
    //     console.log("============================")
    // }, [allGroup, selectMember, checkMember, checkSelectMember])

    useEffect(() => {
        initialData();
    }, [])

    const initialData = () => {
        let newAllGroup = [...allGroup];

        newAllGroup.forEach(g => {
            g.appMemberInGroupList.forEach(m => {
                m.groupCode = g.groupCode;
            })
        })

        setAllGroup(newAllGroup);
    }

    const onChangeSearch = (e) => {
        const searchVal = e.target.value;
        setSearch(searchVal);

        const exp = new RegExp(searchVal, "g");
        let searchArr = [];
        
        allGroup.forEach((val) => {
            const newVal = val.appMemberInGroupList.filter(mem => !mem[searchType].search(exp))
            searchArr = [...searchArr, ...newVal]
        })
        setSearchMember(searchArr);
    }

    const onCheckAllMember = (event, groupCode) => {
        const group = allGroup.filter(g => g.groupCode === groupCode)[0];
        
        if(event.target.checked) {
            group.appMemberInGroupList.forEach(member => {
                if(!checkMember.includes(member)) {
                    setCheckMember(currArr => [...currArr, member]);
                }
            })
        } else {
            let newArr = [...checkMember];

            group.appMemberInGroupList.forEach(m => {
                const index = newArr.indexOf(m);
                newArr = removeItemAtList(newArr, index);
            });

            // setCheckMember(newArr);
            setCheckMember([]);
        }
    }

    const onCheckMember = (groupCode, appMemberCode) => {
        const group = allGroup.filter(g => g.groupCode === groupCode)[0];
        const member = group.appMemberInGroupList.filter(m => m.appMemberCode === appMemberCode);

        if(!checkMember.includes(...member)) {
            setCheckMember(currArr => [...currArr, ...member]);
        } else {
            const newArr = checkMember.filter(m => m.appMemberCode !== appMemberCode);
            setCheckMember(newArr);
        }
    }

    const onSelect = () => {
        const newSelectMember = [...selectMember];
        
        checkMember.forEach(m => {
            if(newSelectMember.length === 0) {
                newSelectMember.push(m);
            }
            let flag = true;
            
            for(let i = 0; i < newSelectMember.length; i++) {
                if(newSelectMember[i].appMemberCode === m.appMemberCode) {
                    flag = false;
                    break;
                }
            }

            if(flag) newSelectMember.push(m);
            setFlagSelect(!flagSelect);
        })
        
        setSelectMember(newSelectMember);
        setCheckMember([]);

        let newAllGroup = [...allGroup];

        checkMember.forEach(member => {
            const filteredByMemberGroupIdList = newAllGroup.filter(g => g.groupCode === member.groupCode);
            const groupIndex = newAllGroup.indexOf(filteredByMemberGroupIdList[0]);
            
            const memberIndex = filteredByMemberGroupIdList[0].appMemberInGroupList.indexOf(member);
            const newGroupMember = removeItemAtList(filteredByMemberGroupIdList[0].appMemberInGroupList, memberIndex);

            filteredByMemberGroupIdList[0].appMemberInGroupList = newGroupMember;
            newAllGroup = replaceItemAtIndex(newAllGroup,groupIndex, filteredByMemberGroupIdList[0]);
        })

        setAllGroup(newAllGroup);

        const exp = new RegExp(search, "g");
        let searchArr = [];
        
        allGroup.forEach((val) => {
            const newVal = val.appMemberInGroupList.filter(mem => !mem[searchType].search(exp))
            searchArr = [...searchArr, ...newVal]
        })
        setSearchMember(searchArr);
    }

    const onCheckSelectMember = (param) => {
        if(!checkSelectMember.includes(param)) setCheckSelectMember(currArr => [...currArr, param]);
        else {
            const newArr = checkSelectMember.filter(m => m.appMemberCode !== param.appMemberCode);
            setCheckSelectMember(newArr);
        }
    }

    const onDelete = () => {
        let newAllGroup = [...allGroup];
        let newSelectMember = [...selectMember];

        checkSelectMember.forEach(member => {
            const group = newAllGroup.filter(g => g.groupCode === member.groupCode)[0];
            const groupIndex = newAllGroup.indexOf(group);

            group.appMemberInGroupList.push(member);
            group.appMemberInGroupList.sort((a,b) => a.appMemberEmail.localeCompare(b.appMemberEmail));

            newAllGroup = replaceItemAtIndex(newAllGroup, groupIndex, group);

            const memberIndex = newSelectMember.indexOf(member);
            newSelectMember = removeItemAtList(newSelectMember, memberIndex);
        })

        setAllGroup(newAllGroup);
        setCheckSelectMember([]);
        setSelectMember(newSelectMember);

        const exp = new RegExp(search, "g");
        let searchArr = [];
        
        allGroup.forEach((val) => {
            const newVal = val.appMemberInGroupList.filter(mem => !mem[searchType].search(exp))
            searchArr = [...searchArr, ...newVal]
        })
        setSearchMember(searchArr);
        setFlagSelect(!flagSelect);
    }

    const renderAllGroup = () => {
        if(search) {
            if(searchMember.length === 0) {
                return (
                    <div className="groupConList">
                        검색된 결과가 없습니다.
                    </div>
                );
            }

            return (
                <div className="groupConList">
                    {
                        searchMember.map((member) => (
                            <PersonalGroup
                                key={member.appMemberCode + member.groupCode}
                                onClick={() => {onCheckMember(member.groupCode, member.appMemberCode)}}
                                memberName={member.appMemberName}
                                memberId={member.appMemberEmail}/>
                        ))
                    }
                </div>
            );
        } else {
            return (
                <div className="groupConList">
                    {
                        allGroup.map((data, idx) => (
                            <GroupWrapper
                                key={idx}
                                groupName={data.groupName}
                                showMember={true}
                                onCheck={(event) => {onCheckAllMember(event, data.groupCode)}}
                                flagSelect={flagSelect}>
                                {
                                    data.appMemberInGroupList.map(member => (
                                        <GroupMember 
                                            key={member.appMemberCode}
                                            groupName={data.groupName}
                                            memberName={member.appMemberName}
                                            memberId={member.appMemberEmail}
                                            onChange={() => {onCheckMember(data.groupCode, member.appMemberCode)}}/>
                                    ))
                                }
                            </GroupWrapper>
                        ))
                    }
                </div>
            );
        }
        
    }

    useEffect(() => {
        if(error) {
            ref.current.style.scrollMargin = "90px";
            ref.current.scrollIntoView();
        }
    }, [error])

    return (
        <div>
            <GroupSearch onChangeSearch={onChangeSearch}/>
            <div className="groupConWrap" ref={ref}>
                <div>
                    <h4>전체 그룹</h4>
                    {renderAllGroup()}
                </div>
                <span>
                    <img 
                    src={process.env.PUBLIC_URL + "/img/icon_arrow_right_g.png"} 
                    alt="화살표 이미지" width="24px" height="24px"
                    onClick={onSelect}/><br/>
                    <img 
                    src={process.env.PUBLIC_URL + "/img/icon_arrow_left_g.png"} 
                    alt="화살표 이미지" width="24px" height="24px"
                    onClick={onDelete}/>
                </span>
                <div>
                    <h4>선택 그룹</h4>
                    <div className="groupConList">
                        {
                            selectMember.map(member => (
                                <div key={member.appMemberCode + member.groupCode}>
                                    <PersonalGroup 
                                        onClick={() => {onCheckSelectMember(member)}}
                                        memberName={member.appMemberName}
                                        memberId={member.appMemberEmail}/>
                                    <input 
                                        type='checkbox' 
                                        name={memberInputName} 
                                        value={member.appMemberCode} 
                                        style={{display: "none"}} 
                                        defaultChecked/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {error ? <p className='warning'>{error}</p> : ""}
        </div>
    )
}

export default GroupAndMemberSelect