import React, { useLayoutEffect, useRef } from 'react'
import { randomNumber } from '../../util/util'

const Input = ({label, name, type, defaultValue, placeholder, comment, error, id, readOnly, required=false}) => {
    const ranId = randomNumber();
    const ref = useRef(null);

    useLayoutEffect(() => {
        if(error) {
            ref.current.focus();
            ref.current.style.scrollMargin = "90px";
            ref.current.scrollIntoView();
        }
    }, [error])

    return (
        <>
        {/* 필수값은 label에 classname = required 를 붙이기 */}
            <div className="mb-4 d-grid">
                <label htmlFor={id ?? ranId} className={required ? "form-label required" : "form-label"}>{label}</label>
                <div>
                    <input 
                        ref={ref}
                        type={type} 
                        name={name} 
                        className="form-control" 
                        id={id} 
                        placeholder={placeholder} 
                        defaultValue={defaultValue} 
                        readOnly={readOnly}/>
                        {error ? <p className="warning">{error}</p> : ""}
                    <p className="info">{comment}</p>
                </div>
            </div>
        </>
    )
}

export default Input