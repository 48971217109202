import React, { useEffect } from 'react'
import Button from '../../../atom/button'
import { useNavigate, useLoaderData } from 'react-router-dom';
import { UPDATE_PATH } from '../../../../const/path';
import { useQuery } from "@tanstack/react-query"
import { noticeReadQuery } from '../../../../queries/noticeQueries';
import parse from 'html-react-parser';

export const noticeReadLoader = 
    (queryClient) => async({params}) => {
        if(!queryClient.getQueryData(noticeReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(noticeReadQuery(params));
        }
        
        return { params };
    }

const NoticeRead = () => {
    const navigator = useNavigate();
    const { params } = useLoaderData();
    const { data: { data: notice } } = useQuery(noticeReadQuery(params));

    return (
        <div className="main">
            <div className="contentBg">
                <h2 className="subTit">공지사항 상세</h2>

                <div className="formWrap">
                    <div className="mb-4 d-grid">
                        <label htmlFor="exampleFormControlInput1" className="form-label">공지사항 제목</label>
                        <div>{ notice.title }</div>
                    </div>

                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">공지사항 게시 날짜</label>
                        <div>
                            { notice.startDate }
                        </div>
                    </div>

                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">공지사항 내용</label>
                        <div className='contentTxt'>
                            { parse(notice.content ?? "") }
                        </div>
                    </div>

                    <div className="mb-4 d-grid">
                        <label htmlFor="formFileMultiple" className="form-label">첨부파일</label>
                        <div className='d-flex'>
                        {
                            notice.fileUrl.map((url) => (
                                <div className="attachment" key={url}>
                                    <div className='fileImg attachment'>
                                        <img src={url} alt="" />
                                        <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>

                <Button content={"공지사항 수정"} onClick={() => {navigator(UPDATE_PATH)}}/>

            </div>
        </div>
    )
}

export default NoticeRead