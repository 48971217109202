import { ROLE_APP_ADMIN } from "../../const/const";
import { myInfoQuery } from "../../queries/adminQueries";

export const settingLoader = 
    (queryClient) => async ({request}) => {
        
        const response = await queryClient.fetchQuery(myInfoQuery());
        if(response.data.appManagerRole !== ROLE_APP_ADMIN) {
            throw new Response("잘못된 요청입니다.(접근 권한)", {status: 400});
        }
        return null;
    }