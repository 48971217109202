import { ADMIN_CREATE_DEFINE, ADMIN_DELETE_DEFINE, ADMIN_LIST_DEFINE, ADMIN_READ_DEFINE, ADMIN_SEARCH_DEFINE, MY_INFO_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const adminListQuery = (params) => ({
    queryKey: ["adminList", params.page],
    queryFn: async() => {
        const json = await fetchDataPathVar(ADMIN_LIST_DEFINE, {}, params);
        return json;
    }
})

export const adminSearchQuery = (params) => ({
    queryKey: ["adminSearchList", params.keyword],
    queryFn: async() => {
        const json = await fetchDataPathVar(ADMIN_SEARCH_DEFINE, {}, params);
        return json;
    }
})

export const adminCreateQuery = (params) => ({
    queryKey: ["adminCreate"],
    queryFn: async() => {
        const json = await fetchData(ADMIN_CREATE_DEFINE, params);
        return json;
    }
})

export const adminReadQuery = (params) => ({
    queryKey: ["adminRead", params.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(ADMIN_READ_DEFINE, params);
        return json;
    }
})

export const adminDeleteQuery = (params) => ({
    queryKey: ["adminDelete"],
    queryFn: async() => {
        const json = await fetchData(ADMIN_DELETE_DEFINE, params);
        return json;
    }
})

export const myInfoQuery = () => ({
    queryKey: ["myInfo"],
    queryFn: async() => {
        const json = await fetchDataPathVar(MY_INFO_DEFINE);
        return json;
    }
})