import { COMPANY_CREATE_DEFINE, COMPANY_DELETE_DEFINE, COMPANY_LIST_DEFINE, COMPANY_READ_DEFINE, COMPANY_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const companyListQuery = () => ({
    queryKey: ["companyList"],
    queryFn: async() => {
        const json = await fetchDataPathVar(COMPANY_LIST_DEFINE);
        return json;
    }
})

export const companyReadQuery = (param) => ({
    queryKey: ["companyRead", param.id],
    queryFn: async() => {
        const json = await fetchDataPathVar(COMPANY_READ_DEFINE, param);
        return json;
    }
})

export const companyCreateQuery = (param) => ({
    queryKey: ["companyCreate"],
    queryFn: async() => {
        const json = await fetchData(COMPANY_CREATE_DEFINE, param);
        return json;
    }
})

export const companyUpdateQuery = (param) => ({
    queryKey: ["companyUpdate"],
    queryFn: async() => {
        const json = await fetchData(COMPANY_UPDATE_DEFINE, param);
        return json;
    }
})

export const companyDeleteQuery = () => ({
    queryKey: ["companyDelete"],
    queryFn: async() => {
        const json = await fetchData(COMPANY_DELETE_DEFINE);
        return json;
    }
})

