import { GET_METHOD, POST_METHOD } from "../../const/const";
import { EDITOR_FILE_UPLOAD_URL, FILE_DETAIL_DEFINE, FILE_POST_DEFINE, FILE_UPDATE_DEFINE } from "../../const/defineName";

export const fileDefine = {
    [FILE_POST_DEFINE] :{
        method: POST_METHOD,
        url: "/v1/file"
    },
    [EDITOR_FILE_UPLOAD_URL] : {
        method: POST_METHOD,
        url: "/v1/ckUpload/image",
    },
    [FILE_DETAIL_DEFINE] : {
        method: GET_METHOD,
        url: "/v1/fileDetail",
    },
    [FILE_UPDATE_DEFINE] : {
        method: POST_METHOD,
        url: "/v1/fileModify"
    }
}