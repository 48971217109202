import React from 'react'
import { useNavigate } from 'react-router-dom'

const Page404 = () => {
  const navigator = useNavigate();

  return (
    <div className='errorWrap'>
      <div className='errorTit'>
        <h2>404 Not Found</h2>
        <p>죄송합니다. 페이지를 찾을 수 없습니다.<br/>존재하지 않는 주소를 입력하셨거나,<br/> 요청하신 페이지 주소가 변경 혹은 삭제되어 찾을 수 없습니다.</p>
        <button onClick={() => {navigator(-1)}}>뒤로가기</button>
      </div>
    </div>
  )
}

export default Page404