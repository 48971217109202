import React from 'react'

const Searchbar = ({searchName, onSearch}) => {
  return (
    <>
        <div className='d-flex'>
          <input type="text" name={searchName} className="form-control" aria-label="Text input with button" placeholder="검색어를 입력하세요"/>
          <button className="searchbtn">
            <img src={process.env.PUBLIC_URL + "/img/search.png"} alt="검색아이콘"/>
          </button>
        </div>
    </>
  )
}

export default Searchbar