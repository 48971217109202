import React from 'react'
import SubTitle from '../../../atom/subTitle';
import Button from '../../../atom/button';
import { useNavigate } from 'react-router-dom'
import { UPDATE_PATH } from '../../../../const/path';
import { appDetailQuery } from '../../../../queries/appQueries';
import { useQuery } from '@tanstack/react-query';
import { FILE_HOST } from '../../../../server/host';

export const appManageLoader =
    (queryClient) => async() => {
        const response = await queryClient.fetchQuery(appDetailQuery());

        return response;
    }

const AppManage = () => {
    const navigate = useNavigate();
    const { data: { data } } = useQuery(appDetailQuery());

    return (
        <div className="main">
            <div className="contentBg">
            <SubTitle title={"앱관리"}/>

            <div className='tableWrap5 '>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>앱 이름</th>
                            <td>
                                <div>{ data.appName }</div>
                                <p className='info'>앱을 대표하는 이름입니다.</p>
                            </td>
                            {/* <th>앱 유형</th>
                            <td>
                                <div>단체앱</div>
                                <p className="info">앱 유형은 수정하실 수 없습니다.</p>
                            </td> */}
                        </tr>
                        <tr>
                            <th>앱 아이콘</th>
                            <td>
                                {
                                    data.appIconFile.map(icon => (
                                        <div className='appiconimg' key={icon.fleUrl}>
                                            <img src={`${FILE_HOST}${icon.fleUrl}`} alt="앱 아이콘 이미지" />
                                        </div>
                                    ))
                                }
                                <p className="info">앱을 대표하는 아이콘입니다.</p>
                            </td>
                        </tr>
                        {/* <tr>
                            <th>앱 템플릿</th>
                            <td className='templateimg'>
                                <p>교회앱 템플릿</p>
                                <img src="../img/mainhome.png" alt="템플릿 이미지"/>
                                <p className="info">앱의 템플릿을 수정하실 수 있습니다. 템플릿 수정시 최대 5분이 소요될 수 있습니다.</p>
                            </td>
                            <th>앱 테마색상</th>
                            <td>
                                <div className="d-flex themecolor align-items-center">
                                    <p>모노톤색 #3D3D3D</p>
                                    <div><span style={{backgroundColor:'#3d3d3d'}}></span></div>
                                </div>
                                <p className='info'>앱의 주요 버튼의 색이 변경됩니다.</p>
                            </td>
                        </tr> */}
                        {/* <tr>
                            <th>추가기능</th>
                            <td>
                                <ul className='addfunctionWrap d-flex justify-content-start align-items-center'>
                                    <li>
                                        <p>SNSnaver</p>
                                        <img src="../img/SNSnaver.png" alt="추가기능 이미지" />
                                    </li>
                                </ul>
                                <p className="info">앱의 추가기능을 수정하실 수 있습니다. 앱 수정 시 최대 5분이 소요될 수 있습니다.</p>
                            </td>
                        </tr> */}
                        <tr>
                            <th>앱 생성일</th>
                            <td>
                                <div>{ data.createAt }</div>
                                <p className="info">앱을 최초로 생성한 날짜 입니다.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/*<div className="btnWrap d-flex justify-content-end align-items-center">*/}
            {/*    <Button content={"앱수정"} onClick={() => {navigate(UPDATE_PATH)}}/>*/}
            {/*</div>*/}
            </div>
        </div>
  )
}

export default AppManage