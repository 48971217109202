import React from 'react'
import Button from '../../../atom/button'
import { useNavigate } from 'react-router-dom'
import { UPDATE_PATH } from '../../../../const/path';
import GroupWrapper from '../../../atom/groupWrapper';
import { useQueries } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { FILE_HOST } from '../../../../server/host';
import { boardContentTypeQuery, boardReadQuery } from '../../../../queries/boardQueries';
import { DISABLE_STATUS, ENABLE_STATUS } from '../../../../const/const';
import FunctionSelector from '../../../components/FunctionSelector';

export const boardReadLoader = 
    (queryClient) => async({params}) => {
        // if(!queryClient.getQueryData(boardReadQuery(params).queryKey)) {
            await queryClient.fetchQuery(boardReadQuery(params));
        // }
        if(!queryClient.getQueryData(boardContentTypeQuery().queryKey)) {
            (await queryClient.fetchQuery(boardContentTypeQuery()));
        }
        
        return { params };
    }

const BoardRead = () => {
    const { params } = useLoaderData();
    // const { data:{data} } = useQuery(boardReadQuery(params));
    const [boardDetail, boardContentTypeList] = useQueries({
        queries: [boardReadQuery(params), boardContentTypeQuery()]
    });
    const contentInfo = boardDetail.data.data.contentInfo;
    const groupList = boardDetail.data.data.groupList;

    const navigator = useNavigate();
    return (
        <div className="main">
        <div className="contentBg">
            <h2 className="subTit">게시판 상세</h2>

            <div className="formWrap">
                <div className="mb-4 d-grid ">
                <label className="form-label">게시판 이름</label>
                <div>
                    <div>{contentInfo.name}</div>
                    <p className="info">게시판을 대표하는 이름입니다.</p>
                </div>
                </div>
    
                <div className="mb-4 d-grid">
                <label htmlFor="formFileMultiple" className="form-label">게시판 아이콘</label>
                <div>
                    <div className="boardImg">
                        { contentInfo.fileInfo.fileUrl ? <img src={`${FILE_HOST}${contentInfo.fileInfo.fileUrl}`} alt="아이콘 이미지"/> : "등록된 아이콘이 없습니다."}
                    </div>
                    <p className="info">게시판을 대표하는 아이콘입니다.</p>
                </div>
                </div>
    
                {/* <div className="mb-4 boardType d-grid">
                    <label  className="form-label">게시판 형태</label>
                    <div>
                        <div className='d-flex'>
                            {
                                boardContentTypeList.data.data.contentTypeInfo ? 
                                    boardContentTypeList.data.data.contentTypeInfo.map((type, idx) => (
                                        <div className="form-check form-check-inline" key={type.code}>
                                            <input 
                                                className="form-check-input"
                                                type="radio" 
                                                name="typeCode"
                                                value={type.code}
                                                id={type.code + idx}
                                                checked={type.code === contentInfo.typeInfo.code}
                                                onChange={() => {}}
                                                />
                                            <label className="form-check-label" htmlFor={type.code + idx}>{type.name}</label>
                                            <div className="boardinfo">{type.description}</div>
                                        </div>
                                    )) : ""
                            }
                        </div>
                        <p className="info">게시판의 형태를 선택해 주세요</p>
                    </div>
                </div> */}

                <FunctionSelector 
                    data={boardContentTypeList.data.data.capabilityInfo} 
                    defaultValue={contentInfo.capabilityInfo}
                    readOnly={true}/>

                <div className="mb-4 d-grid">
                <label className="form-label">게시판 접속 그룹</label>
                <div>
                    <div className="groupConWrap">
                    <div>
                        <h4>선택 그룹</h4>
                        <div className="groupConList">
                            {
                                groupList.map((group, idx) => (
                                    <GroupWrapper 
                                        groupName={group.groupName} 
                                        showMember={false} 
                                        disabled 
                                        defaultChecked={true} 
                                        key={idx}/>
                                ))
                            }
                            
                            {/* <PersonalGroup memberId={"test2"} memberName={"test2"}/> */}
                        </div>
                    </div>
                    </div>
                    <p className="info">해당 게시판을 볼 수 있는 권한을 설정하실 수 있습니다. 선택하지 않은 그룹은 해당 게시판을 읽을 수 없습니다.</p>
                </div>
                </div>
    
                {/* <div className="mb-4  d-grid">
                    <label className="form-label">댓글 사용여부</label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="replyStatus"
                                value={ENABLE_STATUS} checked={contentInfo.replyStatus === ENABLE_STATUS} onChange={() => {}}/>
                            <label className="form-check-label" htmlFor="replyStatus">사용</label>
                        </div>
                        <p className="info">미사용시 앱에서 해당 게시판을 볼 수 없습니다. </p>
                    </div>
                </div> */}
                <div className="mb-4  d-grid">
                    <label className="form-label">게시판 사용여부</label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="status"
                                value={DISABLE_STATUS} checked={contentInfo.status === ENABLE_STATUS} onChange={() => {}}/>
                            <label className="form-check-label" htmlFor="status">사용</label>
                        </div>
                        <p className="info">미사용시 앱에서 해당 게시판을 볼 수 없습니다. </p>
                    </div>
                </div>
            </div>
    
            <Button content={"게시판 수정"} onClick={() => {navigator(UPDATE_PATH)}}/>

        </div>
        </div>
    )
}

export default BoardRead