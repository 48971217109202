import React from 'react'
import Select from '../atom/select'
import Searchbar from '../atom/searchbar'
import { Form } from 'react-router-dom'
import { GET_METHOD } from '../../const/const'

const SearchAndDelete = ({children, isDelete, searchName, onSearch, searchTypeName, onChageSearchType, htmlFor, error}) => {
    return (
        <>
            <div 
                className={isDelete 
                ? "boardListWrap d-flex justify-content-between align-items-center" 
                : "boardListWrap d-flex justify-content-end align-items-center"}
                >
                {
                    isDelete ?
                        <label className="deleyebtn" htmlFor={htmlFor}>
                            삭제
                            {/* <img src={process.env.PUBLIC_URL + "/img/mdi_trash.png"} alt="삭제 이미지"/> */}
                        </label> : ""
                }
                <Form method={GET_METHOD}>
                    <div className="input-group mb-3">
                        <Select name={searchTypeName} onChange={onChageSearchType}>
                            {children}
                        </Select>
                        <Searchbar searchName={searchName}/>
                    </div>
                </Form>
            </div>
        </>
  )
}

export default SearchAndDelete