import { APP_DETAIL_DEFINE, APP_UPDATE_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const appDetailQuery = () => ({
    queryKey: ["appDetail"],
    queryFn: async() => {
        const json = await fetchDataPathVar(APP_DETAIL_DEFINE);
        return json;
    }
})

export const appUpdateQuery = (params) => ({
    queryKey: ["appUpdate"],
    queryFn: async() => {
        const json = await fetchData(APP_UPDATE_DEFINE, params);
        return json;
    }
})