import ToggleBtn from "../atom/toggleBtn";

const FunctionSelector = ({data, defaultValue, readOnly}) => {
    return (
        <div className="mb-4 d-grid  boardFunction">
            <label htmlFor="boardFuntion" className='form-label'>게시판 기능</label>
            <div>
                <div className='d-flex'>
                    {
                        data.map((ty) => (
                            <div className="form-check form-check-inline" key={ty.type}>
                                {/* <input 
                                    className="form-check-input"
                                    style={{display: "none"}} 
                                    type="radio" 
                                    name="typeCode" 
                                    id={ty.code} 
                                    value={ty.code} 
                                    defaultChecked={defaultValue ? idx === 0 : defaultValue === ty.code}/>
                                <label className="form-check-label" htmlFor={ty.code}>{ty.name}</label> */}
                                <ToggleBtn 
                                    name={"capabilityStatus"} 
                                    value={ty.type} 
                                    description={ty.name} 
                                    defaultChecked={defaultValue ? defaultValue.includes(ty.type) : false}
                                    readOnly={readOnly}/>
                                {/* <div className="boardinfo">{ty.description}</div> */}
                            </div>
                        ))
                    }
                </div>
                <p className="info">게시판에 필요한 기능을 선택해 주세요</p>
            </div>
        </div>
    );
}

export default FunctionSelector;