import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Menu = ({ to, src, content, alt }) => {
  const location = useLocation();

  return (
    <li className={location.pathname.includes(to) ? "on" : ""}>
        <Link to={to} className="rounded">
            <img src={src} alt={alt}/>
            <p>{content}</p>
        </Link>
    </li>
  )
}

export default Menu