import { GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE, GROUP_CREATE_DEFINE, GROUP_LIST_DEFINE, GROUP_READ_DEFINE, GROUP_UPDATE_DEFINE } from "../../const/defineName";
import { GROUP_LIST_URL, GROUP_READ_URL, GROUP_UPDATE_URL, GROUP_URL } from "../../const/url";

export const groupDefine = {
    [ALL_GROUP_LIST_FOR_CREATE_OR_UPDATE_DEFINE]: {
        method: GET_METHOD,
        url: GROUP_URL,
    },
    [GROUP_CREATE_DEFINE]: {
        method: POST_METHOD,
        url: GROUP_URL,
    },
    [GROUP_LIST_DEFINE]: {
        method: GET_METHOD,
        url: GROUP_LIST_URL,
    },
    [GROUP_READ_DEFINE]: {
        method: GET_METHOD,
        url: GROUP_READ_URL,
    },
    [GROUP_UPDATE_DEFINE]: {
        method: PUT_METHOD,
        url: GROUP_UPDATE_URL,
    }
}