import React from 'react'
import { Form } from 'react-router-dom'
import { POST_METHOD } from '../../../const/const'

const CompanyDeletePopup = ({onCancle}) => {
  return (
    <Form method={POST_METHOD}>
        <div className='popupWrap'>
            <div className='popupbg'>
                <h3>대표 정보 삭제</h3>
                <span>대표 정보를 정말 삭제하시겠습니까?</span>
                <div className='btnwrap'>
                    <button onClick={onCancle}>취소</button>
                    <button className='yes'>삭제</button>
                </div>
            </div>
        </div>
    </Form>
  )
}

export default CompanyDeletePopup