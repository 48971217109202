import { useOutletContext, useNavigate, Form, redirect, useActionData, useLocation } from "react-router-dom"
import Button from "../../../../atom/button";
import { CONTENT_INQUIRY_LIST_PATH, CONTENT_PATH, UPDATE_PATH } from "../../../../../const/path";
import { POST_METHOD } from "../../../../../const/const";
import { makeParamsFromFormData } from "../../../../../util/util";
import { replyDeleteQuery } from "../../../../../queries/inquiryQueries";
import parse from "html-react-parser";
import { useEffect } from "react";

export const replyDeleteAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        params.replyCodeList = [params.replyCodeList];

        const response = await queryClient.fetchQuery(replyDeleteQuery(params));

        const errors = {};
        if(response.result === "SUCCESS") {
            return redirect(`${CONTENT_PATH}/${CONTENT_INQUIRY_LIST_PATH}/${params.code}`);
        } else {
            errors.error = response.message;
        }

        return errors;
    }

const ReplyRead = () => {
    const errors = useActionData();
    const { inquiryCode } = useOutletContext();
    const { state: { reply } } = useLocation();
    const navigator = useNavigate();

    return (
        <div className="contentBg">
            <h3>문의 답변</h3>
            <div className="tableWrap4">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>제목</th>
                            <td>
                                {reply.title}
                            </td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td>
                                <div className="contentTxt">
                                    {parse(reply.content ?? "")}
                                </div>
                            </td>
                        </tr>
                        <tr className="line"></tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                            {
                                reply.fileUrl ? 
                                reply.fileUrl.map(url => (
                                    <div className='fileImg' key={url}>
                                    <img src={url} alt={url} />
                                    <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                                    </div>
                                )) : "-"
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="warning">{errors?.error}</p>
                <div className="btnWrap justify-content-end">
                    <Form method={POST_METHOD}>
                        <input type="hidden" name="code" value={inquiryCode}/>
                        <input type="hidden" name="replyCodeList" value={reply.replyCode}/>
                        <Button content={"답변삭제"} />
                    </Form>
                    <Button content={"답변수정"} onClick={() => {navigator(UPDATE_PATH)}}/>
                </div>
            </div>
        </div> 
    )
}

export default ReplyRead;