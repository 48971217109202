import React, { useState } from 'react'

const ToggleBtn = ({defaultChecked=false, name, description, value, readOnly}) => {
    const [isCheck, setIsCheck] = useState(defaultChecked);

    const onClick = () => {
        setIsCheck(!isCheck)    
    }

    return (
        <div className='d-flex align-items-center toggle' onClick={readOnly ? () => {} : onClick}>
            <div className='toggle-wrap ' >
                {isCheck ? <input type='hidden' name={name} defaultValue={value}/> : ""}
                <div className={isCheck ? 'toggle-container toggle--checked' : 'toggle-container'}></div>
                <div className={isCheck ? 'toggle-circle toggle--checked ' : 'toggle-circle'}></div>
            </div>
            <p>{description}</p>
        </div>
    )
}

export default ToggleBtn