import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const MenuTitle = ({ src, alt, content, to }) => {
  const location = useLocation();

  return (
    <Link to={to}>
      <li className={location.pathname === to ? "mb-1 border-bottom on" : "mb-1 border-bottom"}>
          <img src={src} alt={alt}/>
          <button className="btn on align-items-center rounded">
              {content}
          </button>
      </li>
    </Link>
  )
}

export default MenuTitle