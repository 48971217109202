import React, { useEffect, useRef, useState } from 'react'
import GroupWrapper from '../atom/groupWrapper'
import GroupSearch from './groupSearch';
import { removeItemAtList } from '../../util/util';

const GroupSelect = ({data=[], memberInputName, groupInputName, showMember=true, error, selectData=[]}) => {
    const ref = useRef();
    const [allGroup, setAllGroup] = useState(data);
    const [checkAllGroup, setCheckAllGroup] = useState([]);

    const [selectGroup, setSelectGroup] = useState(selectData);
    const [checkSelectGroup, setCheckSelectGroup] = useState([]);

    const [search, setSearch] = useState();
    const [searchGroup, setSearchGroup] = useState([]);

    const onCheckAllGroup = (idx) => {
        if(!checkAllGroup.includes(idx)) {
            setCheckAllGroup(currArr => [...currArr, idx])
        } else {
            const newArr = checkAllGroup.filter(data => data.idx === idx);
            setCheckAllGroup(newArr);
        }
    }

    const onCheckSelectGroup = (idx) => {
        if(!checkSelectGroup.includes(idx)) {
            setCheckSelectGroup(currArr => [...currArr, idx]);
        } else {
            const newArr = checkSelectGroup.filter(data => data.idx === idx);
            setCheckSelectGroup(newArr);
        }
    }

    const onSelect = () => {
        let afterFilteredList = [...allGroup];

        for(let idx in checkAllGroup) {
            const groupIdx = checkAllGroup[idx];
            const filterdList = allGroup.filter((data) => data.idx === Number(groupIdx));
            afterFilteredList = afterFilteredList.filter((data) => data.idx !== Number(groupIdx));
            setSelectGroup(currArr => [...currArr, ...filterdList]);
        }

        setAllGroup(afterFilteredList);
        setCheckAllGroup([]);

        //검색결과도 다시 출력
        const exp = new RegExp(search, "g");
        const newArr = afterFilteredList.filter(data => 
            !data.groupName.search(exp)
        )
        setSearchGroup(newArr);
    }

    const onDelete = () => {
        let afterFilteredList = [...selectGroup];

        for(let idx in checkSelectGroup) {
            const groupIdx = checkSelectGroup[idx];
            
            const filterdList = selectGroup.filter((data) => data.idx === Number(groupIdx));
            afterFilteredList = afterFilteredList.filter((data) => data.idx !== Number(groupIdx));
            setAllGroup(currArr => [...currArr, ...filterdList].sort((a, b) => a.idx - b.idx));

            // 검색결과 복구
            setSearchGroup(currArr => [...currArr, ...filterdList.filter(f => !f.groupName.search(new RegExp(search, "g")))]);
        }
        
        setSelectGroup(afterFilteredList);
        setCheckSelectGroup([]);
    }

    const onChangeSearch = (e) => {
        const searchVal = e.target.value;
        setSearch(searchVal);

        const exp = new RegExp(searchVal, "g");
        const newArr = allGroup.filter(data => 
            !data.groupName.search(exp)
        )
        setSearchGroup(newArr);
    }

    const renderAllGroup = () => {
        if(search) {
            if(searchGroup.length === 0) {
                return (
                    <div className="groupConList">
                        검색된 결과가 없습니다.
                    </div>
                );
            }

            return (
                <div className="groupConList">
                    {
                        searchGroup.map((data) => (
                            <GroupWrapper
                                key={data.groupName}
                                groupName={data.groupName}
                                showMember={showMember}
                                onCheck={() => onCheckAllGroup(data.idx)}
                                defaultChecked={checkAllGroup.includes(data.idx)}
                                />
                        ))
                    }
                </div>
            );
        } else {
            return (
                <div className="groupConList">
                    {
                        allGroup.map((data) => (
                            <GroupWrapper
                                key={data.groupName}
                                groupName={data.groupName}
                                showMember={showMember}
                                onCheck={() => onCheckAllGroup(data.idx)}
                                defaultChecked={checkAllGroup.includes(data.idx)}
                                />
                        ))
                    }
                </div>
            );
        }
        
    }

    useEffect(() => {
        if(error) {
            ref.current.style.scrollMargin = "90px";
            ref.current.scrollIntoView();
        }
    }, [error])

    useEffect(() => {
        let newAllGroup = [...allGroup];
        
        for(let i = 0; i < newAllGroup.length; i++) {
            for(let sg of selectGroup) {
                if(newAllGroup[i].groupCode === sg.groupCode) {
                    newAllGroup = removeItemAtList(newAllGroup, i);
                }
            }
        }
        setAllGroup(newAllGroup);
    }, [])

    return (
        <div>
            <GroupSearch onChangeSearch={onChangeSearch}/>
            <div className="groupConWrap" ref={ref}>
                <div>
                    <h4>전체 그룹</h4>
                    {renderAllGroup()}
                </div>
                <span>
                    <img 
                    src={process.env.PUBLIC_URL + "/img/icon_arrow_right_g.png"} 
                    alt="화살표 이미지" width="24px" height="24px"
                    onClick={onSelect}/><br/>
                    <img 
                    src={process.env.PUBLIC_URL + "/img/icon_arrow_left_g.png"} 
                    alt="화살표 이미지" width="24px" height="24px"
                    onClick={onDelete}/>
                </span>
                <div>
                    <h4>선택 그룹</h4>
                    <div className="groupConList">
                        {
                            selectGroup.map((data, idx) => (
                                <div key={data.groupName + idx}>
                                    <GroupWrapper
                                        groupName={data.groupName}
                                        onCheck={() => onCheckSelectGroup(data.idx)}
                                        />
                                    <input 
                                        type='checkbox' 
                                        name={groupInputName} 
                                        value={data.groupCode} 
                                        style={{display: "none"}} 
                                        defaultChecked/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {error ? <p className='warning'>{error}</p> : ""}
        </div>
    )
}

export default GroupSelect