import React from 'react'
import { ACCESS_TOKEN } from "../../const/const";
import { LOGOUT_DEFINE } from "../../const/defineName"
import { LOGIN_PATH } from "../../const/path";
import { deleteCookie, fetchData } from "../../util/util"
import { redirect } from "react-router-dom"

export const logoutLoader = async() => {
  const response = await fetchData(LOGOUT_DEFINE);
  if(response.result === "SUCCESS") {
      deleteCookie("refresh_token");
      localStorage.removeItem(ACCESS_TOKEN);
  }
  return redirect(LOGIN_PATH);
}

export const logoutAction = async() => {
    const response = await fetchData(LOGOUT_DEFINE);
    if(response.result === "SUCCESS") {
      deleteCookie("refresh_token");
      localStorage.removeItem(ACCESS_TOKEN);
    }
    return redirect(LOGIN_PATH);
}

const Logout = () => {
  return (
    <div></div>
  )
}

export default Logout