import React, { Children, cloneElement, isValidElement, useEffect, useRef, useState } from 'react'
import { randomNumber } from '../../util/util';

const GroupWrapper = ({ children, groupName, name, showMember, onCheck, value, defaultChecked, flagSelect, disabled=false, expanded=false }) => {
    const ref = useRef([]);
    const [isPending, setIsPending] = useState(expanded ?? false);
    const parentRef = useRef();
    const childRef = useRef();
    const checkRef = useRef();
    const [allCheck, setAllCheck] = useState(false);

    useEffect(() => {
        parentRef.current.style.height = `${childRef.current.clientHeight}px`;
        checkRef.current.checked = false;
        setAllCheck(false);
    }, [flagSelect])

    useEffect(() => {
        if(expanded) {
            parentRef.current.style.height = `${childRef.current.clientHeight}px`;
        }
    }, [expanded])

    const handleButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (parentRef.current === null || childRef.current === null) {
            return;
        }
        if (parentRef.current.clientHeight > 0) {
            parentRef.current.style.height = "0";
        } else {
            parentRef.current.style.height = `${childRef.current.clientHeight}px`;
        }
        setIsPending(!isPending);
    }

    const onClickAllCheck = (event) => {
        const len = ref.current.length;

        for(let i = 0; i < len; i++) {
            if(!ref.current[i]) continue;
            ref.current[i].checked = !allCheck;
        }
        
        if(onCheck) {
            onCheck(event);
        }
        setAllCheck(!allCheck);
    }

    const renderChild = Children.map(children, (child, idx) => {
        if(isValidElement(child)) {
            return cloneElement(child, {ref, idx, groupName, disabled});
        }
        return child;
    })

    const id = groupName + randomNumber();
    return (
        <div className="wrapper"> 
            <label htmlFor={id} className="chk_box groupB">
                <input 
                    key={groupName}
                    type="checkbox" 
                    id={id} 
                    name={name} 
                    onChange={ (event) => {onClickAllCheck(event)} }
                    value={value}
                    className="checkAll" 
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    ref={checkRef}/>
                <span className="checkon"></span>
                <b> 
                    {groupName} 
                    <button onClick={handleButtonClick}>
                        <img 
                            src={isPending ? `${process.env.PUBLIC_URL}/img/arrow_down.png` : `${process.env.PUBLIC_URL}/img/arrow_down.png`} 
                            alt="그룹 버튼" />
                    </button>
                </b>
            </label>
            <div ref={parentRef} style={{overflow: "hidden", height: "0px", transition: "all 0.3s ease-out"}}>
                <div ref={childRef}>
                    {showMember ? renderChild : ""}
                </div>
            </div>
        </div>
    )
}

export default GroupWrapper