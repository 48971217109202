import React from 'react'

const Button = ({ content, onClick }) => {
  return (
    <div className="btnWrap d-flex justify-content-end align-items-center">
      <button onClick={onClick}>
          {content}
      </button>
    </div>
  )
}

export default Button