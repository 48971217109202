import { ADMIN_CREATE_DEFINE, ADMIN_DELETE_DEFINE, ADMIN_LIST_DEFINE, ADMIN_READ_DEFINE, ADMIN_SEARCH_DEFINE, MY_INFO_DEFINE } from "../../const/defineName";
import { ADMIN_CREATE_URL, ADMIN_DELETE_URL, ADMIN_LIST_URL, ADMIN_READ_URL, ADMIN_SEARCH_URL, MY_INFO_URL } from "../../const/url";
import { DELETE_METHOD, GET_METHOD, POST_METHOD } from "../../const/const";

export const adminDefine = {
    [ADMIN_LIST_DEFINE]: {
        method: GET_METHOD,
        url: ADMIN_LIST_URL
    },
    [ADMIN_SEARCH_DEFINE]: {
        method: GET_METHOD,
        url: ADMIN_SEARCH_URL
    },
    [ADMIN_CREATE_DEFINE]: {
        method: POST_METHOD,
        url: ADMIN_CREATE_URL
    },
    [ADMIN_READ_DEFINE]: {
        method: GET_METHOD,
        url: ADMIN_READ_URL,
    },
    [ADMIN_DELETE_DEFINE]: {
        method: DELETE_METHOD,
        url: ADMIN_DELETE_URL,
    },
    [MY_INFO_DEFINE]: {
        method: GET_METHOD,
        url: MY_INFO_URL,
    },
}