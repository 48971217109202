import React, { useRef, useState } from 'react'
import Pagination from '../../../atom/pagination'
import SearchAndDelete from '../../../components/searchAndDelete'
import { useNavigate, useLoaderData, useSearchParams, useActionData, Form, useSubmit } from 'react-router-dom'
import { PAGE_PER_SIZE, PUT_METHOD } from '../../../../const/const'
import { memberChangeGradeQuery, memberListQuery } from '../../../../queries/memberQueries'
import { useQuery } from '@tanstack/react-query';
import { getClassNameByStatus, getGradeName, getMemberType, getNameByStatus, makeParamsFromFormData } from '../../../../util/util'
import Button from '../../../atom/button'
import ChangeMemberGradePopup from '../../../components/popup/changeMemberGradePopup'

export const memberListLoader = 
    (queryClient) => async({request}) => {
        const url = new URL(request.url);
        const page = url.searchParams.get('page') ?? 0;
        const size = url.searchParams.get('size') ?? PAGE_PER_SIZE;
        const keyword = url.searchParams.get('keyword');
        const keywordType = url.searchParams.get('keywordType');

        const param = {
            page: page,
            size: size,
            keyword: keyword,
            keywordType: keywordType
        }

        // if(!queryClient.getQueryData(memberListQuery(param).queryKey)) {
            await queryClient.fetchQuery(memberListQuery(param));
        // }
        return { param };
    }

export const memberChangeGradeAction =
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData, "appMemberCode");
        const errors = {};

        if(!params.appMemberCode) {
            errors.popupError = "권한을 변경할 회원을 선택해주세요.";
            return errors;
        }
        if(!params.appMemberRole) {
            errors.popupError = "변경할 권한을 선택해주세요.";
            return errors;
        }

        const paramList = [];

        params.appMemberCode.forEach(code => {
            paramList.push({appMemberCode: code, appMemberRole: params.appMemberRole});
        })

        const response = await queryClient.fetchQuery(memberChangeGradeQuery(paramList));

        if(response.result === "FAIL") {
            errors.error = response.message;
        }
        
        return errors;
    }

const Member = () => {
    const navigator = useNavigate();
    const { param } = useLoaderData();
    const { data: { data: memberList } } = useQuery(memberListQuery(param));
    const [, setSearchParams ] = useSearchParams();
    const [isGradePopupOpen, setIsGradePopupOpen] = useState(false);
    const ref = useRef([]);
    const errors = useActionData();
    const submit = useSubmit();
    const submitRef = useRef();

    const onChangeHandle = (page) => {
        setSearchParams({page: page.selected});
    }

    const checkAll = (e) => {
        ref.current.forEach(r => {
            r.checked = e.target.checked;
        });
    }

    const onChangePopupOpen = (e) => {
        e.preventDefault();
        setIsGradePopupOpen(!isGradePopupOpen);
    }

    const onSubmit = () => {
        setIsGradePopupOpen(!isGradePopupOpen);
        submit(submitRef.current);
        ref.current.forEach(r => {
            r.checked = false;
        });
    }

    return (
        <div className="main">
            <div className="contentBg">
                <div className='d-flex justify-content-between align-items-center subTitWrap'>
                    <h2 className="subTit">회원 관리</h2>
                    <Button content={"선택회원 등급수정"} onClick={onChangePopupOpen}/>
                </div>
                <SearchAndDelete isDelete={false} searchName={"keyword"} searchTypeName={"keywordType"}>
                    <option value="">전체</option>
                    <option value={"appMemberEmail"}>이메일</option>
                    <option value={"appMemberName"}>이름</option>
                </SearchAndDelete>
                <Form method={PUT_METHOD}>
                    {isGradePopupOpen ? 
                        <ChangeMemberGradePopup 
                            onCancle={onChangePopupOpen} 
                            error={errors?.popupError}
                            onSubmit={onSubmit}
                            ref={submitRef}/> : ""}
                    <div className="tableWrap">
                        <table className="table table-hover boardCheck">
                            <thead>
                                <tr>
                                <th scope="col" className='tableshort'>
                                    <label htmlFor="selectAll" className="chk_box">
                                        <input 
                                            type="checkbox" 
                                            id="selectAll" 
                                            className="checkAll"
                                            onClick={checkAll}/>
                                        <span className="checkon"></span>
                                        </label>
                                    </th>
                                    <th scope="col"  className="tableshort">No</th>
                                    <th scope="col">이름</th>
                                    <th scope="col">아이디(이메일)</th>
                                    <th scope="col">연락처</th>
                                    <th scope="col">가입일</th>
                                    <th scope="col">유형</th>
                                    <th scope="col">등급</th>
                                    <th scope="col">상태</th>
                                </tr>
                            </thead>
                            <tbody>
                            {/* appMemberInfoResponseList */}
                                {
                                    memberList.appMemberInfoResponseList.length !== 0 ?
                                        memberList.appMemberInfoResponseList.map((member, idx) => (
                                            <tr onClick={() => {navigator(member.appMemberCode)}} key={member.appMemberCode}>
                                                <td  className='tableshort' onClick={(e) => e.stopPropagation()}>
                                                    <label htmlFor={member.appMemberCode} className="chk_box">
                                                        <input type="checkbox" 
                                                        id={member.appMemberCode} 
                                                        name='appMemberCode'
                                                        value={member.appMemberCode}
                                                        className="chkbox"  
                                                        ref={e => ref.current[idx] = e}/>
                                                        <span className="checkon"></span>
                                                    </label>
                                                </td>
                                                <td className="tableshort">{member.idx}</td>
                                                <td>{member.appMemberName}</td>
                                                <td>{member.appMemberEmail}</td>
                                                <td>{member.appMemberPhone}</td>
                                                <td>{member.createAt}</td>
                                                <td>{getMemberType(member.appMemberType)}</td>
                                                <td>{getGradeName(member.appMemberRole)}</td>
                                                <td>
                                                    <p className={getClassNameByStatus(member.appMemberStatus)}>
                                                        {getNameByStatus(member.appMemberStatus)}
                                                    </p>
                                                </td>
                                            </tr>
                                        )) : <td colSpan={9}><div className='nodata'><p>등록된 회원이 없습니다</p></div></td>
                                }
                            </tbody>
                        </table>
                    </div>
                </Form>
                <Pagination 
                    initialPage={param.page} 
                    onChange={onChangeHandle}
                    totalCount={memberList.totalCount}/>
            </div>      
        </div>
    )
}

export default Member