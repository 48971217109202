import React from 'react'
import ReactPaginate from 'react-paginate';
import { PAGE_PER_SIZE } from '../../const/const';

const Pagination = ({totalCount, onChange, initialPage}) => {
  
  return (
    <div className="paginationWrap">
        <nav aria-label="Page navigation">
          <ReactPaginate
            className='pagination'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            initialPage={initialPage}
            activeClassName='active'
            breakLabel={""}
            marginPagesDisplayed={0}
            previousLabel={
              <span className="page-link">
                <span aria-hidden="true">&laquo;</span>
              </span>
            }
            nextLabel={
              <span className="page-link">
                <span aria-hidden="true">&raquo;</span>
              </span>
            }
            pageCount={Math.ceil(totalCount/PAGE_PER_SIZE)}
            pageRangeDisplayed={10}
            renderOnZeroPageCount={null}
            onPageChange={onChange}
          />
        </nav>
    </div>
  )
}

export default Pagination