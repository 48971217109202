import React, { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom';
import { POST_METHOD } from '../../../const/const';
import Input from '../../atom/input';

const CalendarReadPopup = ({data, onClose}) => {
    const fetcher = useFetcher("delete-update-fetcher");
    const [type, setType] = useState("delete");

    useEffect(() => {
        if(fetcher?.data?.params) {
            onClose();
        } else if(fetcher.data?.delete) {
            onClose();
        }
    }, [fetcher, onClose])

    const onClickUpdate = (e) => {
        if(type === "delete"){
            e.preventDefault();
        }
        setType("update");
    }

    return (
        <fetcher.Form method={POST_METHOD}>
            <div className="modal" id="eventModal" tabIndex="-1" aria-labelledby="eventModalLabel" aria-hidden="true">
                <input type='hidden' name='type' value={type}/>
                <input type='hidden' name='code' defaultValue={data.code}/>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between align-items-center">
                            <h5 className="modal-title" id="eventModalLabel">일정</h5>
                            <button className='closeBtn' onClick={onClose}></button>
                        </div>
                        {
                            type === "delete" ?
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="inputTitle" className="form-label">일정제목</label>
                                    <p>{data?.title}</p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputTitle" className="form-label">메모</label>
                                    <p>{data?.content}</p>
                                </div>
                            </div> :
                            <div className="modal-body">
                                <Input type='hidden' name='start' defaultValue={data?.start}/>
                                <Input type='hidden' name='end' defaultValue={data?.end}/>
                                <div className="mb-3">
                                    <Input name='title' label={"일정제목"} defaultValue={data?.title} error={fetcher.data?.error?.title} required/>
                                </div>
                                <div className="mb-3">
                                    <Input name='content' label={"메모"} defaultValue={data?.content} error={fetcher.data?.error?.content} required/>
                                </div>
                            </div>
                        }
                        <div className="btnwrap d-flex justify-content-between">
                            <button>삭제</button>
                            <button className="yes" onClick={onClickUpdate}>수정</button>
                        </div>
                    </div>
                </div>
            </div>
        </fetcher.Form>
    )
}

export default CalendarReadPopup