/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { getFiles, randomNumber, removeItemAtList } from '../../util/util';
import { FILE_DETAIL_DEFINE } from '../../const/defineName';
import { FILE_HOST } from '../../server/host';

const FileUploader = ({fileId, maxLength=3, name="file"}) => {
  const fileRef = useRef([]);
  const [files, setFiles] = useState(["default"]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [error, setError] = useState("");
  const [deleteIdxes, setDeleteIdexes] = useState([]);
  const [mode, setMode] = useState("create");
  
  const onChangeFiles = (e, idx) => {
    const uploadedFiles = e.target.files;
    if(
        files.length + uploadedFiles.length > maxLength+1 ||
        previewFiles.length + uploadedFiles.length > maxLength+1
      ) {
      setError(`최대 ${maxLength}개까지 업로드 가능합니다.`);
      fileRef.current[idx].value = "";
      return false;
    }

    setError("");

    for(let i = 0; i < uploadedFiles.length; i++) {
      const f = uploadedFiles[i];
      const reader = new FileReader();
      reader.readAsDataURL(f);

      new Promise((resolve) => {
        reader.onload = () => {
          setPreviewFiles((currArr) => [...currArr, reader.result || null]);
          resolve();
        }
      })
      setFiles(currArr => [f, ...currArr]);
    }
  }

  const onDeleteFiles = (idx) => {
    setError("");
    const newPreivewFiles = removeItemAtList(previewFiles, idx);
    setPreviewFiles(newPreivewFiles);
    const newFiles = removeItemAtList(files, idx);
    fileRef.current[idx].value = "";
    setFiles(newFiles);
  }

  const onDeleteFilesUpdate = (idx, fleIdx) => {
    setError("");
    const newPreivewFiles = removeItemAtList(previewFiles, idx);
    setPreviewFiles(newPreivewFiles);

    setDeleteIdexes(currArr => [...currArr, fleIdx]);

    const newFiles = removeItemAtList(files, idx);
    setFiles(newFiles);
  }

  const getFileDetails = async() => {
    const response = await getFiles(FILE_DETAIL_DEFINE, {}, {fleGrpId: fileId});
    response.forEach(file => {
      setFiles(currArr => [file, ...currArr]);
      setPreviewFiles(currArr => [...currArr, file]);
    })
  }

  useEffect(() => {
    if(fileId) {
      setMode("update");
      getFileDetails()
    }
  }, [fileId])

  return (
    <div className="mb-4 d-grid">
        <label htmlFor="formFileMultiple" className="form-label"></label>
        <div>
            {
              files.map((file, idx) => (
                <div className="filebox" key={idx} style={{display: idx === files.length-1 ? "" : "none"}}>
                  <label 
                    className={idx === files.length-1 ? "input-file-button" : ""} 
                    htmlFor={`input-file-${idx}`}
                    style={{display: idx === files.length-1 ? "" : "none"}}
                    >
                      +
                  </label>
                  <input 
                    type="file" 
                    name={name} 
                    id={`input-file-${idx}`}
                    ref={el => fileRef.current[idx] = el}
                    onChange={(e) => onChangeFiles(e, idx)}/>
                </div>
              ))
            }
            <p className='errorComent'>{error}</p>
            <p className="info">업로드할 파일이 있는 경우 첨부해주세요 (.jpg, .png 파일형식)</p>
          <div className='d-flex'>
          {
            deleteIdxes.map(idx => (
              <input type='hidden' name='fleIdxs' value={idx} key={idx + randomNumber() + randomNumber()}/>
            ))
          }
          {
            mode === "update" ? 
              <>
                <input type='hidden' name='fleGrpId' value={fileId}/>
                <input type='hidden' name='fileId' value={fileId}/>
              </> : ""
          }
          {
              mode === "create" ? 
              previewFiles.map((file, idx) => (
                <div key={`${file.substring(1,20)}-${randomNumber()}`} className='fileImgWrap'>
                  <div className='fileImg attachment'>
                    <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                    <img src={file} alt='preview'/>
                    <div onClick={() => onDeleteFiles(idx)} className='deleteBtn'></div>
                  </div>
                </div>
              )) :
              previewFiles.map((file, idx) => (
                <div key={idx} className='fileImgWrap'>
                  <div className='fileImg attachment'>
                    <p>클릭시 더 크게 확인하실 수 있습니다.</p>
                    {
                      file.fleUrl ? 
                        <img src={`${FILE_HOST}${file.fleUrl}`} alt='preview'/> : 
                        <img src={file} alt='preview'/>
                    }
                    <div onClick={() => onDeleteFilesUpdate(idx, file.fleIdx)} className='deleteBtn'></div>
                  </div>
                </div>
            ))
            }
          </div>
        </div>
    </div>
  )
}

export default FileUploader