/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarCreatePopup from '../../../components/popup/calendarCreatePopup'
import { dateFormat, makeParamsFromFormData } from '../../../../util/util'
import { calendarCreateQuery, calendarDeleteQuery, calendarListQuery, calendarUpdateQuery } from '../../../../queries/calendarQueries'
import { useFetcher, useLoaderData } from 'react-router-dom';
import CalendarReadPopup from '../../../components/popup/calendarReadPopup'
import { POST_METHOD } from '../../../../const/const'

export const calendarLoader =
  (queryClient) => async() => {
    const response = await queryClient.fetchQuery(calendarListQuery());

    if(response.result === "SUCCESS") {
      const result = [];

      response.data.forEach(d => {
          const startArr = d.start.split(" ");
          const startDate = new Date(startArr[0]);
          startDate.setDate(startDate.getDate() + 1);

          const endArr = d.end ? d.end.split(" ") : null;
          let endDate = null;
          if(endArr) {
            endDate = new Date(endArr[0]);
            endDate.setDate(endDate.getDate() + 1);
          }

          result.push(
            {
              id: d.code,
              title: d.title,
              content: d.content,
              start: startDate.toISOString().replace(/T.*$/, '') + (d.end ? (startArr[1] ? `T${startArr[1]}` : "") : ""),
              end: d.end ? endDate.toISOString().replace(/T.*$/, '') + (endArr[1] ? `T${endArr[1]}` : "") : null,
              code: d.code,
            }
          )
      })
      
      return { result };
    }
  }

export const calendarAction = 
  (queryClient) => async({request}) => {
    const formData = await request.formData();
    const params = makeParamsFromFormData(formData);
    const error = {};

    params.notification = !!params.notification;
    
    switch(params.type) {
      case "create":
        params.start = dateFormat(new Date(params.start));
        if(params.end) {
          const endDate = new Date(params.end);
          endDate.setDate(endDate.getDate()-1);
          params.end = dateFormat(endDate);

          if(params.end === params.start) params.end = null;
        }
        
        if(!params.title) {
          error.title = "제목을 입력해주세요.";
          return { error };
        }
        if(!params.content) {
          error.content = "내용을 입력해주세요.";
          return { error };
        }

        const response = await queryClient.fetchQuery(calendarCreateQuery(params));

        if(response.result === "FAIL") {
          error.error = response.message;
          return { error };
        } else {
          return { params };
        }
      
      case "delete":
        await queryClient.fetchQuery(calendarDeleteQuery(params));
        return { delete: "delete" }

      case "update":
        params.start = dateFormat(new Date(params.start));
        if(params.end) {
          const endDate = new Date(params.end);
          // endDate.setDate(endDate.getDate()-1);
          params.end = dateFormat(endDate);
        }

        if(!params.title) {
          error.title = "제목을 입력해주세요.";
          return { error };
        }
        if(!params.content) {
          error.content = "내용을 입력해주세요.";
          return { error };
        }

        const updateResponse = await queryClient.fetchQuery(calendarUpdateQuery(params));

        if(updateResponse.result === "FAIL") {
          error.error = updateResponse.message;
          return { error };
        } else {
          return { params };
        }

      default:
        break;
    }

    return { error };
  }

const Calendar = () => {
  const [cState, setCState] = useState({
    weekendsVisible: true,
    currentEvents: [],
    isCreateOpen: false,
    isReadOpen: false,
    start: "",
    end: "",
    readData: {},
  });
  const { result } = useLoaderData();
  const fetcher = useFetcher("date-update-fetcher");
  const calRef = useRef();

  useEffect(() => {
    // if(cState.isCreateOpen) {
    //   setCState({
    //     ...cState,
    //     isCreateOpen: !cState.isCreateOpen,
    //   })
    // }

    // if(cState.isReadOpen) {
    //   setCState({
    //     ...cState,
    //     isReadOpen: !cState.isReadOpen,
    //   })
    // }
    
  }, [result])

  const handleDateSelect = (selectInfo) => {
    setCState({
      ...cState,
      isCreateOpen: true,
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    });
  }

  const handlePopup = (e) => {
    e.preventDefault();
    setCState({
      ...cState,
      isCreateOpen: !cState.isCreateOpen
    })
  }

  const handleEventClick = (clickInfo) => {
    const data = {
      title: clickInfo.event._def.title,
      content: clickInfo.event._def.extendedProps.content,
      code: clickInfo.event._def.extendedProps.code,
      start: clickInfo.event.start.toISOString(),
      end: clickInfo.event.end?.toISOString(),
    }
    
    setCState({
      ...cState,
      isReadOpen: true,
      readData: data,
    })
  }

  const handleEvents = (events) => {
    setCState({
      ...cState,
      currentEvents: [...cState.currentEvents, events]
    })
  }

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  const updateDate = (event) => {
    const params = {
      type: "update",
      ...event.event._def.extendedProps,
      title: event.event.title,
      start: event.event.startStr,
    }

    if(!event.event.allDay) {
      if(!event.event.endStr) {
        const t = new Date(event.event.startStr);
        t.setMinutes(t.getMinutes() + 60)
        params.end = t.toString();
      } else {
        params.end = event.event.endStr;
      }
      
    } else {
      if(event.event.endStr) {
        if(!event.oldEvent.endStr) {
          if(!params.end) {
            params.end = null;
            return;
          }
          const endDate = new Date(params.end);
          endDate.setDate(endDate.getDate()-1);
          params.end = dateFormat(endDate);
        } else {
          params.end = event.event.endStr;
        }
      } else {
        params.end = "";
      }
    }

    fetcher.submit(
      params
      , {method: POST_METHOD}
    )
  }

  return (
    <div className='main'>
      <div className='demo-app-main'>
        <FullCalendar
          ref={calRef}
          events={result}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={cState.weekendsVisible}
          locale={'ko'}
          select={handleDateSelect}
          eventContent={renderEventContent} 
          eventClick={handleEventClick}
          eventsSet={handleEvents}
          eventChange={updateDate}
        />
      </div>
      {
        cState.isCreateOpen ? 
          <CalendarCreatePopup 
            start={cState.start} 
            end={cState.end}
            onCancle={handlePopup}
            onSubmit={() => {setCState({...cState, isCreateOpen: false})}}/> 
          : ""
      }
      {
        cState.isReadOpen ?
          <CalendarReadPopup 
            data={cState.readData}
            onClose={() => setCState({...cState, isReadOpen: false})}
            onSubmit={() => {setCState({...cState, isReadOpen: false})}}/> 
            : ""
      }
    </div>
  )
}

export default Calendar;